import {DataGrid, Form, ResponsiveBox} from "devextreme-react";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import React from "react";
import {
    OrderDetailProductWithoutPriceColumnsDef
} from "../../../ColumnsDef/Orders/orderDetailProductWithoutPriceColumnsDef";

export default function OrderDetailWithoutPriceComponent (props) {
    
    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie {props.row.orderNr}</h3>
            <div className={'content-block dx-card responsive-paddings'}>
            <Form formData={props.row} colCount={2} labelMode='floating' readOnly={true} >
                <SimpleItem dataField="companyName">
                    <Label text='Nazwa'/>
                </SimpleItem>
                <SimpleItem dataField="companyAddress" editorType={dxTextArea}>
                    <Label text='Adres'/>
                </SimpleItem>
                <SimpleItem dataField="contactName">
                    <Label text='Kontakt'/>
                </SimpleItem>
                <SimpleItem dataField="contactPhone">
                    <Label text='Telefon'/>
                </SimpleItem>
                <SimpleItem dataField="contactEmail">
                    <Label text='E-mail'/>
                </SimpleItem>
                <SimpleItem dataField="deliveryAddress" editorType={dxTextArea}    editorOptions={{ autoResizeEnabled : true}}>
                    <Label text='Adres dostawy'/>
                </SimpleItem>
                <SimpleItem dataField="packageNr">
                    <Label text='Numer przesyłki'/>
                </SimpleItem>
                <SimpleItem dataField="invoiceNr">
                    <Label text='Numer faktury'/>
                </SimpleItem>
            </Form>
            <Form formData={props.data}  labelMode='floating' readOnly={true}>
                <SimpleItem dataField="comment" editorType={dxTextArea}>
                    <Label text='Uwagi'/>
                </SimpleItem>
                <GroupItem caption="Produkty">
                    <DataGrid
                        dataSource={props.row.productOnOrderDetail}
                        allowColumnReordering={false}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        columnAutoWidth={true}
                        columns={OrderDetailProductWithoutPriceColumnsDef}
                    >
                    </DataGrid>
                </GroupItem>
            </Form>
            </div>
        </React.Fragment>
    )
}