import {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";

const GET_ROLES_URL = 'user/GetRoles'

export const useGetRoles = () => {
    const [roles, setRoles] = useState([]);

    const receiveData = (response) => {
        setRoles(response.data);
    };

    const [rolesLoading, rolesError, setRolesReload] = useGetApiDataAuth(
        GET_ROLES_URL,
        receiveData
    );

    return [rolesLoading, rolesError, setRolesReload, roles];
};
