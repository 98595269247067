export const Roles = [
    {
        name: "Administrator",
        urls: [
            'managment',
            'documents',
            'storage',
            'inputOrders',
            'orderRealization',
            "/users/usersManagment",
            "/order/newOrderUser",
            "/order/createOrderOtherDelivered",
            "/order/orders",
            "/reports/sellingReport",
        ],
        componentsVisible: [
            "product-add-to-storage",
            "product-delete",
            "storage-all",
            "storage-edit-product",
            "createOrder-edit-company",
        ],
    },
    {
        name: "Pracownik",
        urls: [
            'storage',
            'orderRealization',
            "/order/newOrderUser",
            "/order/orders"
        ],
        componentsVisible: [
            "product-add-to-storage",
            "product-delete",
            "storage-all",
            "createOrder-edit-company",
        ],
    },
    {
        name: "Klient",
        urls: [
            "/order/newOrderClient",
            "/order/orders"
        ],
        componentsVisible: [

        ],
    },
   
];
