import React, {useCallback, useEffect, useState} from "react";

import SelectCompanyComponent from "../Managment/Company/selectCompanyComponent";
import {GetCompanyDetailsQuery} from "../../Query/Company/getCompanyDetailsQuery";
import {CompanyNewModel} from "../../Models/companyNewModel";
import {AddNewCompanyQuery} from "../../Query/Company/addNewCompanyQuery";
import {EditCompanyQuery} from "../../Query/Company/editCompanyQuery";
import {EditCompanyComponent} from "../Managment/Company/editCompanyComponent";
import {useGetCompanyRepresentative} from "../../Query/Company/useGetCompanyRepresentative";
import {Button} from "devextreme-react/button";
import {CreateOrderQuery} from "../../Query/Orders/createOrderQuery";
import {CreateOrderWithInvoiceQuery} from "../../Query/Orders/createOrderWithInvoiceQuery";
import {GetPackagePdfQuery} from "../../Query/Documents/getPackagePdfQuery";
import NewOrderODComponent from "./DetailsComponents/newOrderODComponent";
import AvailableProductsComponent from "../NewOrder/availableProductsComponent";
import {AddProductToOrderODComponent} from "./DetailsComponents/addProductToOrderODComponent";
import {CreateOrderODQuery} from "../../Query/Orders/createOrderODQuery";

const startOrder={
    listOfProduct: [],
    company : undefined,
    number:  undefined // '20210112321'
}

export default function CreateOrderODComponent (props) {
    const [selectedCompany, setSelectedCompany] = useState(props.company);
    const [listOfProduct, setListOfProduct] = useState([]);
    const [isFromCompany,setIsFromCompany]=useState(props.isFromCompany);
    const [companyToEdit, setCompanyToEdit] = useState(undefined);

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative] = useGetCompanyRepresentative();


    const [selectedOrder,setSelectedOrder]= useState(undefined);

    useEffect(()=>{
        if(isFromCompany && props.company!==null && props.company!==undefined)
        {
            startOrder.listOfProduct=[];
            startOrder.company=props.company;
            setSelectedOrder(startOrder);
        }
    },[])

    const onCompanySelect=(data)=>
    {
        GetCompanyDetailsQuery(data.id,(response)=>{
            
            setSelectedCompany(response.data);
            startOrder.listOfProduct=[];
            startOrder.company=response.data;
            startOrder.companyView=data;
            setSelectedOrder(startOrder);
            console.log('onCompanySelect',startOrder);
        });

    }

    const addProduct=(data) =>{
        const newList = JSON.parse(JSON.stringify(listOfProduct))
        newList.push(data);
        const nl=JSON.parse(JSON.stringify(newList));

        selectedOrder.listOfProduct=nl;
        //setSelectedOrder(selectedOrder);
        console.log(nl);
        console.log('addProduct',selectedOrder);
        setListOfProduct(nl);


    }

    const cancel=()=>{
        if(!isFromCompany) {
            setListOfProduct([]);
            setSelectedOrder(undefined);
            setSelectedCompany(undefined);
        }
        else {
            setListOfProduct([]);
            startOrder.listOfProduct=[];
            startOrder.company=props.company;
            setSelectedOrder(startOrder);
        }
    }

    const onCompanyInsert = useCallback( () => {
        setCompanyToEdit({...CompanyNewModel});
    },[]);

    const onCompanySave=useCallback((data) => {
        if(data.id===0)
        {
            AddNewCompanyQuery(data,()=>{
                //dataGridRefresh(companiesDataGridRef);
            });
        }
        else {
            EditCompanyQuery(data,()=>{
               // dataGridRefresh(companiesDataGridRef);
               cancel();
            });
        }
        setCompanyToEdit(undefined);
    },[]);
    
    const onOrderSave=(data)=>{
        const prod = listOfProduct.map((op, i) => {
            return {
                productId: op.id,
                count: op.productCount,
                pricePln: op.pricePln,
                priceUsd: op.priceUsd
            }
        });
        const dat = {
            companyId: data.companyId,
            deliveryAddressId: data.deliveryAddressId,
            products: prod,
            invoiceCreatedDate: data.invoiceCreatedDate,
            invoicePaymentDate: data.invoicePaymentDate,
            invoiceNr: data.invoiceNr,
            comment: data.comment,
            invoiceReturnDate: data.invoiceReturnDate
            // packageWeight:packageWeight
        }

        CreateOrderODQuery(dat,(response)=>{
              
                cancel();
            });
        
    }

    return (
        <React.Fragment>
                    {selectedOrder ?
                        <div>
                            <Button   type={"default"}  width={"100%"}  stylingMode={"contained"} onClick={()=>{setCompanyToEdit(selectedCompany)}} >
                               Edytuj firmę
                            </Button>
                            <NewOrderODComponent 
                                data={selectedOrder}
                                onSave={onOrderSave}
                                onClose={cancel}
                                     /> </div>: <div/>
                    }
                 
                                {!selectedCompany && companyToEdit === undefined ? <SelectCompanyComponent
                                        onInsert={onCompanyInsert}
                                        onSelect={onCompanySelect}
                                    /> :
                                     companyToEdit === undefined ? 
                                         <AvailableProductsComponent
                                             addProductDetailComponent={AddProductToOrderODComponent}
                                             addProduct={addProduct} 
                                             isHidden={isFromCompany}/>
                                    : <div/>
                                }
            {companyToEdit !== undefined ? <EditCompanyComponent
                save={
                    (data) => {
                        onCompanySave(data)
                    }
                }
                data={companyToEdit}
                companyRepresentative={companyRepresentative}
                cancel={()=>{
                    setCompanyToEdit(undefined);
                }}

            /> : null}
                          

        </React.Fragment>

    )
}
