import React, {useEffect, useState} from "react";
import QrReader from 'react-qr-reader'
import {AxiosAuthPost, useGetApiDataAuth} from "../../Helper";
import SelectWarehouse from "./selectWarehouse";
import SelectProductTable from "./selectProductTable";
import PackingSelectedProduct from "./packingSelectedProduct";
import {store} from "react-notifications-component";
import {Button} from "reactstrap";



const PackingProduct=(props)=>{

    const[scanResult,setScanResult]=useState("");

    const [dataInEdit, setDataInEdit] = useState(undefined);
    const [selectedWarehouse,setSelectedWarehouse]=useState(undefined);
    const [showSelectWarehouse,setShowSelectWarehouse]=useState(undefined);
    const [selectedProduct,setSelectedProduct]=useState(undefined);

    const [userName, setUserName] = useState(null);

    const [scanOn,setScanOn]=useState(true);

    const [result, setResult] = useState([]);


    const receiveData = (response) => {
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "warehouseplace/Get",
        receiveData
    );

    //
    // useEffect(async ()=>{
    //     const load= async ()=> {
    //         const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    //
    //         setUserName(user && user.name);
    //     }
    //     load();
    //
    // },[])


    const clearData=()=>{
        setDataInEdit(undefined);
        setScanResult("");
        setReload(true);
        setScanOn(true);
        setSelectedWarehouse(undefined);

    }
    

    useEffect(()=>{
            const dat2 = scanResult;
            console.log(dat2);
            const res = result.filter((e) => e.idCode === dat2);
            if (res.length > 0) {
                setSelectedWarehouse(res[0]);
            }
    },[scanResult])
    

    const onScan2=(data)=>{
        if(data)
        {
            setScanOn(false);

            setScanResult(data);
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const packingProductWarehouse=(data) => {

        console.log(data);
        AxiosAuthPost(
            "product/PackingProductOnWarehouse",
            data,
            (response) => {
                setReload(true);

                store.addNotification({
                    title: "Magazyn",
                    message: "Produkt " +data.productCode+ " został spakowany",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });

        clearData();
    }
   
        return (
            <div>
                <p><Button onClick={ ()=> {

                    setShowSelectWarehouse(true);
                }
                }>
                    Wybierz miejsce
                </Button></p>
                {scanOn === true ?
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={onScan2}
                        style={{width: '100%'}}
                    /> : null}

                <p>{scanResult}</p>
                {/*  <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className={` text-decoration-none  `}>
                        <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={ ()=> {

                            setShowSelectWarehouse(true);
                            }
                        }>
                            <i className="fa fa-fw fa-trash mr-2"></i>
                            Wybierz miejsce
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown> */}

                {showSelectWarehouse !== undefined ? <SelectWarehouse
                    onSelect={
                        (data) => {
                            //setShowAddToWarehouse(data);
                            // addToWarehouse(data);
                            console.log(data);
                            
                            if(props.onlySelectWarehouse===true)
                            {
                                if(props.completeOrder)
                                {
                                    const dat ={...props.data,productOnWarehousePlaceId: data.id  }

                                    setShowSelectWarehouse(undefined);
                                    setSelectedWarehouse(undefined);
                                    setSelectedProduct(undefined);
                                    setScanOn(true);
                                    props.completeOrder(dat);
                                }
                            }
                            else {
                                setShowSelectWarehouse(undefined);
                                setSelectedWarehouse(data);
                            }
                        }
                    }
                 
                    cancel={() => {
                        setScanOn(true);
                        setSelectedWarehouse(undefined);
                        setShowSelectWarehouse(undefined);
                        if(props.onCancel)
                        {
                            props.onCancel();
                        }
                    }}
                    result={result}

                /> : null}

                {selectedWarehouse !== undefined ? <SelectProductTable
                    onSelect={
                        (data) => {
   
                            setShowSelectWarehouse(undefined);
                            setSelectedProduct(data);
                            setSelectedWarehouse(undefined);
                        }
                    }

                    cancel={() => {
                        setScanOn(true);
                        setSelectedWarehouse(undefined);
                        setShowSelectWarehouse(undefined);
                        if(props.onCancel)
                        {
                            props.onCancel();
                        }
                    }}
                    warehouse={selectedWarehouse}
                    data={
                      props.productName ? selectedWarehouse.productOnWarehousePlacesView.filter(ee=>ee.name===props.productName)
                          : selectedWarehouse.productOnWarehousePlacesView
                    }
                /> : null}
                {selectedProduct !== undefined ? <PackingSelectedProduct
                    save={
                        (data,count) => {
                            console.log('dddd',data,count);
                            setShowSelectWarehouse(undefined);
                            setSelectedWarehouse(undefined);
                            setSelectedProduct(undefined);
                            setScanOn(true);
                            

                            const editData=
                                {
                                    productOnWarehouseId:data.id,
                                    count:count,
                                    userName: userName,
                                    productCode: data.productIdCode
                                }
                                
                                if(props.completeOrder)
                                {
                                    const dat ={productOnWarehousePlaceId: data.id , count: count }

                                    props.completeOrder(dat);
                                }
                                else 
                                {
                                    packingProductWarehouse(editData);
                                }
                        }
                    }

                    cancel={() => {
                        setScanOn(true);
                        setSelectedWarehouse(undefined);
                        setShowSelectWarehouse(undefined);
                        setSelectedProduct(undefined);
                        if(props.onCancel)
                        {
                            props.onCancel();
                        }

                    }}
                    data={selectedProduct}

                /> : null}

                
            </div>
        )
    
}
export default PackingProduct