import {AxiosAuthGet} from "../../Helper";
import {ShowErrorNotification} from "../../notifications";
import ConstantsTitles from "../../Consts/titles";

import {LogError, LogInfo} from "../../logs";

const GET_ORDER_DETAILS_URL = 'order/GetOrderDetail/'

const onError=(error)=>{
    LogError('GetOrderDetailsQuery',error);
    ShowErrorNotification(ConstantsTitles.ORDER_TITLE, error.message);
}

export const GetOrderDetailsQuery = (orderId, onResponse) => {
    LogInfo('GetOrderDetailsQuery',orderId);

    const newUrl=GET_ORDER_DETAILS_URL+orderId;

    AxiosAuthGet(newUrl,onResponse,onError);

}