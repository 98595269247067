export const CompanyNewModel={
  id: 0,
  nip: "",
  name: "",
  street: "",
  number: "",
  localNumber: "",
  zip: "",
  city: "",
  contactFirstName: "",
  contactLastName: "",
  contactPhoneNumbers: "",
  contactEmail: "",
  discountPercent: 0,
  isRepresentative: false,
  marketingContact: false,
  isProfessional: false,
  isGroupInvoice: false,
  isAfterTraining: false,
  trainedName: "",
  trainerName: "",
  certNumber: "",
  trainingDate: null,
  
  comment: "",
  deliveryAddresses: [],
}