import React, {useRef, useState} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail, Selection, Toolbar, Item
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {AddButtonsOnEnd, dataGridRefresh, useDxDataGridExpandedLoadQuery} from "../../Base/dxGridHellper";
import OrderActionExpandedComponent from "../DetailsComponents/orderActionExpandedComponent";
import {OrderToReturningDxStore} from "../../../Query/Orders/orderToReturnigDxStore";
import {GetDataToOrderReturningQuery} from "../../../Query/Orders/getDataToOrderReturningQuery";
import {OrdersColumnsDef} from "../../../ColumnsDef/Orders/ordersColumnsDef";
import OrderReturningComponent from "../OperationsComponents/orderReturningComponent";



export default function OrdersToReturnComponent (props) {


    const ordersDataGridRef = useRef();
    const [ordersColumns]=useState(
        OrdersColumnsDef
    )
    
    const [selectedOrder, setSelectedOrder] = useState(undefined);
    

    const actions={
        actionName: "Zwrot",
        action: (selectedData)=>{
            setSelectedOrder(selectedData)}
    }

    const [contentReady, selectionChanged, renderDetailSection,reloadData]=useDxDataGridExpandedLoadQuery(GetDataToOrderReturningQuery,OrderActionExpandedComponent,actions);

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            {!selectedOrder ?
                <DataGrid
                    keyExpr="id"
                    dataSource={OrderToReturningDxStore}
                    showBorders={true}
                    remoteOperations={true}
                    columnAutoWidth={true}
                    ref={ordersDataGridRef}
                    columns={ordersColumns}
                    onSelectionChanged={selectionChanged}
                    onContentReady={contentReady}
                >
                    <Selection mode="single" />
                    <SearchPanel visible={true}/>
                    <Paging defaultPageSize={12} />
                    <Pager
                        showPageSizeSelector={true}
                    />
                    <MasterDetail enabled={false} render={renderDetailSection}/>
                    <Toolbar>
                        <Item location="before"  name="searchPanel"/>
                    </Toolbar>

                </DataGrid> : <div/>}
            {selectedOrder!==undefined ?
                <OrderReturningComponent selectedOrder={selectedOrder} reloadData={reloadData} onDataLoaded={contentReady} cancel={()=>setSelectedOrder(undefined)} />
                : <div/>}
        </div>
    )
}