import React, {useRef} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail, Selection, Toolbar, Item, ColumnChooser
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {useDxDataGridExpandedLoadQuery} from "../../Base/dxGridHellper";
import {GetOrderDetailsQuery} from "../../../Query/Orders/getOrderDetailQuery";
import {OrdersColumnsDef} from "../../../ColumnsDef/Orders/ordersColumnsDef";
import {OtherDeliveryOrderDxStore} from "../../../Query/Orders/otherDeliveryOrderDxStore";
import OrderActionWithPriceExpandedComponent from "../DetailsComponents/orderActionWithPriceExpandedComponent";


export default function OtherDeliveryOrdersComponent (props) {

    const ordersDataGridRef = useRef();
   
    const [contentReady, selectionChanged, renderDetailSection]=useDxDataGridExpandedLoadQuery(GetOrderDetailsQuery,OrderActionWithPriceExpandedComponent,{});

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <DataGrid
                keyExpr="id"
                dataSource={OtherDeliveryOrderDxStore}
                showBorders={true}
                remoteOperations={true}
                columnAutoWidth={true}
                ref={ordersDataGridRef}
                columns={OrdersColumnsDef}
                onSelectionChanged={selectionChanged}
                onContentReady={contentReady}
            >
                <Selection mode="single" />
                <GroupPanel visible={true} />
                <SearchPanel visible={true}/>
                <ColumnChooser enabled={true}/>
                <FilterRow visible={true}/>
                <Paging defaultPageSize={12} />
                <Pager
                    showPageSizeSelector={true}
                />
                <MasterDetail enabled={false} render={renderDetailSection} />
                <Toolbar>
                    <Item location="before"  name="searchPanel"/>
                    <Item location="before"  name="columnChooserButton"/>

                </Toolbar>

            </DataGrid>
        </div>
    )
}