import {CardBody, CardHeader, CardImg, Table} from "../../appp/components";
import React, {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";
import {Card} from "reactstrap";



const PrintQRCodes=(props)=>{
    const [result, setResult] = useState([]);


    const receiveData = (response) => {
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "QrCodes/Get",
        receiveData
    );
    
    return(
        <Table className="mb-2" responsive>
            <tbody>
            {result.map((dat, i) => {

            return (
                <tr  id={'war_'+dat.id}>
                    <Card className="mb-3">
                        <CardHeader tag="h6" className="bg-info text-white">
                            {dat.idCode}
                        </CardHeader>
                        <CardBody size={32}>
                            
                                <CardImg size={ 32 }
                                         src={ `data:image/jpeg;base64,${dat.qrCodeImage}` } />
                           
                           
                        </CardBody>
                    
                    </Card>
                </tr>
            )
        })}
            </tbody>
        </Table>
)
    
}
export default PrintQRCodes