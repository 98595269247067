import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const ADD_COMPANY_URL = 'company/Add'

export const AddNewCompanyQuery = (data, onResponse) => {
    return DefaultPostQuery(
        ADD_COMPANY_URL,
        ConstantsTitles.COMPANY_TITLE,
        ConstantsMessages.COMPANY_ADDED,
        AddNewCompanyQuery.name,
        data,
        onResponse
    )
}