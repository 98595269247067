import React, {useEffect, useRef, useState} from "react";
import {useGetCompany} from "../../Query/Company/useGetCompany";
import {Item, MasterDetail, SearchPanel, Selection, Toolbar} from "devextreme-react/data-grid";
import {DataGrid, LoadPanel} from "devextreme-react";
import {CompanyDxStore} from "../../Query/Company/companyDxStore";
import {CompaniesColumnsDef} from "../../ColumnsDef/Company/companyColumnsDef";
import {
    AddButtonsOnBegin,
    AddButtonsOnEnd, dataGridCollapseAll, dataGridGetObject,
    useDxDataGridExpanded,
    useDxDataGridExpandedLoadQuery
} from "../Base/dxGridHellper";
import {Button} from "devextreme-react/button";
import {AvailableProductsDxStore} from "../../Query/Products/availableProductsDxStore";
import {AvailableProductsColumnsDef} from "../../ColumnsDef/Products/availableProductsColumnsDef";
import {GetOrderDetailsQuery} from "../../Query/Orders/getOrderDetailQuery";
import OrderExpandedComponent from "../Orders/DetailsComponents/orderExpandedComponent";
import {AddProductToOrderComponent} from "./DetailsComponents/addProductToOrderComponent";

export default function AvailableProductsComponent (props) {

    const dxGridRef=useRef();
    
    const actions={
        addProduct: (e)=>{
            props.addProduct(e);
           dataGridCollapseAll(dxGridRef);
        }
    }

    const [contentReady, selectionChanged, renderDetailSection]=useDxDataGridExpanded(props.addProductDetailComponent,actions);
    

    return (
        <div>
        <h3 className={'content-block'}>Lista produktów</h3>
        <div className={'content-block dx-card responsive-paddings'}>
            <DataGrid
                dataSource={AvailableProductsDxStore}
                rowAlternationEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
                columns={AvailableProductsColumnsDef}
                remoteOperations={true}
                onSelectionChanged={selectionChanged}
                onContentReady={contentReady}
                ref={dxGridRef}
            >
                <Selection mode="single" />
                <MasterDetail enabled={false} render={renderDetailSection} />
                <SearchPanel visible={true}/>
                <Toolbar>
                    <Item  location="before"
                           name="searchPanel"
                    />
                </Toolbar>

            </DataGrid>
        </div>
        </div>
    )
}