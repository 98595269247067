import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const DELETE_COMPANY_URL = 'company/Delete'

export const DeleteCompanyQuery = (data, onResponse) => {
    return DefaultPostQuery(
        DELETE_COMPANY_URL,
        ConstantsTitles.COMPANY_TITLE,
        ConstantsMessages.COMPANY_DELETED,
        DeleteCompanyQuery.name,
        data,
        onResponse
    )
}