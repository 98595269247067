import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithFileDownloadQuery} from "../defaultPostWithFileDownloadQuery";

const GET_PACKAGE_PDF_URL = 'Document/GetPackagePdf'

export const GetPackagePdfQuery = (data, onResponse) => {
    return DefaultPostWithFileDownloadQuery(
        GET_PACKAGE_PDF_URL,
        ConstantsTitles.DOCUMENT_TITLE,
        ConstantsMessages.DOCUMENT_GET_EXISTING_PACKAGE_PDF,
        GetPackagePdfQuery.name,
        'Paczka'+data.id+'.pdf',
        data,
        onResponse
    )
}
    
    
   