import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const CREATE_ORDER_RW_URL = 'Order/CreateOrderRw'

export const CreateOrderRwQuery = (data, onResponse) => {
    return DefaultPostQuery(
        CREATE_ORDER_RW_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CREATE_ORDER,
        CreateOrderRwQuery.name,
        data,
        onResponse
    )
}
