import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const ACCEPT_ORDER_URL = 'Order/AcceptOrder'

export const AcceptOrderQuery = (data, onResponse) => {
    return DefaultPostQuery(
        ACCEPT_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_ACCEPT_ORDER,
        AcceptOrderQuery.name,
        data,
        onResponse
    )
}