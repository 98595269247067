import {AxiosAuthPost, useGetApiDataAuth} from "../../Helper";
import React, {useState} from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {CustomExportCSV, CustomSearch} from "../../appp/routes/Tables/ExtendedTable/components";
import {
    Button,
    ButtonGroup,
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "../../appp/components";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import EditWarehouse from "./editwarehouse";
import WarehouseProductTable from "../productonwarehouse/warehouseProductTable";
import AddProduct from "./addProduct";
import EditProductOnWarehouse from "../productonwarehouse/editProductOnWarehouse";
import { UserContext } from "../../Users/UserContextProvider";
import { CheckRolesComponentName } from "../../Users/roleHelpers";
import {store} from "react-notifications-component";

function Warehouseplace () {

    const [user, setUser] = React.useContext(UserContext);

    const [result, setResult] = useState([]);
    const [dataInEdit, setDataInEdit] = useState(undefined);

    const [showAddToWarehouse,setShowAddToWarehouse]=useState(undefined);
    const [showEditProductOnWarehouse,setShowEditProductOnWarehouse]=useState(undefined);

    const receiveData = (response) => {
        setResult(response.data);
    };
    
    const [loading, error, setReload] = useGetApiDataAuth(
       "warehouseplace/Get",
        receiveData
    );
    

    const insert = () => {
        setDataInEdit({
            id: 0,
            idCode: "",
            rack: "",
            shelf: "",
            place: "",
            depth: 0,
            height: 0,
            width: 0,
        });
    };

    const showEditModal=(row)=>{
        console.log("edit",row)
        setDataInEdit(row);
    }

    const showEditProductOnWarehouseHandle=(dat)=>{
        console.log("edit",dat)
        
        const editData=
            {
                productOnWarehouseId:dat.id,
                count:dat.count,
                expirationDate:moment(dat.expirationDate,"DD.MM.YYYY").toDate(),
                userName: user!==null ? user.name : "Brak"
            }
        
        setShowEditProductOnWarehouse(editData);
    }

    const showDeleteModal=(data)=>{

        AxiosAuthPost(
            "warehouseplace/Delete",
            data,
            (response) => {
                setReload(true);

                store.addNotification({
                    title: "Miejsce w magazynie",
                    message: "Usunięto miejsce w magazynie",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});

            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });    
    }
    
    const save=(data) => {
        if(data.id===0)
        {
            AxiosAuthPost(
                "warehouseplace/Add",
                data,
                (response) => {
                    setReload(true);

                    store.addNotification({
                        title: "Miejsce w magazynie",
                        message: "Dodano miejsce w magazynie",
                        type: "success",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }});
                    
                },(error) => {
                    // handle error

                    console.log(error);
                    store.addNotification({
                        title: "Błąd!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                    });
                });
        }
        else {
            AxiosAuthPost(
                "warehouseplace/Edit",
                data,
                (response) => {
                   setReload(true);

                    store.addNotification({
                        title: "Miejsce w magazynie",
                        message: "Miejsce w magazynie zostało zmodyfikowane",
                        type: "success",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }});
                  
                },(error) => {
                    // handle error
                   
                    console.log(error);
                    store.addNotification({
                        title: "Błąd!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                    });
                });
        }
        setDataInEdit(undefined);
    }

    const actionsFormatter = (cell,row) =>
        <React.Fragment>
            {CheckRolesComponentName(user.role,'storage-all') ?
            <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className={` text-decoration-none  `}>
                    <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => showEditModal(row)}>
                        <i className="fa fa-fw fa-edit mr-2"></i>
                        Edycja {row.idCode}
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                        if (window.confirm('Jesteś pewien, że chcesz usunąć miejsce w magazynie?')) {
                            showDeleteModal(row)
                        } else {
                            // Do nothing!
                        }
                    }
                    }>
                        <i className="fa fa-fw fa-trash mr-2"></i>
                        Usuwanie {row.idCode}
                    </DropdownItem>
                    <DropdownItem onClick={() => setShowAddToWarehouse({
                        productId:0,
                        warehouseId: row.id,
                        count: 0,
                        expirationDate: "",
                        userName: user!==null ? user.name : "Brak"
                    })}>
                        <i className="fa fa-fw fa-trash mr-2"></i>
                        Dodaj do magazynu
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown> : <div></div>}
        </React.Fragment>
        // <div className="ButtonInRow" style={{textAlign: 'center'}}>
        //     <FaEdit size={20} color={"black"} data-tip data-for={`cessionE_${row.id}`} style={{cursor: "pointer"}} onClick={() => showEditModal(row)} />
        //     <ReactTooltip id={`cessionE_${row.id}`} type="dark" place="right">
        //         <span>Edytuj</span>
        //     </ReactTooltip>
        //     <FaTrash size={20} color={"black"} data-tip data-for={`cessionD_${row.id}`} style={{cursor: "pointer"}} onClick={() => showDeleteModal(row)} />
        //     <ReactTooltip id={`cessionD_${row.id}`} type="dark" place="right">
        //         <span>Usuń</span>
        //     </ReactTooltip>
        // </div>;

    const sortCaret = (order) => {
        if (!order)
            return <i className="fa fa-fw fa-sort text-muted"></i>;
        if (order)
            return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
    };

    const addToWarehouse=(data) => {

        console.log(data);
        AxiosAuthPost(
            "product/AddToWarehouse",
            data,
            (response) => {
                setReload(true);
                store.addNotification({
                    title: "Produkt na magazynie",
                    message: "Produkt dodany do magazynu",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }});

            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });

        setShowAddToWarehouse(undefined);
    }

    const editProductOnWarehouse=(data) => {

        console.log(data);
        AxiosAuthPost(
            "product/EditProductOnWarehouse",
            data,
            (response) => {
                setReload(true);
                store.addNotification({
                    title: "Produkt na magazynie",
                    message: "Produkt został zmodyfikowany",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });

        setShowEditProductOnWarehouse(undefined);
    }
    
    const columnDefs = [
        // {
        //     dataField: 'qrCodeImage',
        //     text: 'Photo',
        //     formatter: (cell) => (
        //         <Avatar.Image src={ `data:image/jpeg;base64,${cell}` } />
        //     ),
        // }, 
        {
            dataField: 'idCode',
            text: 'Kod id',
            sort: true,
            sortCaret
        }, {
            dataField: 'rack',
            text: 'Regał',
            sort: true,
            sortCaret
        }, {
            dataField: 'shelf',
            text: 'Półka',
            sort: true,
            sortCaret
        },
        {
            dataField: 'place',
            text: 'Miejsce',
            sort: true,
            sortCaret
        },
        {
            dataField: 'comment',
            text: 'Uwagi',
            sort: true,
            sortCaret
        },
        // {
        //     dataField: 'width',
        //     text: 'Szerokość',
        //     sort: true,
        //     sortCaret
        // },
        // {
        //     dataField: 'height',
        //     text: 'Wysokość',
        //     sort: true,
        //     sortCaret
        // },
        // {
        //     dataField: 'depth',
        //     text: 'Głębokość',
        //     sort: true,
        //     sortCaret
        // },
        {
            dataField: 'action',
            text: '',
            isDummyField: true,
            headerStyle: () => {
                return { width: '50px' }
            },
            formatter: actionsFormatter,
            csvExport: false
        }
        // {
        //     dataField: 'status',
        //     text: 'Status',
        //     sort: true,
        //     sortCaret,
        //     formatter: (cell) => {
        //         const color = (status) => {
        //             const map = {
        //                 'Active': 'success',
        //                 'Suspended': 'danger',
        //                 'Waiting': 'info',
        //                 'Unknown': 'secondary'
        //             };
        //             return map[status];
        //         }
        //
        //         return (
        //             <Badge color={ color(cell) }>
        //                 { cell }
        //             </Badge>
        //         );
        //     }
        // }, {
        //     dataField: 'region',
        //     text: 'Region',
        //     sort: true,
        //     sortCaret
        // }, {
        //     dataField: 'earnings',
        //     text: 'Earnings',
        //     sort: true,
        //     sortCaret,
        //     formatter: (cell, row) => (
        //         <span>
        //                 { row.earningsCurrencyIcon }
        //             { _.isNumber(cell) && cell.toFixed(2) }
        //             </span>
        //     )
        // }
    ];

    const expandRow = {
        renderer: row => (
            <div>
                <WarehouseProductTable data={row.productOnWarehousePlacesView} warehouse={row} EditProductOnWarehouse={showEditProductOnWarehouseHandle}/>
            </div>
           
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => isAnyExpands ? (
            <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
        ) : (
            <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
        ),
        expandColumnRenderer: ({ expanded }) =>
            expanded ? (
                <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
            ) : (
                <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
            )
    }

    return (
        <div>
           
        <ToolkitProvider
            keyField="id"
            data={ result }
            columns={ columnDefs }
            search
            exportCSV
        >
            {
                props => (
                    <React.Fragment>
                        <div className="d-flex justify-content-end align-items-center mb-2">
                            <h6 className="my-0">
                                Miejsca w magazynie 
                            </h6>
                            <div className="d-flex ml-auto">
                                <CustomSearch
                                    className="mr-2"
                                    { ...props.searchProps }
                                />
                                <ButtonGroup>
                                    <CustomExportCSV
                                        { ...props.csvProps }
                                    >
                                        Export
                                    </CustomExportCSV>
                                    <Button
                                        // id="modalDefault101"
                                        size="sm"
                                        outline
                                        onClick={ ()=> insert() }
                                    >
                                        Dodaj <i className="fa fa-fw fa-plus"></i>
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <BootstrapTable
                            classes="table-responsive-lg"
                            bordered={ false }
                            responsive
                            hover
                            expandRow={ expandRow }
                            { ...props.baseProps }
                        />
                    </React.Fragment>
                )
            }
        </ToolkitProvider>

            {/*<Button id="modalDefault101" color="secondary" outline>*/}
            {/*    See Modal  <i className="fa fa-angle-right ml-1"></i>*/}
            {/*</Button>*/}
            { /* START Example Modal */}
            {dataInEdit !== undefined ? <EditWarehouse 
                save={
                    (data) => {
                        save(data)
                    }
                }
                data={dataInEdit}
                cancel={()=>{
                    setDataInEdit(undefined);
                }}
                
            /> : null}

            {showAddToWarehouse !== undefined ? <AddProduct
                save={
                    (data) => {
                        addToWarehouse(data);
                    }
                }
                data={
                    showAddToWarehouse
                }
                cancel={()=>{
                    setShowAddToWarehouse(undefined);
                }}

            /> : null}
            {showEditProductOnWarehouse !== undefined ? <EditProductOnWarehouse
                save={
                    (data) => {
                        editProductOnWarehouse(data);
                    }
                }
                data={
                    showEditProductOnWarehouse
                }
                cancel={()=>{
                    setShowEditProductOnWarehouse(undefined);
                }}

            /> : null}


        </div>
    )
}

export default Warehouseplace;
