import React from "react";
import {
    DropdownItem, DropdownMenu,
    DropdownToggle,
    Table,
    UncontrolledButtonDropdown
} from "../../appp/components";

function OrderProductWarehause (props) {

    return(
        <React.Fragment>
      
    <Table responsive>
        <thead>
        <tr>
            <th className="bt-0">Product</th>
            <th className="bt-0">Opis</th>
            <th className="bt-0">Ilość</th>
            <th className="bt-0 text-right">Magazyn kod</th>
            <th className="bt-0 text-right">Regał</th>
            <th className="bt-0 text-right">Półka</th>
            <th className="bt-0 text-right">Miejsce</th>
            <th className="bt-0 text-right"> </th>

        </tr>
        </thead>
        <tbody>
        {props.data.map((row) =>
        <tr key={ row.productOnWarehousePlaceId } className="text-nowrap">
            <td className="align-middle">
                <span className="text-inverse">{row.name}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.description}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.count}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.idCode}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.rack}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.shelf}</span>
            </td>
            <td className="align-middle">
                <span className="text-inverse">{row.place}</span>
            </td>
            <td className="align-middle">
                 <button onClick={()=>props.completeOrder(row)}>{props.getDescription}</button>
                {props.handsGetDescription ?
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className={` text-decoration-none  `}>
                        <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => {props.onHandleSelected(row)}}>
                            <i className="fa fa-fw fa-edit mr-2"></i>
                            {props.handsGetDescription} 
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown> : <div/>}
            </td>
        </tr>)}
        </tbody>
    </Table>
            </React.Fragment>
    )
}

export default OrderProductWarehause