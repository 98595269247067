import React, {useCallback, useEffect, useState} from "react";

import {CheckIfRoleForComponentName} from "../../Users/roleHelpers";
import NewOrderRwComponent from "./DetailsComponents/newOrderRwComponent";
import AvailableProductsComponent from "./availableProductsComponent";
import {AddProductToOrderComponent} from "./DetailsComponents/addProductToOrderComponent";
import {CreateOrderRwQuery} from "../../Query/Orders/createOrderRwQuery";

const startOrder={
    listOfProduct: [],
    company : undefined,
    number:  undefined, 
    comment: undefined
}

export default function CreateOrderRwComponent (props) {
    const [listOfProduct, setListOfProduct] = useState([]);
    

    const [selectedOrder,setSelectedOrder]= useState(undefined);

    useEffect(()=>{
        setListOfProduct([]);
        startOrder.listOfProduct=[];
        setSelectedOrder({...startOrder});
    },[])
    

    const addProduct=(data) =>{
        const newList = JSON.parse(JSON.stringify(listOfProduct))
        newList.push(data);
        const nl=JSON.parse(JSON.stringify(newList));

        selectedOrder.listOfProduct=nl;
        //setSelectedOrder(selectedOrder);
        console.log(nl);
        console.log('addProduct',selectedOrder);
        setListOfProduct(nl);


    }

    const cancel=()=>{
        setSelectedOrder(undefined);
        setListOfProduct([]);
        startOrder.listOfProduct=[];
        startOrder.comment='';
        setSelectedOrder({...startOrder});
    }
    

    const onOrderSave=(data)=>{
        const prod = listOfProduct.map((op, i) => {
            return {
                productId: op.id,
                count: op.productCount,
                discount: op.discount,
                vat: op.vat,
                pricePln: op.newPrice,
            }
        });
        const dat = {
            companyId: 0,
            deliveryAddressId: 0,
            products: prod,
            // invoiceBonus,
            comment: data.comment,
        }

        console.log(dat);
        
            CreateOrderRwQuery(dat,(response)=>{
                    cancel();
            });
        

    }

    return (
        <React.Fragment>
            {selectedOrder ?
                <div>
                    <NewOrderRwComponent
                        data={selectedOrder}
                        onSave={onOrderSave}
                        onClose={cancel}
                        editDisable={!CheckIfRoleForComponentName('createOrder-edit-company')}
                    /> </div>: <div/>
            }
            <AvailableProductsComponent
                addProductDetailComponent={AddProductToOrderComponent}
                addProduct={addProduct}
                />
            


        </React.Fragment>

    )
}
