import React, {useRef, useState} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail, Selection, Toolbar, Item, ColumnChooser
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {CancelOrderQuery} from "../../../Query/Orders/cancelOrderQuery";
import {AddButtonsOnEnd, dataGridRefresh, useDxDataGridExpandedLoadQuery} from "../../Base/dxGridHellper";
import ConstantsMessages from "../../../Consts/messages";
import OrderExpandedComponent from "../DetailsComponents/orderExpandedComponent";
import {GetOrderDetailsQuery} from "../../../Query/Orders/getOrderDetailQuery";
import {OrdersColumnsDef} from "../../../ColumnsDef/Orders/ordersColumnsDef";
import {CreateInvoiceQuery} from "../../../Query/Documents/createInvoiceQuery";
import {GetInvoicePdfQuery} from "../../../Query/Documents/getInvoicePdfQuery";
import {GetExistedPackagePdfQuery} from "../../../Query/Documents/getExistedPackagePdfQuery";
import {OrderToReturnQuery} from "../../../Query/Orders/orderToReturnQuery";
import {OrderToReturnComponent} from "../OperationsComponents/orderToReturnComponent";
import {AcceptOrderQuery} from "../../../Query/Orders/acceptOrderQuery";
import {GetPackagePdfQuery} from "../../../Query/Documents/getPackagePdfQuery";
import {OrderToAcceptDxStore} from "../../../Query/Orders/orderToAcceptDxStore";


export default function OrdersToAcceptComponent (props) {

    const [orderToReturn,setOrderToReturn]=useState(undefined);
    const ordersDataGridRef = useRef();
    // const [ordersColumns]=useState(
    //     AddButtonsOnEnd(OrdersColumnsDef,
    //         [
    //             {
    //                 name:'customDelete',
    //                 hint: 'Anulowanie / Zwrot',
    //                 icon:'remove',
    //                 onClick: (e)=> cancelSelectedOrder(e.row.data)
    //             }
    //         ])
    // )

    const acceptSelectedOrder=(data)=> {
        if( data.packageNr===null || data.packageNr==='')
        {
            if (window.confirm(ConstantsMessages.ORDER_ACCEPT_ORDER_CONFIRM)) {
                AcceptOrderQuery(data, () => {
                    dataGridRefresh(ordersDataGridRef);
                    reloadData((newData)=>{});
                });
            }
        }
        else
        {
            AcceptOrderQuery(data, () => {
                dataGridRefresh(ordersDataGridRef);
                reloadData((newData)=>{});
            });
        }
    }

    const cancelSelectedOrder=(data)=>{

        if(data.orderStatus==="Wysłane")
        {
            setOrderToReturn(data);
        }
        else
        {
            if (window.confirm(ConstantsMessages.ORDER_CANCEL_ORDER_CONFIRM)) {
                CancelOrderQuery(data,()=>{
                    dataGridRefresh(ordersDataGridRef);
                });
            }
        }
    }

    const onReturn=(data)=>{
        OrderToReturnQuery(data,()=>{
            setOrderToReturn(undefined)
        });
    }



    const createInvoice=(data)=>{

        CreateInvoiceQuery(data,()=>{
            dataGridRefresh(ordersDataGridRef);
            reloadData((newData)=>{});
        });
    }

    const getInvoicePdf=(data)=>{
        GetInvoicePdfQuery(data,()=>{
            dataGridRefresh(ordersDataGridRef);
        });
    }

    const getPackagePdf=(data)=>{
        data.paymentType= data.invoicePaymentType;
        GetPackagePdfQuery(data,()=>{
            dataGridRefresh(ordersDataGridRef);
            reloadData((newData)=>{});
        });
    }

    const getExistedPackagePdf=(data)=>{
        GetExistedPackagePdfQuery(data,()=>{
            dataGridRefresh(ordersDataGridRef);
        });
    }



    const actions={
        createInvoice: createInvoice,
        getInvoicePdf: getInvoicePdf,
        getPackagePdf: getPackagePdf,
        getExistedPackagePdf: getExistedPackagePdf,
        onCancel: cancelSelectedOrder,
        acceptSelectedOrder: acceptSelectedOrder
    }

    const [contentReady, selectionChanged, renderDetailSection, reloadData]=useDxDataGridExpandedLoadQuery(GetOrderDetailsQuery,OrderExpandedComponent,actions);

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            { orderToReturn===undefined ?
                <DataGrid
                    keyExpr="id"
                    dataSource={OrderToAcceptDxStore}
                    showBorders={true}
                    remoteOperations={true}
                    columnAutoWidth={true}
                    ref={ordersDataGridRef}
                    columns={OrdersColumnsDef}
                    onSelectionChanged={selectionChanged}
                    onContentReady={contentReady}
                >
                    <Selection mode="single"/>
                    <GroupPanel visible={true}/>
                    <SearchPanel visible={true}/>
                    <FilterRow visible={true}/>
                    <Paging defaultPageSize={12}/>
                    <Pager
                        showPageSizeSelector={true}
                    />
                    <MasterDetail enabled={false} render={renderDetailSection}/>
                    <Toolbar>
                        <Item location="before" name="searchPanel"/>

                    </Toolbar>

                </DataGrid> :

                <OrderToReturnComponent
                    data={orderToReturn}
                    onCancel={()=>{setOrderToReturn(undefined)}}
                    onReturn={onReturn}
                />
            }
        </div>
    )
}