import React, {useCallback, useEffect, useState} from "react";

import {CheckIfRoleForComponentName} from "../../Users/roleHelpers";
import SelectCompanyComponent from "../Managment/Company/selectCompanyComponent";
import {GetCompanyDetailsQuery} from "../../Query/Company/getCompanyDetailsQuery";
import AvailableProductsComponent from "./availableProductsComponent";
import NewOrderComponent from "./DetailsComponents/newOrderComponent";
import {CompanyNewModel} from "../../Models/companyNewModel";
import {AddNewCompanyQuery} from "../../Query/Company/addNewCompanyQuery";
import {EditCompanyQuery} from "../../Query/Company/editCompanyQuery";
import {EditCompanyComponent} from "../Managment/Company/editCompanyComponent";
import {useGetCompanyRepresentative} from "../../Query/Company/useGetCompanyRepresentative";
import {Button} from "devextreme-react/button";
import {CreateOrderQuery} from "../../Query/Orders/createOrderQuery";
import {CreateOrderWithInvoiceQuery} from "../../Query/Orders/createOrderWithInvoiceQuery";
import {GetPackagePdfQuery} from "../../Query/Documents/getPackagePdfQuery";
import {AddProductToOrderComponent} from "./DetailsComponents/addProductToOrderComponent";

const startOrder={
    listOfProduct: [],
    company : undefined,
    number:  undefined // '20210112321'
}

export default function CreateOrderComponent (props) {
    const [selectedCompany, setSelectedCompany] = useState(props.company);
    const [listOfProduct, setListOfProduct] = useState([]);
    const [isFromCompany,setIsFromCompany]=useState(props.isFromCompany);
    const [companyToEdit, setCompanyToEdit] = useState(undefined);

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative] = useGetCompanyRepresentative();


    const [selectedOrder,setSelectedOrder]= useState(undefined);
    
    useEffect(()=>{
        setListOfProduct([]);
        setSelectedOrder(undefined);
        setSelectedCompany(undefined);
        setCompanyToEdit(undefined);
    },[])

    const onCompanySelect=(data)=>
    {
        GetCompanyDetailsQuery(data.id,(response)=>{
            
            setSelectedCompany(response.data);
            const newOrder={...startOrder};
            newOrder.listOfProduct=[];
            newOrder.company=response.data;
            newOrder.companyView=data;
            setSelectedOrder(newOrder);
            console.log('onCompanySelect',newOrder);
        });

    }

    const addProduct=(data) =>{
        const newList = JSON.parse(JSON.stringify(listOfProduct))
        newList.push(data);
        const nl=JSON.parse(JSON.stringify(newList));

        selectedOrder.listOfProduct=nl;
        //setSelectedOrder(selectedOrder);
        console.log(nl);
        console.log('addProduct',selectedOrder);
        setListOfProduct(nl);


    }

    const cancel=()=>{
        // if(!isFromCompany) {
        //     setListOfProduct([]);
        //     setSelectedOrder(undefined);
        //     setSelectedCompany(undefined);
        // }
        // else {
        //     setListOfProduct([]);
        //     startOrder.listOfProduct=[];
        //     startOrder.company=props.company;
        //     setSelectedOrder(startOrder);
        // }

        setListOfProduct([]);
        setSelectedOrder(undefined);
        setSelectedCompany(undefined);
    }

    const onCompanyInsert = useCallback( () => {
        setCompanyToEdit({...CompanyNewModel});
    },[]);

    const onCompanySave=useCallback((data) => {
        if(data.id===0)
        {
            AddNewCompanyQuery(data,()=>{
                cancel();
            });
        }
        else {
            EditCompanyQuery(data,()=>{
               // dataGridRefresh(companiesDataGridRef);
               cancel();
            });
        }
        setCompanyToEdit(undefined);
    },[]);
    
    const onOrderSave=(data)=>{
        const prod = listOfProduct.map((op, i) => {
            return {
                productId: op.id,
                count: op.productCount,
                discount: op.discount,
                vat: op.vat,
                pricePln: op.newPrice,
            }
        });
        const dat = {
            companyId: data.companyId,
            deliveryAddressId: data.deliveryAddressId,
            products: prod,
            isInvoice: data.isInvoice,
            paymentType: data.invoicePaymentType,
            // invoiceBonus,
            invoicePaymentDate: data.invoicePaymentDate,
            isDpdPackage:data.isDpd,
            invoiceNr: data.invoiceNr,
            comment: data.comment,
            packageWeight:data.packageWeight
            
        }

        console.log(dat);
        if (dat.isInvoice) {
            CreateOrderWithInvoiceQuery(dat,(response)=>{
                if(dat.isDpdPackage)
                {
                    console.log(response);
                    const orderId = response.headers['content-disposition']
                        .split('filename=')[1].split(';')[0];

                    dat.id = orderId;

                   GetPackagePdfQuery(dat,()=>{
                       cancel();
                   });
                }
                else {
                    cancel();
                }
               
            });
        }
        else{
            CreateOrderQuery(dat,(response)=>{
                if(dat.isDpdPackage)
                {
                    dat.id = response.data;

                    GetPackagePdfQuery(dat,()=>{
                        cancel();
                    });
                }
                else
                {
                    cancel();
                }
               
            });
        }
        
    }

    return (
        <React.Fragment>
                    {selectedOrder ?
                        <div>
                            <Button   type={"default"}  width={"100%"}  stylingMode={"contained"} onClick={()=>{setCompanyToEdit(selectedCompany)}} >
                               Edytuj firmę
                            </Button>
                            <NewOrderComponent 
                                data={selectedOrder}
                                onSave={onOrderSave}
                                onClose={cancel}
                                editDisable={!CheckIfRoleForComponentName('createOrder-edit-company')}
                                     /> </div>: <div/>
                    }
                 
                                {!selectedCompany && companyToEdit === undefined ? <SelectCompanyComponent
                                        onInsert={onCompanyInsert}
                                        onSelect={onCompanySelect}
                                    /> :
                                     companyToEdit === undefined ? 
                                         <AvailableProductsComponent
                                             addProductDetailComponent={AddProductToOrderComponent}
                                             addProduct={addProduct} 
                                             isHidden={isFromCompany}/>
                                    : <div/>
                                }
            {companyToEdit !== undefined ? <EditCompanyComponent
                save={
                    (data) => {
                        onCompanySave(data)
                    }
                }
                data={companyToEdit}
                companyRepresentative={companyRepresentative}
                cancel={()=>{
                    setCompanyToEdit(undefined);
                }}

            /> : null}
                          

        </React.Fragment>

    )
}
