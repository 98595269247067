import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const RETURN_ORDER_URL = 'Order/OrderToReturn'

export const OrderToReturnQuery = (data, onResponse) => {
    return DefaultPostQuery(
        RETURN_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_TO_RETURN_ORDER,
        OrderToReturnQuery.name,
        data,
        onResponse
    )
}