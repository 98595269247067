
import React from "react";

export const AvailableProductsColumnsDef = [
    {
        dataField: "idCode",
        caption: "Kod Id",
        dataType: "string"
    },
    {
        dataField: "availableCount",
        caption: "Dostępna ilość",
        dataType: "number"
    },
    {
        dataField: "name",
        caption: "Nazwa",
        dataType: "string"
    },
    {
        dataField: "description",
        caption: "Opis",
        dataType: "string"
    },
    {
        dataField: "nameEng",
        caption: "Name",
        dataType: "string"
    },
    {
        dataField: "descriptionEng",
        caption: "Description",
        dataType: "string"
    },
    {
        dataField: "brand",
        caption: "Marka",
        dataType: "string"
    },
    {
        dataField: "type",
        caption: "Typ",
        dataType: "string"
    },    
    {
        dataField: "subtype",
        caption: "Podtyp",
        dataType: "string"
    },    
    {
        dataField: "price",
        caption: "Cena",
        dataType: "number"
    },    
    {
        dataField: "priceForProfessionals",
        caption: "Cena dla profsionalistów",
        dataType: "number"
    },    

]
    
