import React from "react";

const getMonthFromDate = (date) =>
{
    return new Date(date.getFullYear(),date.getMonth(),0,0,0,0,0);
}

export const getMonthFromOperationDate=(rowData)=>{
    const newDate=  getMonthFromDate(rowData.operationDate);
    console.log(newDate);
    return new Date();
}


export const SellingReportColumnsDef = [
        {
            dataField: "idCode",
            caption: "Produkt",
            dataType: "string"
        },
        {
            dataField: "productName",
            caption: "Nazwa produktu",
            dataType: "string"
        },
        {
            dataField: "operationDate",
            caption: "Data operacji",
            dataType: "datetime",
            format: "dd.MM.yyyy, HH:mm",
            sortOrder: "desc",
        },
        {
            dataField: "count",
            caption: "Ilość",
            dataType: "number"
        },
        {
            dataField: "userName",
            caption: "Użytkownik operacji",
            dataType: "string"
        },
        {
            dataField: "companyName",
            caption: "Nazwa firmy",
            dataType: "string"
        },
        {
            dataField: "price",
            caption: "Kwota",
            dataType: "number"
        },
        {
            dataField: "warehouseIdCode",
            caption: "Magazyn",
            dataType: "string"
        },
        {
            dataField: "orderNr",
            caption: "Numer zamówienia",
            dataType: "string"
        },
    {
        dataField: "createdDate",
        caption: "Data utworzenia",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm",
        groupInterval: "month"
    },
    {
            dataField: "invoiceNr",
            caption: "Faktura",
            dataType: "string"
        },{
            dataField: "packageNr",
            caption: "Numer przesyłki",
            dataType: "string"
        },
        {
            dataField: "priceWz",
            caption: "Kwota Wz",
            dataType: "number"
        },
    {
        dataField: "createdByUser",
        caption: "Zamówienie utworzone przez",
        dataType: "string"
    },
    {
        dataField: "representativeName",
        caption: "Reprezantant",
        dataType: "string"
    },
    ]
