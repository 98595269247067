import {useState} from "react";

export const dataGridRefresh = (dataGridRef) =>
{
    dataGridRef.current.instance.refresh();
}

export const dataGridEditSave = (dataGridRef) =>
{
    return dataGridRef.current.instance.saveEditData();
}

export const dataGridGetObject = (dataGridRef) =>
{
    return dataGridRef.current.instance;
}

export const dataGridCollapseAll = (dataGridRef) =>
{
    return dataGridRef.current.instance.collapseAll(-1);
}


export const useDxDataGridExpandedLoadQuery = (loadQuery,expandedComponent,expandComponentProps) =>
{
    const [selectedData,setSelectedData]=useState({});
    const [keyId,setKeyId]=useState(undefined)

    const contentReady = (e) => {
      //  if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
    }

    const selectionChanged = (e) => {
        e.component.collapseAll(-1);
        if(e.currentSelectedRowKeys[0]!==undefined) {
            setKeyId(e.currentSelectedRowKeys[0]);
            loadQuery(e.currentSelectedRowKeys[0], (result) => {
                console.log(result.data);
                setSelectedData(result.data);
                e.component.expandRow(e.currentSelectedRowKeys[0]);
            })
        }
    }

    const renderDetailSection =() => {
        expandComponentProps.data=selectedData;
        return expandedComponent(expandComponentProps);
    }
    
    const reloadData= (onReload) => {
        console.log('reload',keyId);
         loadQuery(keyId,(result)=>
        {
            console.log(result)
            setSelectedData(result.data);
            onReload(result.data);
        })
    }

    return [contentReady, selectionChanged, renderDetailSection,reloadData];
}

export const useDxDataGridExpanded = (expandedComponent,expandComponentProps) =>
{
    const contentReady = (e) => {
      //  if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
    }

    const selectionChanged = (e) => {
        e.component.collapseAll(-1);         
        e.component.expandRow(e.currentSelectedRowKeys[0]);
    }

    const renderDetailSection =(props) => {
        expandComponentProps.data=props.data;
        return expandedComponent(expandComponentProps);
    }
    

    return [contentReady, selectionChanged, renderDetailSection];
}

export const AddButtonsOnEnd = (columns, buttonsArray) =>
{
    const newElement={
        type: "buttons",
        caption: "",
        buttons: buttonsArray
    }
    return [...columns, newElement];
}

export const AddButtonsOnBegin = (columns, buttonsArray) =>
{
    const newElement={
        type: "buttons",
        caption: "",
        buttons: buttonsArray
    }
    return [newElement,...columns];
}