import React from "react";
import {OrderStatusEnum} from "../../Enums/orderStatusEnum";



export const OrdersColumnsDef = [
    {
        dataField: "orderNr",
        caption: "Nr zamówienia",
        dataType: "string"
    },
    {
        dataField: "nopcommerceOrderNr",
        caption: "Nr zamówienia Sklep",
        dataType: "string"
    },
    {
        dataField: "companyNip",
        caption: "Nip",
        dataType: "string"
    },
    {
        dataField: "companyName",
        caption: "Nazwa firmy",
        dataType: "string"
    },
    {
        dataField: "companyAddress",
        caption: "Adres firmy",
        dataType: "string"
    },
    {
        dataField: "deliveryAddress",
        caption: "Adres dostawy",
        dataType: "string"
    },
    {
        dataField: "createdDate",
        caption: "Data utworzenia",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm",
        sortOrder: "desc"
    },
    {
        dataField: "contactName",
        caption: "Osoba kontaktowa",
        dataType: "string"
    },
    {
        dataField: "contactPhone",
        caption: "Telefon",
        dataType: "string"
    },
    {
        dataField: "contactEmail",
        caption: "Email",
        dataType: "string"
    },
    {
        dataField: "orderStatus",
        caption: "Status",
        dataType: "object",
        lookup: {
            dataSource: OrderStatusEnum,
            valueExpr: "id",
            displayExpr: "desc"
        }
    },
    {
        dataField: "packedDate",
        caption: "Data spakowania",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm"
    },
    {
        dataField: "sendDate",
        caption: "Data wysłania",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm"
    },
    {
        dataField: "packageNr",
        caption: "Numer przesyłki",
        dataType: "string"
    },
]
    
