import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Editing, Summary, TotalItem} from "devextreme-react/data-grid";
import React, {useEffect, useRef, useState} from "react";
import {ProductsOnOrderColumnsDef} from "../../../ColumnsDef/Products/productsOnOrderColumnsDef";
import {dataGridRefresh} from "../../Base/dxGridHellper";
import {Button} from "devextreme-react/button";
import ButtonCancel from "../../Base/Buttons/buttonCancel";
import ButtonSave from "../../Base/Buttons/buttonSave";


let startDate=new Date()
startDate.setDate(startDate.getDate() + 7);

const defaultData={
    discount:0,
    vat: 23,
    bonus: 0,
    invoicePaymentDate: startDate,
    isDpd: true,
    isInvoice: true,
}


export default function NewOrderRwComponent (props) {

    const [dataProducts,setDataProducts]=useState([])
    const [data,setData]=useState({...defaultData})

    const productsDataGridRef = useRef();
    
    const CalculationsProductValues = (e) => {
        props.data.listOfProduct.map((op, i) => {
            const productDiscount=100;
            if (op.vat === undefined) op.vat = data.vat;

                op.newPrice = Number((0).toFixed(2));
             
        })
        if (productsDataGridRef.current !== undefined)
            dataGridRefresh(productsDataGridRef);
    }
    

    useEffect(()=>{

        CalculationsProductValues();

    },[props.data.listOfProduct])



    const onSave=()=>{

        
        props.onSave(data);
        

    }

    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie RW</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={data} labelMode='floating' colCount={1}
                      showColonAfterLabel={true}
                      showValidationSummary={true}
                      validationGroup="orderData"
                >
                    <GroupItem caption="Ustawienia" colCount={2}>
                        <SimpleItem dataField="comment" editorType={dxTextArea}>
                            <Label text='Uwagi'/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.listOfProduct}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            columns={ProductsOnOrderColumnsDef}
                            ref={productsDataGridRef}
                        >
                            <Summary>
                                <TotalItem
                                    column="fullPrice"
                                    summaryType="sum"
                                />
                                <TotalItem
                                    column="weight"
                                    summaryType="sum"
                                />
                            </Summary>
                        </DataGrid>
                    </GroupItem>
                </Form>
                <ButtonSave onClick={onSave}
                >
                 
                </ButtonSave>
                <ButtonCancel onClick={()=>props.onClose()} />

            </div>
        </React.Fragment>
    )
}