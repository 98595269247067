import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const RETURN_ORDER_URL = 'Order/ReturnDataFromOrder'

export const ReturnOrderQuery = (data, onResponse) => {
    return DefaultPostQuery(
        RETURN_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_RETURN_ORDER,
        ReturnOrderQuery.name,
        data,
        onResponse
    )
}