import React, {useEffect, useRef, useState} from "react";
import {GetODOrdersInvoicesQuery} from "../../Query/Orders/getODOrdersInvoicesQuery";
import {DataGrid, SelectBox, Tabs} from "devextreme-react";
import {GetOrderDetailsQuery} from "../../Query/Orders/getOrderDetailQuery";
import PickupToOrderDetailComponent from "./pickupToOrderDetailComponent";
import {PickupReportDxStore} from "../../Query/Reports/pickupReportDxStore";
import {PickupReportColumnsDef} from "../../ColumnsDef/Reports/pickupReportColumnDef";
import {
    ColumnChooser,
    Export, FilterPanel,
    FilterRow,
    Grouping, GroupItem,
    GroupPanel, HeaderFilter, Item, Pager, Paging,
    SearchPanel,
    Selection, Summary, Toolbar
} from "devextreme-react/data-grid";
import {allowedPageSizesDefaultOption, defaultPageSizeDefaultOption} from "../../Consts/defaultOptions";
import {Button} from "devextreme-react/button";
import {PickupReportsAllDxStore} from "../../Query/Reports/pickupReportsAllDxStore";
import {PickupReportsForInvoiceIdDxStore} from "../../Query/Reports/pickupReportsForInvoiceIdDxStore";
import {SetPickupToOrderQuery} from "../../Query/Orders/setPickupToOrderQuery";
import {dataGridRefresh} from "../Base/dxGridHellper";

export const tabs = [
    {
        id: 0,
        text: 'Nie przypisane',
    },
    {
        id: 1,
        text: 'Wszystkie',
    },
    {
        id: 2,
        text: 'Przypisane do faktury',
    },
];
export default function PickupToOrderComponent (props) {
    const [invoices, setInvoices] = useState([]);
    const [invoiceData,setInvoiceData]=useState(undefined);
    const [pickupTabsSelectedIndex,setPickupTabsSelectedIndex]=useState(0);
    const [selectedPickupStore,setSelectedPickupStore]=useState(PickupReportDxStore)
    const dataGridRef = useRef();

    useEffect(()=>{
        GetODOrdersInvoicesQuery((result)=>{
            setInvoices(result.data);
        })
    },[])
    
    const onSelectInvoice=(id)=>{
        GetOrderDetailsQuery(id,(response)=> {
            setInvoiceData(response.data);
            setPickupTabsSelectedIndex(2);
            setSelectedPickupStore(PickupReportsForInvoiceIdDxStore(response.data.id));
            dataGridRefresh(dataGridRef);

        });
    }

    const setSelectedDataToInvoice = () => {
        const data=dataGridRef.current.instance.getSelectedRowKeys();
        console.log(data);

        const dat={
            operationsIds: data,
            orderId: invoiceData.id
        }
        SetPickupToOrderQuery(dat,(response)=>{
            setSelectedPickupStore(PickupReportDxStore)
            dataGridRefresh(dataGridRef);
        });

    }
    
    const onOptionChanged=(args)=>{
        if (args.name === 'selectedIndex') {
            setPickupTabsSelectedIndex(args.value);
            if(args.value===1)
            {
                setSelectedPickupStore(PickupReportsAllDxStore)
            }
            else if(args.value===2)
            {
                setSelectedPickupStore(PickupReportsForInvoiceIdDxStore(invoiceData.id));
                
            }
            else if(args.value===0)
            {
                setSelectedPickupStore(PickupReportDxStore)
            }
        }
    }

    return (
        <React.Fragment>
            <h3 className={'content-block'}>Przyjęcia towaru</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <SelectBox dataSource={invoices}
                           label="Wybierz fakturę przyjęcia"
                           labelMode="floating"
                           valueExpr="orderId"
                           displayExpr="invoice"
                           searchEnabled={true}
                           searchMode='contains'
                           searchExpr='invoice'
                           searchTimeout={200}
                           minSearchLength={0}
                           showDataBeforeSearch={true}
                           onValueChanged={(e)=>{onSelectInvoice(e.value)}}

                />
                <div>

                {invoiceData ? <div>
                    <PickupToOrderDetailComponent
                    data={invoiceData}
                     />
                    <div className={'content-block dx-card responsive-paddings'}>
                        <Tabs
                            dataSource={tabs}
                            onOptionChanged={onOptionChanged}
                            selectedIndex={pickupTabsSelectedIndex}
                        />
                       
                    </div>

                    <div className={'content-block dx-card responsive-paddings'}>
                        <Button type={"success"} onClick={setSelectedDataToInvoice}>
                            Przypisz zaznaczone do faktury
                        </Button>
                        <DataGrid
                            keyExpr="id"
                            dataSource={selectedPickupStore}
                            showBorders={true}
                            remoteOperations={true}
                            columnAutoWidth={true}
                            columns={PickupReportColumnsDef}
                            ref={dataGridRef}
                        >
                            <Selection
                                mode="multiple"
                                selectAllMode='allPages'
                                showCheckBoxesMode='always'
                            />
                            <FilterRow visible={true}/>
                            <HeaderFilter visible={true} />
                            <FilterPanel visible={true} />
                            <Paging defaultPageSize={defaultPageSizeDefaultOption} />
                            <Pager
                                allowedPageSizes={allowedPageSizesDefaultOption}
                                showPageSizeSelector={true}
                            />
                            
                        </DataGrid>
                    </div>
                    </div>
                    : <div/>}
                </div>
            </div>
            
        </React.Fragment>
    )
}
