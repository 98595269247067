import {DataGrid, Form, ResponsiveBox} from "devextreme-react";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import React from "react";
import {OrderODDetailProductsColumnDef} from "../../ColumnsDef/Orders/orderODDetailProductsColumnDef";

export default function PickupToOrderDetailComponent (props) {

    return (
        <React.Fragment>
            <h3 className={'content-block'}>Faktura nr {props.data.invoiceNr}</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={props.data} colCount={2} labelMode='floating' readOnly={true} >
                    <SimpleItem dataField="companyName">
                        <Label text='Nazwa'/>
                    </SimpleItem>
                    <SimpleItem dataField="companyAddress" editorType={dxTextArea}>
                        <Label text='Adres'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactName">
                        <Label text='Kontakt'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactPhone">
                        <Label text='Telefon'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactEmail">
                        <Label text='E-mail'/>
                    </SimpleItem>
                    <SimpleItem 
                        dataField="createdDate"   
                        editorType="dxDateBox"
                        editorOptions={
                                    {displayFormat: "dd.MM.yyyy" }
                                }>
                        <Label text='Data utworzenia'/>
                    </SimpleItem>
                </Form>
                <Form formData={props.data} labelMode='floating' readOnly={true}>
                    <SimpleItem dataField="comment" editorType={dxTextArea}>
                        <Label text='Uwagi'/>
                    </SimpleItem>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.productOnOrderDetail}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            columns={OrderODDetailProductsColumnDef}
                        >
                        </DataGrid>
                    </GroupItem>
                </Form>
            </div>
        </React.Fragment>
    )
}