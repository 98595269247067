import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Summary, TotalItem} from "devextreme-react/data-grid";
import React, {useEffect, useState} from "react";
import {OrderDetailProductsColumnsDef} from "../../../ColumnsDef/Orders/orderDetailProductsColumnsDef";
import {getFullWeight} from "../../../ColumnsDef/Products/productsOnOrderColumnsDef";
import {Button} from "devextreme-react/button";
import ConstantsMessages from "../../../Consts/messages";
import {CancelOrderQuery} from "../../../Query/Orders/cancelOrderQuery";
import {dataGridRefresh} from "../../Base/dxGridHellper";
import {ShowErrorNotification} from "../../../notifications";

const invoicePaymentTypeEnum = ["PRZ", "POB","GTK"];


export default function OrderDetailAgentComponent (props) {

    const [orderData,setOrderData]=useState({...props.data});
    
    useEffect(()=>{
        console.log(props.data);
        let startDate=new Date()
        const data={...props.data}
        startDate.setDate(startDate.getDate() + 7);
        data.paymentType=data.invoicePaymentType;
        if(data.paymentDate)
        {
            data.invoicePaymentDate=data.paymentDate;
        }
        else {
            data.invoicePaymentDate=startDate;
        }
       
        console.log(data);

        setOrderData(data)
        
    },[])
    
    const createInvoice = (data) => {
        if(data.paymentType===undefined)
        {
            ShowErrorNotification("Zamowienie", "Wybier typ płatności");
        }
        else{
            props.createInvoice(data);
        }
      
    }
    
    const acceptOrder = (data) =>{
      if(data.invoiceNr===null || data.invoiceNr==='')
        {
            ShowErrorNotification("Zamowienie", "wpisz numer faktury lub wybierz generowanie");
        }
      else {
          props.acceptSelectedOrder(data);
      }
    }
    
    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie {props.data.orderNr}</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={props.data} colCount={2} labelMode='floating' readOnly={true} >
                    <SimpleItem dataField="companyName">
                        <Label text='Nazwa'/>
                    </SimpleItem>
                    <SimpleItem dataField="companyAddress" editorType={dxTextArea}>
                        <Label text='Adres'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactName">
                        <Label text='Kontakt'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactPhone">
                        <Label text='Telefon'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactEmail">
                        <Label text='E-mail'/>
                    </SimpleItem>
                    <SimpleItem dataField="deliveryAddress" editorType={dxTextArea}  editorOptions={{ autoResizeEnabled : true}}>
                        <Label text='Adres dostawy'/>
                    </SimpleItem>
                </Form>
                <Form formData={props.data} labelMode='floating' readOnly={true}>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.productOnOrderDetail}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={false}
                            columns={OrderDetailProductsColumnsDef}
                        >
                            <Summary>
                                <TotalItem
                                    column="fullPrice"
                                    summaryType="sum"
                                />
                            </Summary>
                        </DataGrid>
                    </GroupItem>
                </Form>
                <Form formData={orderData} labelMode='floating' readOnly={false}>
                    <SimpleItem dataField="comment" editorType={dxTextArea}>
                        <Label text='Uwagi'/>
                    </SimpleItem>
                    <SimpleItem dataField="packageNr">
                        <Label text='Numer przesyłki'/>
                    </SimpleItem>
                    <SimpleItem dataField="invoiceNr">
                        <Label text='Numer faktury'/>
                    </SimpleItem>
                    <SimpleItem
                        dataField="paymentType"
                        editorType="dxSelectBox"
                        editorOptions={{
                            items:invoicePaymentTypeEnum,
                            searchEnabled: false,
                        }}
                    >
                        <RequiredRule message="Wybierz rodzaj płatności" />
                        <Label text=' Rodzaj płatności'/>
                    </SimpleItem>
                    <SimpleItem
                        dataField="invoicePaymentDate"
                        editorType="dxDateBox"
                        editorOptions={
                            {displayFormat: "dd.MM.yyyy" }
                        }>
                        <Label text='Data płatności'/>
                    </SimpleItem>
                </Form>
                <div>
                    {props.data.invoiceNr===null ?
                        <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{createInvoice(orderData)}}>
                            Utwórz fakturę
                        </Button>
                        :
                        <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.getInvoicePdf(props.data)}}>
                            Pobierz fakturę
                        </Button>
                    }
                </div>
                <div>
                    <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.getPackagePdf(orderData)}}>
                        Pobierz list przewozowy
                    </Button>
                </div>
                <div>
                    <Button type={"success"} width={"100%"} stylingMode={"contained"} onClick={()=>{acceptOrder(orderData)}}>
                        Akceptuj
                    </Button>
                </div>
                <div>
                    <Button width={"100%"} stylingMode={"contained"} onClick={()=>{props.onCancel(props.data)}}>
                        Zwrot / Anulowanie
                    </Button>
                </div>
            </div>

        </React.Fragment>
    )
}