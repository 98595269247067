import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "../../appp/components";

const WarehouseProductRow = (props)=>
{
    return (
        <React.Fragment>
            <td className="align-middle">
                <div>
                    {props.data.productIdCode}
                </div>
            </td>
            <td className="align-middle">
                <div>
                    {props.data.name}
                </div>
            </td>
            <td className="align-middle">

                {props.data.count > 25 ?
                    <span className="text-info">
                            {props.data.count}
                        </span> :
                    <span className="text-danger">
                            {props.data.count}
                        </span>
                }
            </td>
            <td className="align-middle">
                <div>
                    {props.data.expirationDate}
                </div>
            </td>
            <td className="align-middle text-right">
                {/*{CheckRolesComponentName(user.role,'storage-edit-product') ?*/}
                {props.showEdit ?
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className={` text-decoration-none  `}>
                        <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => props.EditProductOnWarehouse(props.data)}>
                            <i className="fa fa-fw fa-edit mr-2"></i>
                            Edycja produktu na magazynie
                        </DropdownItem>
                        {/*<DropdownItem onClick={() => alert(2)}>*/}
                        {/*    <i className="fa fa-fw fa-trash mr-2"></i>*/}
                        {/*    Pobierz z magazynu*/}
                        {/*</DropdownItem>*/}
                    </DropdownMenu>
                </UncontrolledButtonDropdown> : <div></div>}
            </td>
        </React.Fragment>
        
    )

}

export default WarehouseProductRow;