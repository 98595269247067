/* eslint-disable no-unused-vars */
import React from "react";
import { UserContext } from "./UserContextProvider";
import { Roles } from "./roleSettings";

/**
 * @return {boolean}
 */
export function CheckRoles(role, url) {
    // return true; //tymczasowo nim nie skończe

    if (Array.isArray(role)) {
        const userRoles = Roles.filter((r) => role.some((x) => r.name === x));

        return userRoles.some((ro) => {
            return ro.urls.some((ee) => ee === url);
        });
    } else {
        let ro = Roles.find((ee) => ee.name === role);
        //console.log(ro);
        if (ro === undefined || ro === null) {
            ro = Roles.find((ee) => ee.name === "default");
        }
        //console.log(ro);
        return ro.urls.some((ee) => ee === url);
    }
}

/**
 * @return {boolean}
 */
export function CheckRolesForUrl(url) {
    const [user, setUser] = React.useContext(UserContext);

    if (user !== null && user.role !== null) {
        return CheckRoles(user.role, url);
    }
    return false;
}

/**
 * @return {boolean}
 */
export function CheckRolesComponentName(role, componentName) {
    //return true; //tymczasowo nim nie skończe

    if (Array.isArray(role)) {
        const userRoles = Roles.filter((r) => role.some((x) => r.name === x));

        return userRoles.some((ro) => {
            return ro.componentsVisible.some((ee) => ee === componentName);
        });
    } else {
        let ro = Roles.find((ee) => ee.name === role);
        if (ro === undefined || ro === null) {
            ro = Roles.find((ee) => ee.name === "default");
        }

        return ro.componentsVisible.some((ee) => ee === componentName);
    }
}

export const CheckRoleForComponent = (componentName, component) => {
    const [user, setUser] = React.useContext(UserContext);

    if (
        user &&
        user.role !== null &&
        CheckRolesComponentName(user.role, componentName)
    ) {
        return component;
    } else {
        return <React.Fragment />;
    }
};

export function CheckIfRoleForComponentName (componentName)  {
    const [user, setUser] = React.useContext(UserContext);

    if (
        user &&
        user.role !== null &&
        CheckRolesComponentName(user.role, componentName)
    ) {
        return true
    } else {
        return false
    }
};

export function GetUserCompany() {
    const [user, setUser] = React.useContext(UserContext);

    if (user !== null && user.role !== null) {
        return user.company;
    }
    return false;
}