import { HolderTextProvider } from './HolderTextProvider';
import { HolderIconProvider } from './HolderIconProvider';

const HolderProvider = {
    Text: HolderTextProvider,
    Icon: HolderIconProvider
};

export default HolderProvider;

