import React from "react";
import CreateOrderComponent2 from "../NewOrder/createOrderComponent2";


function NewOrderUser (props) {

    return (
        <React.Fragment>
        <CreateOrderComponent2 company={undefined} isFromCompany={false} />
        </React.Fragment>
    )
}

export default NewOrderUser;
