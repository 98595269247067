import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Editing, Summary, TotalItem} from "devextreme-react/data-grid";
import React, {useEffect, useRef, useState} from "react";
import {dataGridRefresh} from "../../Base/dxGridHellper";
import {Button} from "devextreme-react/button";
import ButtonCancel from "../../Base/Buttons/buttonCancel";
import {ProductsOnOrderODColumnsDef} from "../../../ColumnsDef/Products/productsOnOrderODColumnsDef";
import {GetNbpUsdGetQuery} from "../../../Query/NbpGetQuery";

const deliveryAddressDisplayExpr= function(item) {
    return item && item.name + ' '+ item.street+ ' '+ item.number + ' '+ item.localNumber + ' ' +item.city;
}

let startDate=new Date()
startDate.setDate(startDate.getDate() + 7);

const defaultData={
    invoiceCreatedDate: new Date(),
    invoicePaymentDate: startDate,
    invoiceReturnDate: new Date(),
    usdCurrency: 1.0

}



export default function NewOrderODComponent (props) {

    const [dataCompany,setDataCompany]=useState(props.data.company)
    const [dataProducts,setDataProducts]=useState([])
    const [data,setData]=useState({
        invoiceCreatedDate: new Date(),
        invoicePaymentDate: startDate,
        invoiceReturnDate: new Date(),
        usdCurrency: 1.0
    })

    const productsDataGridRef = useRef();
    
    useEffect(()=>{
        calculatePln(data.invoiceReturnDate);
    },[])
    
   const calculatePln= (value)=>{

       GetNbpUsdGetQuery(value,(response)=>{
           console.log(response);
           const data1={...data}
           data1.usdCurrency=response.data.rates[0].mid;
           setData(data1);
       });
       
    };
   
   const calculateProducts=(value) => {

       props.data.listOfProduct.map((op, i) => {
           if(op.priceUsd>0) {
               op.pricePln = Number(((op.priceUsd * value) * 100 / 100).toFixed(2));
           }
       })

       CalculationsProductValues(value);
   }
    
    
    const CalculationsProductValues = (e) => {
        props.data.listOfProduct.map((op, i) => {
            op.fullPrice = Number(((op.pricePln * op.productCount) * 100 / 100).toFixed(2));
        })
        if (productsDataGridRef.current !== undefined)
            dataGridRefresh(productsDataGridRef);
    }

    const onProductOnOrderRowUpdating = (e) => {
        console.log(e)
 
        const productCount=e.newData.productCount ? e.newData.productCount : e.oldData.productCount;
        const pricePln=e.newData.pricePln ? e.newData.pricePln : e.oldData.pricePln;
       
        //e.oldData.fullPrice=Number((pricePln*productCount).toFixed(2));
        
    }

    useEffect(()=>{

        calculateProducts(data.usdCurrency);
        CalculationsProductValues();
        
    },[props.data.listOfProduct])
    

    const handleSubmit = (e) =>{
        
        data.companyId=dataCompany.id;
        props.onSave(data);
        e.preventDefault();
    }
    
    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={props.data.companyView} colCount={2} labelMode='floating' readOnly={true} >
                    <SimpleItem dataField="nip">
                        <Label text='NIP'/>
                    </SimpleItem>
                    <SimpleItem dataField="name">
                        <Label text='Nazwa'/>
                    </SimpleItem>
                    <SimpleItem dataField="address" editorType={dxTextArea}>
                        <Label text='Adres'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactFirstName">
                        <Label text='Kontakt - Imię'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactLastName">
                        <Label text='Kontakt - Nazwisko'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactPhoneNumbers">
                        <Label text='Telefon'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactEmail">
                        <Label text='E-mail'/>
                    </SimpleItem>
                </Form>
                <form action="your-action1" onSubmit={handleSubmit}>
                <Form formData={data} labelMode='floating' colCount={1}
                      showColonAfterLabel={true}
                      showValidationSummary={true}
                      validationGroup="orderData"
                >
                    <GroupItem caption="Ustawienia" colCount={2}>
                        <SimpleItem
                            dataField="invoiceCreatedDate"
                            editorType="dxDateBox"
                            editorOptions={
                                {displayFormat: "dd.MM.yyyy", onValueChanged: (e)=>{calculatePln(e.value)}}
                            }>
                            <Label text='Data utworzenia'/>
                        </SimpleItem>
                        <SimpleItem
                            dataField="invoiceReturnDate"
                            editorType="dxDateBox"
                            editorOptions={
                                {displayFormat: "dd.MM.yyyy"}
                            }>
                            <Label text='Data przyjęcia'/>
                        </SimpleItem>
                        <SimpleItem
                            dataField="usdCurrency"  editorOptions={
                            { onValueChanged: (e)=>{calculateProducts(e.value)}}
                        }>
                            <Label text='Kurs Usd/Pln'/>
                        </SimpleItem>
                    {/*<SimpleItem*/}
                    {/*    dataField="invoicePaymentDate"*/}
                    {/*    editorType="dxDateBox"*/}
                    {/*    editorOptions={*/}
                    {/*        {displayFormat: "dd.MM.yyyy" }*/}
                    {/*    }>*/}
                    {/*    <Label text='Data płatnościś'/>*/}
                    {/*</SimpleItem>*/}
                    <SimpleItem dataField="invoiceNr" isRequired={true}>
                        <Label text='Numer faktury'/>
                    </SimpleItem>
                    <SimpleItem dataField="comment" editorType={dxTextArea}>
                        <Label text='Uwagi'/>
                    </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.listOfProduct}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            columns={ProductsOnOrderODColumnsDef}
                            onRowUpdating={onProductOnOrderRowUpdating}
                            ref={productsDataGridRef}
                        >
                            <Summary>
                                <TotalItem
                                    column="fullPrice"
                                    summaryType="sum"
                                />
                            </Summary>
                            <Editing
                                useIcons={true}
                                mode="row"
                                allowUpdating={true}
                                allowDeleting={true}
                               />
                        </DataGrid>
                    </GroupItem>
                </Form>

                    <Button type={"success"} useSubmitBehavior={true} onClick={props.onClick}
                        validationGroup="orderData"
                >
                    Zapisz
                </Button>
                    <ButtonCancel onClick={()=>props.onClose()} />
                </form>
            </div>
        </React.Fragment>
    )
}