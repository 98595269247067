import React, {useState} from "react";
import {Container, Modal, ModalBody} from "reactstrap";
import {Box, Form} from "devextreme-react";
import {ButtonItem, GroupItem, Label, SimpleItem} from "devextreme-react/form";
import ButtonAdd from "../../Base/Buttons/buttonAdd";
import {Item} from "devextreme-react/box";
import ButtonCancel from "../../Base/Buttons/buttonCancel";
import {Button} from "devextreme-react/button";



export const AddProductToOrderODComponent = (props) => {

    const data= {count:0};

    const AddProduct=(data)=>{
        if(data.count>0) {
            const newData = props.data;
            newData.productCount = data.count;
            newData.pricePln=data.pricePln;
            newData.priceUsd=data.priceUsd;
            props.addProduct(newData);
        }
        
    }

    const handleSubmit = (e) =>{
        AddProduct(data)
        e.preventDefault();
    }

    return (
                <React.Fragment>
                    <Box
                        direction="row"
                        width="150"
                        height="100%"
                    >
                        <Item ratio={1}>
                            <form action="your-action" onSubmit={handleSubmit}>
                            <Form formData={data} colCount={1} labelMode='floating'   validationGroup="productOnOrderData"
                                  showValidationSummary={true}
                            >
                            <GroupItem caption= {"Dodaj produkt "+props.data.idCode}>
                                <SimpleItem 
                                    dataField="count"
                                    editorType="dxNumberBox"
                                    isRequired={true} >
                                    <Label text='Ilość'/>
                                </SimpleItem>
                                <SimpleItem
                                    dataField="pricePln"
                                    editorType="dxNumberBox"
                                    isRequired={true} >
                                    <Label text='Kwota PLN'/>
                                </SimpleItem>
                                <SimpleItem
                                    dataField="priceUsd"
                                    editorType="dxNumberBox"
                                    isRequired={true} >
                                    <Label text='Kwota USD'/>
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                            <Button type={"success"} useSubmitBehavior={true}
                                    validationGroup="productOnOrderData"
                            >
                                Dodaj
                            </Button>
                            <ButtonCancel onClick={()=>props.onClose()} />

                        </form>
                        </Item>
                    </Box>
                </React.Fragment>
    )
}