import authService from "./components/api-authorization/AuthorizeService";
import {useEffect, useState} from "react";
import Axios from "axios";

export const getApiDataAuth = async (url) => {
    
    const token=await authService.getAccessToken();
    return await Axios.get(url, {
        headers: !token ? {} : {Authorization: `Bearer ${token}`},
    });
};

export const useGetApiDataAuth = (url, returnedData) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (reload === true) {
            setReload(false);

            authService.getAccessToken().then((token) => {
                Axios.get(url, {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` },
                })
                    .then((response) => {
                        setLoading(false);

                        returnedData(response);
                    })
                    .catch(function(error) {
                        // handle error
                        console.log(error);
                        setLoading(false);
                        setError(true);
                    });
            });
        }
    }, [reload]);

    return [loading, error, setReload];
};

export const AxiosAuthGet = (url, onResponse, onError) => {
    authService.getAccessToken().then((token) => {
        Axios.get(url,  {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                onResponse(response);
            })
            .catch(function(error) {
                // handle error
                onError(error);
            });
    });
};

export const AxiosAuthPost = (url, data, onResponse, onError) => {
    authService.getAccessToken().then((token) => {
        Axios.post(url, data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                onResponse(response);
            })
            .catch(function(error) {
                onError(error);
            });
    });
};

export const AxiosAuthPostBlob = (url, data, onResponse, onError) => {
    authService.getAccessToken().then((token) => {
        Axios.post(url, data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }, responseType: "blob"
        })
            .then((response) => {
                onResponse(response);
            })
            .catch(function(error) {
                onError(error);
            });
    });
};

export const AxiosAuthDelete = (url, onResponse, onError) => {
    authService.getAccessToken().then((token) => {
        Axios.delete(url, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                onResponse(response);
            })
            .catch(function(error) {
                // handle error
                onError(error);
            });
    });
};

export const useEditFormDialogHooks = (startedData) => {
    const [editedData, setEditedData] = useState(startedData);

    function handleSubmit(event) {
        event.preventDefault();
    }

    const onDialogInputChange = (event) => {
         console.log(event,event.target);
        let target = event.target;

         let value = target.type === "checkbox" ? target.checked : target.value;
            const name = target.props ? target.props.name : target.name;

            const edited = Object.assign({}, editedData);


        if(target.type==='number')
            {
                edited[name] = Number(value.replace(',','.'));  
            }
            else 
            {
                edited[name] = value;
            }
            // console.log(edited);
            setEditedData(edited);
        
    };

    return [editedData, handleSubmit, onDialogInputChange];
};