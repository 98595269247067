import React from "react";

import {useEditFormDialogHooks} from "../../Helper";
//import {Modal,ModalHeader,ModalBody,Container,Row,Col,FormGroup,Label,Input,ModalFooter} from "reactstrap";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter
} from "../../appp/components";
import {Button} from "reactstrap";




const EditProduct = (props) => {


    const [
        dataInEdit,
        handleSubmit,
        onDialogInputChange,
    ] = useEditFormDialogHooks(props.data);


    const save = (event) => {
        event.preventDefault();

        props.save(dataInEdit);
    };




    //  useEffect(()=>{
    //     console.log(props);
    //      if(props.data) {
    //          console.log(props.data,"dddd",data);
    //
    //         const dat = Object.assign({}, props.data);
    //         setData(dat);
    //     }
    // },[props.data])

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Produkt
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>


                        <FormGroup row>
                            <Label for="idCode" sm={3}>
                                Kod id
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="idCode"
                                    id="idCode"
                                    placeholder="Kod Id"
                                    value={dataInEdit.idCode}
                                    onChange={onDialogInputChange}
                                    required={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={3}>
                                Nazwa
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="name"
                                    id="name"
                                    placeholder=""
                                    value={dataInEdit.name}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="nameEng" sm={3}>
                                Nazwa Eng
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="nameEng"
                                    id="nameEng"
                                    placeholder=""
                                    value={dataInEdit.nameEng}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={3}>
                                Opis
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="description"
                                    id="description"
                                    placeholder=""
                                    value={dataInEdit.description}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="descriptionEng" sm={3}>
                                Opis Eng.
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="descriptionEng"
                                    id="descriptionEng"
                                    placeholder=""
                                    value={dataInEdit.descriptionEng}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="brand" sm={3}>
                                Marka
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="brand"
                                    id="brand"
                                    placeholder=""
                                    value={dataInEdit.brand}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="type" sm={3}>
                                Typ
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="type"
                                    id="type"
                                    placeholder=""
                                    value={dataInEdit.type}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="subtype" sm={3}>
                                Podtyp
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="subtype"
                                    id="subtype"
                                    placeholder=""
                                    value={dataInEdit.subtype}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="comment" sm={3}>
                                Uwagi
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="comment"
                                    id="comment"
                                    placeholder=""
                                    value={dataInEdit.comment}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                            <FormGroup row>
                                <Label for="price" sm={3}>
                                    Cena
                                </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="price"
                                    id="price"
                                    placeholder=""
                                    value={dataInEdit.price}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="priceForProfessionals" sm={3}>
                                Cena dla profesionalistów
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="priceForProfessionals"
                                    id="priceForProfessionals"
                                    placeholder=""
                                    value={dataInEdit.priceForProfessionals}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="weight" sm={3}>
                                Waga (g)
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="weight"
                                    id="weight"
                                    placeholder=""
                                    value={dataInEdit.weight}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={3}>
                                Czy usługa
                            </Label>
                            <Col sm={9}>
                                
                                <Input
                                    type="checkbox"
                                    name="isService"
                                    id="isService"
                                    placeholder=""
                                    value={dataInEdit.isService}
                                    checked={dataInEdit.isService}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={save}>
                    Zapisz
                </Button>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Anuluj
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditProduct;