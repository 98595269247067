
export const OrderODDetailProductsColumnDef = [
    {
        dataField: "productIdCode",
        caption: "Kod ID",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "productName",
        caption: "Nazwa",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "productCount",
        caption: "Ilość",
        dataType: "string",
        allowEditing: true
    },
    {
        dataField: "price",
        caption: "Kwota",
        alignment: "right",
        allowEditing: false
    },
    {
        dataField: "set",
        caption: "Przysisane",
        alignment: "right",
        allowEditing: false
    },
]
    
