import _ from "lodash";
import faker from "faker/locale/en_US";
import {
    Card, CardBody, CardHeader, Col, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "../../appp/components";
import {randomAvatar} from "../../appp/utilities";
import React, {useState} from "react";
import SelectProductRow from "./selectProductRow";
import {Button} from "reactstrap";
import {useGetApiDataAuth} from "../../Helper";
import DatePicker from "react-datepicker";
import {ButtonInput} from "../../appp/routes/Forms/DatePicker/components";




const PackingSelectedProduct = (props)=>
{

    const [count, setCount] = useState(0);

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
               Pobierz produkt
            </ModalHeader>
            <ModalBody>
                <React.Fragment>

                    <Card >
                        <CardHeader tag="h6" className="bg-info text-white">
                            {props.data.productIdCode} {props.data.name}
                        </CardHeader>
                        <CardBody>
                            Miejsce: {props.data.warehouseIdCode} Ilość towaru: {props.data.count}
                            <FormGroup row>
                                <Label for="count" sm={3}>
                                    Ilość pobranych
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="number"
                                        name="count"
                                        id="count"
                                        placeholder=""
                                        value={count}
                                        onChange={ (event)=>
                                            {
                                                if(event.target.value>props.data.count)
                                                {
                                                    setCount(props.data.count)
                                                }
                                                else 
                                                {
                                                    setCount(event.target.value)
                                                }
                                               
                                            }
                                        }
                                        max={props.data.count}

                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Button onClick={
                                    ()=> {
                                       props.save(props.data,count)
                                    }
                                }>Dodaj</Button>
                            </FormGroup>
                            
                        </CardBody>

                    </Card>

                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Zamknij
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default PackingSelectedProduct
