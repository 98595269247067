import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Summary, TotalItem} from "devextreme-react/data-grid";
import React from "react";
import {OrderDetailProductsColumnsDef} from "../../../ColumnsDef/Orders/orderDetailProductsColumnsDef";
import {getFullWeight} from "../../../ColumnsDef/Products/productsOnOrderColumnsDef";
import {Button} from "devextreme-react/button";
import ConstantsMessages from "../../../Consts/messages";
import {CancelOrderQuery} from "../../../Query/Orders/cancelOrderQuery";
import {dataGridRefresh} from "../../Base/dxGridHellper";


export default function OrderDetailEditableComponent (props) {
    
    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie {props.data.orderNr}</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={props.data} colCount={2} labelMode='floating' readOnly={true} >
                    <SimpleItem dataField="companyName">
                        <Label text='Nazwa'/>
                    </SimpleItem>
                    <SimpleItem dataField="companyAddress" editorType={dxTextArea}>
                        <Label text='Adres'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactName">
                        <Label text='Kontakt'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactPhone">
                        <Label text='Telefon'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactEmail">
                        <Label text='E-mail'/>
                    </SimpleItem>
                    <SimpleItem dataField="deliveryAddress" editorType={dxTextArea}  editorOptions={{ autoResizeEnabled : true}}>
                        <Label text='Adres dostawy'/>
                    </SimpleItem>
                    <SimpleItem dataField="invoiceNr">
                        <Label text='Numer faktury'/>
                    </SimpleItem>
                </Form>
                <Form formData={props.data} labelMode='floating' readOnly={true}>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.productOnOrderDetail}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={false}
                            columns={OrderDetailProductsColumnsDef}
                        >
                            <Summary>
                                <TotalItem
                                    column="fullPrice"
                                    summaryType="sum"
                                />
                            </Summary>
                        </DataGrid>
                    </GroupItem>
                </Form>
                <Form formData={props.data} labelMode='floating' readOnly={false}>
                <SimpleItem dataField="comment" editorType={dxTextArea}>
                    <Label text='Uwagi'/>
                </SimpleItem>
                <SimpleItem dataField="packageNr">
                    <Label text='Numer przesyłki'/>
                </SimpleItem>
                </Form>
                <div>
                    <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.getPackagePdf(props.data)}}>
                        Pobierz list przewozowy
                    </Button>
                </div>
                <div>
                    <Button type={"success"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.acceptSelectedOrder(props.data)}}>
                        Akceptuj
                    </Button>
                </div>
                <div>
                    <Button width={"100%"} stylingMode={"contained"} onClick={()=>{props.onCancel(props.data)}}>
                        Zwrot / Anulowanie
                    </Button>
                </div>
            </div>
            
        </React.Fragment>
    )
}