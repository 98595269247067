import {AxiosAuthGet} from "../../Helper";
import {ShowErrorNotification} from "../../notifications";
import ConstantsTitles from "../../Consts/titles";

import {LogError, LogInfo} from "../../logs";

const GET_COMPANY_DETAILS_URL = 'company/GetCompany/'

const onError=(error)=>{
    LogError('GetCompanyDetailsQuery',error);
    ShowErrorNotification(ConstantsTitles.COMPANY_TITLE, error.message);
}

export const GetCompanyDetailsQuery = (companyId, onResponse) => {
    LogInfo('GetCompanyDetailsQuery',companyId);
    
    const newUrl=GET_COMPANY_DETAILS_URL+companyId;
    
    AxiosAuthGet(newUrl,onResponse,onError);
       
}