import React, {useState} from "react";
import {ModalFooter} from "../../../appp/components";
import {Container, Modal, ModalBody} from "reactstrap";
import {Form} from "devextreme-react";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import { Button } from 'devextreme-react/button';
import ButtonSave from "../../Base/Buttons/buttonSave";
import ButtonCancel from "../../Base/Buttons/buttonCancel";


export const ChangeUserPasswordComponent = (props) => {

    const [passwordData, setPasswordData] = useState(props.data);

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
        >
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <Form formData={passwordData} colCount={1} labelMode='floating'>
                            <GroupItem caption="Ustaw hasło">
                                <SimpleItem dataField="oldPassword" visible={!passwordData.adminChange}>
                                    <Label text='Stare hasło'/>
                                </SimpleItem> 
                                <SimpleItem dataField="newPassword" isRequired={true}>
                                    <Label text='Nowe hasło'/>
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <ButtonSave onClick={() => props.save(passwordData)}/>
                <ButtonCancel onClick={() => props.cancel()}/>
            </ModalFooter>
        </Modal>
    )
}