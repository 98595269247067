import {Button} from "devextreme-react/button";
import React from "react";

export default function ButtonSave (props) {

    return (
        <Button type={"success"} onClick={props.onClick}>
            Zapisz
        </Button>
    )
}

