import React, {useRef, useState} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail,
    Selection,
    Toolbar,
    Item,
    ColumnChooser,
    Summary,
    TotalItem,
    GroupItem,
    Grouping,
    Export,
    HeaderFilter,
    FilterPanel
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {SellingReportDxStore} from "../../Query/Reports/sellingReportDxStore";
import {SellingReportColumnsDef} from "../../ColumnsDef/Reports/sellingReportColumnDef";
import {Button} from "devextreme-react/button";
import {PickupReportDxStore} from "../../Query/Reports/pickupReportDxStore";
import {PickupReportColumnsDef} from "../../ColumnsDef/Reports/pickupReportColumnDef";
import {allowedPageSizesDefaultOption, defaultPageSizeDefaultOption} from "../../Consts/defaultOptions";
import {PickupReportsAllDxStore} from "../../Query/Reports/pickupReportsAllDxStore";


export default function PickupReportComponent (props) {

    const [isExpanded,setIsExpanded] = useState(false)

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            <DataGrid
                keyExpr="id"
                dataSource={PickupReportsAllDxStore}
                showBorders={true}
                remoteOperations={true}
                columnAutoWidth={true}
                columns={PickupReportColumnsDef}
            >
                <Export enabled={true} allowExportSelectedData={false} />
                <Grouping autoExpandAll={isExpanded} />
                <Selection mode="single" />
                <GroupPanel visible={true} />
                <SearchPanel visible={true}/>
                <ColumnChooser enabled={true}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true} />
                <FilterPanel visible={true} />
                <Paging defaultPageSize={defaultPageSizeDefaultOption} />
                <Pager
                    allowedPageSizes={allowedPageSizesDefaultOption}
                    showPageSizeSelector={true}
                />
                <Toolbar>
                    <Item location="before">
                        <Button
                            icon='expand'
                            onClick={()=>setIsExpanded(!isExpanded)} />
                    </Item>
                    <Item location="before"  name="exportButton"/>
                    <Item location="before"  name="searchPanel"/>
                    <Item location="before"  name="groupPanel"/>


                </Toolbar>
                <Summary>
                    <GroupItem
                        column="count"
                        summaryType="sum"
                        displayFormat="Ilość: {0}"
                        showInGroupFooter={false}
                        alignByColumn={true}
                    />

                </Summary>

            </DataGrid>
        </div>
    )
}