
import React from "react";
import {OrderStatusEnum} from "../../Enums/orderStatusEnum";
import {VatEnum} from "../../Enums/vatEnum";

export const getFullPrice=(rowData)=>{
    return (rowData.newPrice*rowData.productCount).toFixed(2);
}

export const getFullWeight=(rowData)=>{
    return ((rowData.weight*rowData.productCount)/1000).toFixed(2);
}

export const getNettoPrice=(rowData)=>{
    
    return (rowData.newPrice / (1 + rowData.vat / 100)).toFixed(2);
}

export const ProductsOnOrderColumnsDef = [
    {
        dataField: "idCode",
        caption: "Kod Id",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "name",
        caption: "Nazwa",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "priceNetto",
        caption: "kwota netto",
        dataType: "number",
        allowEditing: false,
        calculateCellValue: getNettoPrice
    },
    {
        dataField: "vat",
        caption: "VAT",
        dataType: "number",
        allowEditing: true,
        lookup: {
            dataSource: VatEnum,
            valueExpr: "id",
            displayExpr: "desc"
        }
    },
    {
        dataField: "newPrice",
        caption: "Kwota (szt)",
        dataType: "number",
        allowEditing: true
    },
    {
        dataField: "productCount",
        caption: "ilość",
        dataType: "number",
        allowEditing: false
    },
    {
        dataField: "weight",
        caption: "Waga",
        dataType: "string",
        allowEditing: false,
        calculateCellValue: getFullWeight
    },
    {
        dataField: "fullPrice",
        caption: "Pełna kwota",
        dataType: "number",
        allowEditing: false,
        calculateCellValue: getFullPrice
    },
]
    
