import React, {useRef, useState} from "react";
import {Box, DataGrid, Form} from "devextreme-react";
import {Item} from "devextreme-react/box";
import {Button} from "devextreme-react/button";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Editing, Summary, TotalItem} from "devextreme-react/data-grid";
import {OrderReturnProductsColumnsDef} from "../../../ColumnsDef/Orders/orderReturnProductsColumnsDef";

export const OrderToReturnComponent = (props) => {

    const productsDataGridRef = useRef();
    const [orderData] = useState(props.data);

    const onProductOnOrderRowUpdating = (e) => {
        
        const productCount=e.newData.productCount ? e.newData.productCount : e.oldData.productCount;

        e.oldData.fullPrice=Number((e.oldData.fullPrice*productCount).toFixed(2));
       
    }

    return (
        <React.Fragment>
            <Box
                direction="row"
                width="600"
                height="100%"
            >
                <Item ratio={1}>
                    <React.Fragment>
                        <h3 className={'content-block'}>Zamówienie {orderData.orderNr}</h3>
                        <div className={'content-block dx-card responsive-paddings'}>
                            <Form formData={orderData} colCount={2} labelMode='floating' readOnly={true} >
                                <SimpleItem dataField="companyName">
                                    <Label text='Nazwa'/>
                                </SimpleItem>
                                <SimpleItem dataField="companyAddress" editorType={dxTextArea}>
                                    <Label text='Adres'/>
                                </SimpleItem>
                                <SimpleItem dataField="contactName">
                                    <Label text='Kontakt'/>
                                </SimpleItem>
                                <SimpleItem dataField="contactPhone">
                                    <Label text='Telefon'/>
                                </SimpleItem>
                                <SimpleItem dataField="contactEmail">
                                    <Label text='E-mail'/>
                                </SimpleItem>
                                <SimpleItem dataField="deliveryAddress" editorType={dxTextArea}  editorOptions={{ autoResizeEnabled : true}}>
                                    <Label text='Adres dostawy'/>
                                </SimpleItem>
                                <SimpleItem dataField="packageNr">
                                    <Label text='Numer przesyłki'/>
                                </SimpleItem>
                                <SimpleItem dataField="invoiceNr">
                                    <Label text='Numer faktury'/>
                                </SimpleItem>
                            </Form>
                            <Form labelMode='floating' readOnly={true}>
                                <SimpleItem dataField="comment" editorType={dxTextArea}>
                                    <Label text='Uwagi'/>
                                </SimpleItem>
                                <GroupItem caption="Produkty">
                                    <DataGrid
                                        dataSource={orderData.productOnOrderDetail}
                                        allowColumnReordering={false}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        columnAutoWidth={false}
                                        columns={OrderReturnProductsColumnsDef}
                                        onRowUpdating={onProductOnOrderRowUpdating}
                                        ref={productsDataGridRef}
                                    >
                                        <Summary>
                                            <TotalItem
                                                column="fullPrice"
                                                summaryType="sum"
                                            />
                                        </Summary>
                                        <Editing
                                            useIcons={true}
                                            mode="row"
                                            allowUpdating={true}
                                            allowDeleting={true}
                                        />
                                    </DataGrid>
                                </GroupItem>
                            </Form>
                        </div>
                    </React.Fragment>
                    <div className={'content-block dx-card responsive-paddings'}>
                        <div>
                           
                                <Button type={"success"} width={"100%"} stylingMode={"contained"} onClick={() => {
                                    props.onReturn(props.data)
                                }}>
                                    Zwrot
                                </Button>
                            
                        </div>
                        <div>

                            <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={() => {
                                props.onCancel()
                            }}>
                                Anuluj
                            </Button>

                        </div>
                    </div>
                </Item>
            </Box>
        </React.Fragment>
    )
}