import React, {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";
import CreateOrderComponent from "../NewOrder/createOrderComponent";


function NewOrderClient (props) {

    const [result, setResult] = useState(null);

    const receiveData = (response) => {
        console.log(response.data);
        setResult(response.data);
    };
    
    const [loading, error, setReload] = useGetApiDataAuth(
        "company/GetUserCompany",
        receiveData
    );
    
    return (
        <React.Fragment>
            {result!==null ?
                <CreateOrderComponent company={result} isFromCompany={true} /> : <div> Brak aktywnego klienta</div>}
        </React.Fragment>
    )
}

export default NewOrderClient;
