import React from "react";

const ProductOnWarehouseRow = (props)=>
{

    return (
        <React.Fragment>
                <td className="align-middle">
                    <div>
                        {props.data.warehouseIdCode}
                    </div>
                </td>
            <td className="align-middle">
                <div>
                    {props.data.rack}
                </div>
            </td>
            <td className="align-middle">
                <div>
                    {props.data.shelf}
                </div>
            </td>
            <td className="align-middle">
                <div>
                    {props.data.place}
                </div>
            </td>
                <td className="align-middle">
                    
                    {props.data.count > 25 ?
                        <span className="text-info">
                            {props.data.count}
                        </span> :
                        <span className="text-danger">
                            {props.data.count}
                        </span>
                    }
                </td>
            <td className="align-middle">
                <div>
                    {props.data.expirationDate}
                </div>
            </td>
            
        </React.Fragment>
    )

}

export default ProductOnWarehouseRow;