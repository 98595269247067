import React, {useRef, useState} from "react";
import {ModalFooter, ModalHeader} from "../../../appp/components";
import {Container, Modal, ModalBody} from "reactstrap";
import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import {Editing} from "devextreme-react/data-grid";
import ButtonCancel from "../../Base/Buttons/buttonCancel";
import ButtonSave from "../../Base/Buttons/buttonSave";
import {AddressColumnsDef} from "../../../ColumnsDef/Company/addressColumnsDef";
import {formValidate} from "../../Base/dxFormHelper";
import {dataGridEditSave} from "../../Base/dxGridHellper";
import {DiscountOfTypeOnProductColumnsDef} from "../../../ColumnsDef/Company/discountOfTypeOnProductColumnsDef";


export const EditCompanyComponent = (props) => {

    const [companyData] = useState({...props.data});
    const companiesDataRef = useRef();
    const addressDataRef = useRef();


    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
        >
            <ModalHeader tag="h6">
                Klient
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <Form 
                            formData={companyData} 
                            colCount={1} 
                            labelMode='floating' 
                            ref={companiesDataRef}
                            showValidationSummary={true}
                        >
                            <GroupItem caption="Dane Firmy">
                                <SimpleItem dataField="nip">
                                    <Label text='Nip'/>
                                </SimpleItem>
                                <SimpleItem dataField="name">
                                    <Label text='Nazwa'/>
                                    <RequiredRule
                                        message="Nazwa firmy jest wymagana"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="street">
                                    <Label text='Ulica'/>
                                    <RequiredRule
                                        message="Ulica jest wymagana"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="number">
                                    <Label text='Numer domu'/>
                                    <RequiredRule
                                        message="Numer domu jest wymagany"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="localNumber">
                                    <Label text='Numer lokalu'/>
                                </SimpleItem>
                                <SimpleItem dataField="zip">
                                    <Label text='Kod pocztowy'/>
                                    <RequiredRule
                                        message="Kod pocztowy jest wymagany"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="city">
                                    <Label text='Miasto'/>
                                    <RequiredRule
                                        message="Miasto jest wymagane"
                                    />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption="Kontakt">
                                <SimpleItem dataField="contactFirstName">
                                    <Label text='Imię'/>
                                    <RequiredRule
                                        message="Imię jest wymagane"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="contactLastName">
                                    <Label text='Nazwisko'/>
                                    <RequiredRule
                                        message="Nazwisko jest wymagane"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="contactPhoneNumbers">
                                    <Label text='Telefon'/>
                                    <RequiredRule
                                        message="Telefon jest wymagany"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="contactEmail">
                                    <Label text='Email'/>
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption="Ustawienia">
                                <SimpleItem dataField="discountPercent">
                                    <Label text='Wysokość rabatu (%)'/>
                                </SimpleItem>
                                <SimpleItem dataField="isRepresentative">
                                    <Label text='Czy jest przedstawicielem'/>
                                </SimpleItem>
                                <SimpleItem 
                                    dataField="representativeId"
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: props.companyRepresentative, searchEnabled: true, displayExpr:"name",  valueExpr:"id"}}
                                >
                                    <Label text='Przedstawiciel'/>
                                </SimpleItem>
                              
                                <SimpleItem dataField="marketingContact">
                                    <Label text='Zgody marketingowe'/>
                                </SimpleItem>
                                <SimpleItem dataField="isProfessional">
                                    <Label text='Czy jest profesionalistą'/>
                                </SimpleItem>
                                <SimpleItem dataField="isGroupInvoice">
                                    <Label text='Czy faktura grupowa'/>
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption="Szkolenie">
                                <SimpleItem dataField="isAfterTraining">
                                    <Label text='Czy jest po szkoleniu'/>
                                </SimpleItem>
                                <SimpleItem dataField="trainedName">
                                    <Label text='Imię nazwisko osoby szkolonej'/>
                                </SimpleItem>
                                <SimpleItem dataField="trainerName">
                                    <Label text='Imię nazwisko osoby szkolącej'/>
                                </SimpleItem>
                                <SimpleItem dataField="certNumber">
                                    <Label text='Numer certyfikatu'/>
                                </SimpleItem>
                                <SimpleItem 
                                    dataField="trainingDate"
                                    editorType="dxDateBox"
                                    editorOptions={
                                        {displayFormat: "dd.MM.yyyy", label: 'Data szkolenia' }
                                    }>
                                    <Label text='Data szkolenia'/>
                                </SimpleItem>
                            </GroupItem>
                           
                            <GroupItem caption="Adres dostawy">
                            <SimpleItem dataField="companyId">
                                <DataGrid
                                    dataSource={companyData.deliveryAddresses}
                                    allowColumnReordering={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    columns={AddressColumnsDef}
                                    ref={addressDataRef}
                                >
                                    <Editing
                                        useIcons={true}
                                        mode="row"
                                        allowUpdating={true}
                                        allowDeleting={true}
                                        allowAdding={true} />
                                </DataGrid>
                            </SimpleItem>
                            </GroupItem>
                            <GroupItem caption="Zniżki dla typu">
                                <SimpleItem >
                                    <DataGrid
                                        dataSource={companyData.discountOfTypeOnProduct}
                                        allowColumnReordering={true}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        columns={DiscountOfTypeOnProductColumnsDef}
                                    >
                                        <Editing
                                            useIcons={true}
                                            mode="row"
                                            allowUpdating={true}
                                            allowDeleting={true}
                                            allowAdding={true} />
                                    </DataGrid>
                                </SimpleItem>
                            </GroupItem>
                            <SimpleItem dataField="comment">
                                <Label text='Uwagi'/>
                            </SimpleItem>
                        </Form>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <ButtonSave onClick={() => {
                    const res=formValidate(companiesDataRef);
                    if (res.isValid === true) {
                        dataGridEditSave(addressDataRef).then(()=>{
                            props.save(companyData)
                        })
                    }
                }
                }
                />
                <ButtonCancel onClick={() => props.cancel()}/>
            </ModalFooter>
        </Modal>
    )
}