import React, {useState} from "react";
import {ModalFooter, ModalHeader} from "../../../appp/components";
import {Container, Modal, ModalBody} from "reactstrap";
import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, SimpleItem} from "devextreme-react/form";
import {Editing} from "devextreme-react/data-grid";
import ButtonSave from "../../Base/Buttons/buttonSave";
import ButtonCancel from "../../Base/Buttons/buttonCancel";


export const EditUserComponent = (props) => {

    const [userData, setUserData] = useState(props.data);

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
        >
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <Form formData={userData} colCount={1} labelMode='floating'>
                            <GroupItem caption="Dane użytkownika">
                                <SimpleItem dataField="userName">
                                    <Label text='Nazwa'/>
                                </SimpleItem>
                                <SimpleItem dataField="phoneNumber">
                                    <Label text='Telefon'/>
                                </SimpleItem>
                                <SimpleItem dataField="email">
                                    <Label text='E-mail'/>
                                </SimpleItem>
                                <SimpleItem dataField="comment">
                                    <Label text='Uwagi'/>
                                </SimpleItem>
                                <SimpleItem
                                    dataField="roleName"
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: props.Roles, searchEnabled: true}}
                                >
                                    <Label text='Rola'/>
                                </SimpleItem>
                                <SimpleItem
                                    dataField="companyId"
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: props.Company, searchEnabled: true, displayExpr:"name",  valueExpr:"id"}}
                                >
                                    <Label text='Firma'/>
                                </SimpleItem>
                                <SimpleItem dataField="password" visible={props.isNew}>
                                    <Label text='Hasło'/> 
                                </SimpleItem> 
                            </GroupItem>
                        </Form>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <ButtonSave onClick={() => props.save(userData)}/>
                <ButtonCancel onClick={() => props.cancel()}/>
            </ModalFooter>
        </Modal>
    )
}