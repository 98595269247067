import React, {useEffect, useState} from "react";
import QrReader from 'react-qr-reader'
import {AxiosAuthPost, useGetApiDataAuth} from "../../Helper";
import PickProduct from "./pickProduct";
import PickupWarehouse from "./pickupWarehouse";
import {Button} from "reactstrap";
import { store } from 'react-notifications-component';


const PickupOrder=(props)=>{
    
    const[scanResult,setScanResult]=useState("");

    const [result, setResult] = useState([]);
    const [dataInEdit, setDataInEdit] = useState(undefined);
    const [showAddToWarehouse,setShowAddToWarehouse]=useState(undefined);
    const [showAddToWarehouse2,setShowAddToWarehouse2]=useState(undefined);

    const [dataProductId, setDataProductId] = useState(undefined);
    const [dataCount, setDataCount] = useState(0);
    const [dataExDate, setDataExDate] = useState(undefined);

    const [userName, setUserName] = useState(null);

    const [scanOn,setScanOn]=useState(true);
    const [status,setStatus]=useState(0);

    const [result2, setResult2] = useState([]);

    const [selectedProduct,setSelectedProducte]=useState(undefined);



    const receiveData2 = (response) => {
        setResult2(response.data);
    };

    const [loading2, error2, setReload2] = useGetApiDataAuth(
        "warehouseplace/Get",
        receiveData2
    );

    //
    // useEffect(async ()=>{
    //     const load= async ()=> {
    //         const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    //
    //         setUserName(user && user.name);
    //     }
    //     load();
    //
    // },[])

    const receiveData = (response) => {
        console.log(response.data);
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "product/Get",
        receiveData
    );

    const clearData=()=>{
        setDataProductId(undefined);
        setDataCount(0);
        setDataExDate(undefined);
        setShowAddToWarehouse(undefined);
        setDataInEdit(undefined);
        setScanResult("");
        setReload(true);
        setScanOn(true);
        setStatus(0);
        setShowAddToWarehouse2(undefined);

    }
    
    const pickupProduct=()=>
    {
        console.log(dataCount);
       // console.log(dataExDate);
        const val={
            productId:0,
            warehouseId: 0,
            count:  0,
            expirationDate: new Date(),
            userName: userName
        };
        console.log(val);

        setShowAddToWarehouse(val);
    }
    
    useEffect(()=>{
        if(status===0) {
            const dat = scanResult.split(";");
            console.log(dat);
            const val = {
                productId: 0,
                warehouseId: 0,
                count: 0,
                expirationDate: new Date(),
                userName: userName
            };

            if (dat.length > 1) {
                val.count = Number(dat[1]);
            }
            if (dat.length > 2) {
                val.expirationDate = Date.parse(dat[2]);
                //  setDataExDate(dat[2]);
            }

            if (dat.length > 0) {
                const res = result.filter((e) => e.idCode === dat[0]);
                if (res.length > 0) {
                    console.log(val);
                    setShowAddToWarehouse(val);
                    setResult(res);
                }
            }
        }
        else 
        {
            const dat2 = scanResult;
            console.log(dat2);
            const res = result2.filter((e) => e.idCode === dat2);
            if (res.length > 0) {
                setShowAddToWarehouse2(showAddToWarehouse);
                setResult2(res);
            }
        }
    },[scanResult])
    
    const onScan=(data)=>{
        if(data)
        {
            setScanOn(false);
          
            setScanResult(data);
        }
    }

    const onScan2=(data)=>{
        if(data)
        {
            setScanOn(false);

            setScanResult(data);
        }
    }
    
    const handleError = err => {
        console.error(err)
    }

    const addToWarehouse=(data) => {

        console.log(data);
        AxiosAuthPost(
            "product/AddToWarehouse",
            data,
            (response) => {
                
                setReload(true);
                store.addNotification({
                    title: "Magazyn",
                    message: "Produkt " +data.productCode+ " dodany do magazynu",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                
              

             

            },(error) => {
                // handle error
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
                console.log(error);
                
            });

        clearData();
    }
    if(status===0) {
        return (

            <div>
                <p>  <Button onClick={pickupProduct}>
                    Wybierz produkt
                </Button> </p>
                {scanOn === true ?
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={onScan}
                        style={{width: '100%'}}
                    /> : null}

                <p>{scanResult}</p>
                

                {/* <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className={` text-decoration-none  `}>
                        <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={pickupProduct}>
                            <i className="fa fa-fw fa-trash mr-2"></i>
                            Pobierz product
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>*/}

                {showAddToWarehouse !== undefined ? <PickProduct
                    save={
                        (data) => {
                            setShowAddToWarehouse(data);
                            setStatus(1);
                            setScanOn(true);
                        }
                    }
                    data={
                        showAddToWarehouse
                    }
                    cancel={() => {
                        clearData();
                    }}
                    result={result}

                /> : null}
            </div>

        )
    }
    else {
        return (
            <div>
               
                <div>
                    <p>  <Button onClick={ ()=> {
                    clearData();
                }
                }>
                    Cofnij do wyboru produktu
                    </Button> </p>
                    <p>
                    <Button onClick={ ()=> {
                    console.log(showAddToWarehouse);
                    setScanOn(false);
                    setShowAddToWarehouse2(showAddToWarehouse);
                }
                }>
                    Wybierz miejsce dla  {showAddToWarehouse.productCode} ({showAddToWarehouse.count})
                </Button></p>
                </div>
                {scanOn === true ?
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={onScan2}
                        style={{width: '100%'}}
                    /> : null}

                <p>{scanResult}</p>
                {/*  <UncontrolledButtonDropdown>
                    <DropdownToggle color="link" className={` text-decoration-none  `}>
                        <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={ ()=> {
                            console.log(showAddToWarehouse);
                            setScanOn(false);
                            setShowAddToWarehouse2(showAddToWarehouse);
                        }
                        }>
                            <i className="fa fa-fw fa-trash mr-2"></i>
                            Wybierz miejsce
                        </DropdownItem>
                        <DropdownItem onClick={ ()=> {
                            clearData();
                        }
                        }>
                            <i className="fa fa-fw fa-trash mr-2"></i>
                            Wruć do wyboru produktu
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown> */}

                {showAddToWarehouse2 !== undefined ? <PickupWarehouse
                    save={
                        (data) => {
                            //setShowAddToWarehouse(data);
                            addToWarehouse(data);
                        }
                    }
                    data={
                        showAddToWarehouse2
                    }
                    cancel={() => {
                       setScanOn(true);
                        setShowAddToWarehouse2(undefined);
                    }}
                    result={result2}

                /> : null}
            </div>
        )
    }
}
export default PickupOrder