import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithResponseErrorQuery} from "../defaultPostWithResponseErrorQuery";

const DELETE_USER_URL = 'user/DeleteUser'

export const DeleteUserQuery = (data, onResponse) => {
    return DefaultPostWithResponseErrorQuery(
        DELETE_USER_URL,
        ConstantsTitles.USERS_TITLE,
        ConstantsMessages.USER_DELETED,
        DeleteUserQuery.name,
        data,
        onResponse
    )
}