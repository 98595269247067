import React, {useRef, useState} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail, Selection, Toolbar, Item
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {CancelOrderQuery} from "../../../Query/Orders/cancelOrderQuery";
import {AddButtonsOnEnd, dataGridRefresh, useDxDataGridExpandedLoadQuery} from "../../Base/dxGridHellper";
import ConstantsMessages from "../../../Consts/messages";
import {OrderToCompletingDxStore} from "../../../Query/Orders/orderToCompletingDxStore";
import OrderActionExpandedComponent from "../DetailsComponents/orderActionExpandedComponent";
import {GetDataToOrderCompletingQuery} from "../../../Query/Orders/getDataToOrderCompleting";
import {OrdersToCompletingColumnDef} from "../../../ColumnsDef/Orders/ordersToCompletingColumnDef";
import OrderCompletingComponent from "../OperationsComponents/orderCompletingComponent";



export default function OrdersToCompletingComponent (props) {

    
    const ordersDataGridRef = useRef();
    const [ordersColumns]=useState(
        OrdersToCompletingColumnDef
    )

   
    
    const [selectedOrder, setSelectedOrder] = useState(undefined);

    const cancelSelectedOrder=(data)=>{

        if (window.confirm(ConstantsMessages.ORDER_CANCEL_ORDER_CONFIRM)) {
            CancelOrderQuery(data,()=>{
                dataGridRefresh(ordersDataGridRef);
            });
        }
    }

    const actions={
        actionName: "Kompletuj",
        action: (selectedData)=>{
            setSelectedOrder(selectedData)},
        actionName2: "Anuluj zamówienie",
        action2: cancelSelectedOrder
    }

    const [contentReady, selectionChanged, renderDetailSection,reloadData]=useDxDataGridExpandedLoadQuery(GetDataToOrderCompletingQuery,OrderActionExpandedComponent,actions);

    return (
        <div className={'content-block dx-card responsive-paddings'}>
            {!selectedOrder ?
            <DataGrid
                keyExpr="id"
                dataSource={OrderToCompletingDxStore}
                showBorders={true}
                remoteOperations={true}
                columnAutoWidth={true}
                ref={ordersDataGridRef}
                columns={ordersColumns}
                onSelectionChanged={selectionChanged}
                onContentReady={contentReady}
            >
                <Selection mode="single" />
                <SearchPanel visible={true}/>
                <Paging defaultPageSize={12} />
                <Pager
                    showPageSizeSelector={true}
                />
                <MasterDetail enabled={false} render={renderDetailSection}/>
                <MasterDetail enabled={false} render={renderDetailSection} />
                <Toolbar>
                    <Item location="before"  name="searchPanel"/>
                </Toolbar>

            </DataGrid> : <div/>}
            {selectedOrder!==undefined ?
                <OrderCompletingComponent selectedOrder={selectedOrder} reloadData={reloadData} onDataLoaded={contentReady} cancel={()=>setSelectedOrder(undefined)} />
                : <div/>}
        </div>
    )
}