export const DEFAULT_PASSWORD="Test123$";
const DEFAULT_ROLE="Pracownik";

export const UserNewModel={
    id: 0,
    userName: "",
    email: "",
    company: "",
    phoneNumber: "",
    roleName: DEFAULT_ROLE,
    comment: "",
    password: DEFAULT_PASSWORD,
    companyId: -1
}