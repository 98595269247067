import React, { Component } from 'react';
import {Route} from 'react-router';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import {BrowserRouter as Router} from "react-router-dom";
import AppLayout from "./appp/layout/default";
import {RoutedContent} from "./appp/routes";
import { UserContextProvider } from "./Users/UserContextProvider";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
// import 'devextreme/dist/css/dx.light.css'; 
// import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'
import './dx-styles.scss';


const basePath = process.env.BASE_PATH || '/';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (

        <Router basename={ basePath }>
            <UserContextProvider>
                <ReactNotification />
            <AppLayout>
                <RoutedContent />

            </AppLayout>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes}/>
            </UserContextProvider>
        </Router>

      // <Layout>
      //   <Route exact path='/' component={Reports} />
      //   <Route path='/counter' component={Counter} />
      //   <AuthorizeRoute path='/fetch-data' component={FetchData} />
      //   <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      // </Layout>
    );
  }
}
