
import React from "react";

export const getFullPrice=(rowData)=>{
    return (rowData.pricePln*rowData.productCount).toFixed(2);
}

export const ProductsOnOrderODColumnsDef = [
    {
        dataField: "idCode",
        caption: "Kod Id",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "name",
        caption: "Nazwa",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "pricePln",
        caption: "Kwota Pln",
        dataType: "number",
        allowEditing: true
    },
    {
        dataField: "priceUsd",
        caption: "Kwota USD",
        dataType: "number",
        allowEditing: true
    },
    {
        dataField: "productCount",
        caption: "ilość",
        dataType: "number",
        allowEditing: true
    },
    {
        dataField: "fullPrice",
        caption: "Pełna kwota",
        dataType: "number",
        allowEditing: false,
        calculateCellValue: getFullPrice
    },
]
    
