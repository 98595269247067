import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const CREATE_INVOICE_URL = 'Document/CreateInvoice'

export const CreateInvoiceQuery = (data, onResponse) => {
    return DefaultPostQuery(
        CREATE_INVOICE_URL,
        ConstantsTitles.DOCUMENT_TITLE,
        ConstantsMessages.DOCUMENT_CREATE_INVOICE,
        CreateInvoiceQuery.name,
        data,
        onResponse
    )
}
    
    
   