import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithResponseErrorQuery} from "../defaultPostWithResponseErrorQuery";

const CHANGE_PASSWORD_USER_URL = 'user/ChangePassword'

export const ChangeUserPasswordQuery = (data, onResponse) => {
    return DefaultPostWithResponseErrorQuery(
        CHANGE_PASSWORD_USER_URL,
        ConstantsTitles.USERS_TITLE,
        ConstantsMessages.USER_CHANGE_PASSWORD,
        ChangeUserPasswordQuery.name,
        data,
        onResponse
    )
}