import {AxiosAuthPostBlob} from "../../Helper";
import React, {useState} from "react";
import {
    Col, FormGroup,
    Input,
    Label
} from "../../appp/components";

import {UserContext} from "../../Users/UserContextProvider";
import '@progress/kendo-theme-default/dist/all.css';
import esMessages from "./../../pl-PL.json";
import {loadMessages} from "@progress/kendo-react-intl";

loadMessages(esMessages, "pl-PL");


function WarehouseWzPz () {
    
    const [userName, setUserName] = useState(null);
    const [user, setUser] = React.useContext(UserContext);
    const [dateFrom, setDateFrom] = useState(null);
    
    
    const getWzPdf=(data)=>{
        AxiosAuthPostBlob(
            "Report/GetWzPdf",
            {fromDate: dateFrom
            },
            (response) => {
                const filename = 'Wz.pdf'
                const blob= response.data;

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();


            },(error) => {
                // handle error

                console.log(error);

            });
    }

    const getPzPdf=(data)=>{
        AxiosAuthPostBlob(
            "Report/GetPzPdf",
            {fromDate: dateFrom
            },
            (response) => {
                const filename = 'Pz.pdf'
                const blob= response.data;

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();


            },(error) => {
                // handle error

                console.log(error);

            });
    }

    const getRwPdf=(data)=>{
        AxiosAuthPostBlob(
            "Report/GetRwPdf",
            {fromDate: dateFrom
            },
            (response) => {
                const filename = 'Rw.pdf'
                const blob= response.data;

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();


            },(error) => {
                // handle error

                console.log(error);

            });
    }
    
    
    return (
            <div>
                <div>
                    <FormGroup row>
                        <Label for="dateFrom" sm={3}>
                            Data - miesiąc
                        </Label>
                        <Col sm={9}>
                            <Input
                                type="date"
                                name="dateFrom"
                                id="dateFrom"
                                placeholder=""
                                value={dateFrom}
                                onChange={(value)=>
                                {
                                    setDateFrom(value.target.value)
                                }}

                            />
                        </Col>
                    </FormGroup>
                   
                    <button
                        title="Pobierz Wz dla okresu"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={getWzPdf}
                    >
                        Pobierz Wz
                    </button>
                    <button
                        title="Pobierz Pz dla okresu"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={getPzPdf}
                    >
                        Pobierz Pz
                    </button>
                    <button
                        title="Pobierz RW dla okresu"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={getRwPdf}
                    >
                        Pobierz Rw
                    </button>
                </div>
                <div>
                </div>
            </div>

    )
}

export default WarehouseWzPz;
