import React from 'react';

import { SidebarMenu } from './../../components';
import { CheckRolesForUrl } from "../../../Users/roleHelpers";

const roleName="Pracownik";

export const SidebarMiddleNav = () => (

     <SidebarMenu>
         {CheckRolesForUrl('orderRealization') ? <SidebarMenu.Item
             icon={<i className="fa fa-fw fa-home"></i>}
             title="Realizacja Zamówień"
         >
             {/*<SidebarMenu.Item title="Zamówienia do wydruku" to='/orderrealization/toprint' exact />*/}
             <SidebarMenu.Item title="Do skompletowania" to='/orderrealization/neworders' exact />
             <SidebarMenu.Item title="Czekają na wysyłkę" to='/orderrealization/orderstosend' exact />
             <SidebarMenu.Item title="Zwroty do przetworzenia" to='/orderrealization/orderstoreturn' exact />

         </SidebarMenu.Item> : <SidebarMenu.Item title="" exact/> }
         
         {CheckRolesForUrl('storage') ? <SidebarMenu.Item 
        icon={<i className="fa fa-fw fa-home"></i>} 
        title="Magazyn"
    >
        
            <SidebarMenu.Item title="Miejsca w magazynie" to='/warehouse/warehouseplace' exact /> 
         
            <SidebarMenu.Item title="Produkty" to='/warehouse/products' exact />
            <SidebarMenu.Item title="Kody Qr magazynu" to='/warehouse/printQRCodes' exact />
            <SidebarMenu.Item title="Przyjęcie towaru" to='/warehouse/pickupOrder' exact />
            {/*<SidebarMenu.Item title="Pobranie towaru" to='/warehouse/packedProduct' exact />*/}
           

            
            
         </SidebarMenu.Item> : <SidebarMenu.Item title="" exact/> }
         {CheckRolesForUrl('managment') ?  <SidebarMenu.Item
            icon={<i className="fa fa-fw fa-th"></i>}
            title="Zarządzanie"
            
        >
             <SidebarMenu.Item title="Użytkownicy" to='/users/usersManagment' exact />
             <SidebarMenu.Item title="Klienci" to='/company/company' exact />
             <SidebarMenu.Item title="Raport sprzedaży" to='/reports/sellingReport' exact />
             <SidebarMenu.Item title="Raport przyjęć" to='/reports/pickupReport' exact />
             <SidebarMenu.Item title="Raport sprzedaży grupowane" to='/reports/sellingGroupedReport' exact />


         </SidebarMenu.Item> :  <SidebarMenu.Item title="" exact/>}

         {CheckRolesForUrl('documents') ?  <SidebarMenu.Item
             icon={<i className="fa fa-fw fa-th"></i>}
             title="Dokumenty"
         >
             <SidebarMenu.Item title="Raporty magazynowe" to='/documents/warehouse' exact />
             <SidebarMenu.Item title="Faktury grupowe" to='/documents/groupInvoice' exact />
             {/*<SidebarMenu.Item title="Faktury" to='/documents/invoices' exact />*/}
         </SidebarMenu.Item> :  <SidebarMenu.Item title="" exact/>}

         <SidebarMenu.Item
             icon={<i className="fa fa-fw fa-th"></i>}
             title="Zamówienia"
         >
             <SidebarMenu.Item title="Zamówienia" to='/order/orders' exact />
             {CheckRolesForUrl('/order/newOrderUser') ?
                 <SidebarMenu.Item title="Nowe zamówienie" to='/order/newOrderUser' exact />
                 :  <SidebarMenu.Item title="" exact/>}
             {CheckRolesForUrl('/order/newOrderUser') ?
                 <SidebarMenu.Item title="Do zaakceptowania" to='/order/orderstoaccept' exact />
                 :  <SidebarMenu.Item title="" exact/>}
             
             {CheckRolesForUrl('/order/newOrderUser') ?
                 <SidebarMenu.Item title="Nowe zamówienie Rw" to='/order/newOrderRw' exact />
                 :  <SidebarMenu.Item title="" exact/>}
             {CheckRolesForUrl('/order/newOrderClient') ?
                 <SidebarMenu.Item title="Nowe zamówienie" to='/order/newOrderClient' exact />
                 :  <SidebarMenu.Item title="" exact/>}
         </SidebarMenu.Item>

         {CheckRolesForUrl('inputOrders') ?  <SidebarMenu.Item
             icon={<i className="fa fa-fw fa-th"></i>}
             title="Przyjęcia towaru - faktury"
         >
                 <SidebarMenu.Item title="Faktury - przyjęcia" to='/order/otherDeliveredOrdersTable' exact />
                 <SidebarMenu.Item title="Nowe faktura" to='/order/createOrderOtherDelivered' exact />
             <SidebarMenu.Item title="Przypisz przyjęcia" to='/order/pickupToOrder' exact />



         </SidebarMenu.Item> :  <SidebarMenu.Item title="" exact/>}

         <SidebarMenu.Item
             icon={<i className="fa fa-fw fa-th"></i>}
             title="Agent"
         >
                 <SidebarMenu.Item title="Nowe zamówienie" to='/order/newOrderAgent' exact />
         </SidebarMenu.Item>

     </SidebarMenu > 
);
