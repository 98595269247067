import {AxiosAuthPost} from "../Helper";
import {LogError, LogInfo} from "../logs";
import {ShowErrorNotification, ShowInfoNotification} from "../notifications";


export const DefaultPostQuery = (url,infoTitle,responseOkMessage,logTitle, data, onResponse,showNotification) => {
    LogInfo(logTitle,data);
    AxiosAuthPost(
        url,
        data,
        (response) => {

            if(showNotification===undefined || showNotification===true) {
                ShowInfoNotification(infoTitle, responseOkMessage);
            }
            onResponse(response);

        }, (error) => {

            // handle error
            LogError(logTitle,error);
            ShowErrorNotification(infoTitle, error.message);

        });
}