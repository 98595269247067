import {LogError, LogInfo} from "../logs";
import {ShowErrorNotification, ShowInfoNotification} from "../notifications";
import Axios from "axios";
import ConstantsTitles from "../Consts/titles";
import moment from "moment";

const GET_NBP_USD_URL = 'https://api.nbp.pl/api/exchangerates/rates/a/usd/'

const onError=(error)=>{
    LogError('GetOrderDetailsQuery',error);
    ShowErrorNotification(ConstantsTitles.ORDER_TITLE, error.message);
}

export const GetNbpUsdGetQuery = (date, onResponse) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
   
    LogInfo('NbpUsdGetQuery',date);
    
        Axios.get(GET_NBP_USD_URL+ moment(newDate).format("yyyy-MM-DD"))
            .then((response) => {
                onResponse(response);
            })
            .catch(function(error) {
                // handle error
                onError(error);
            });
};
