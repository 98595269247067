import React, {useState} from "react";
import OrderProductWarehause from "../../OrderRealization/orderProductWarehause";
import PackingProduct from "../../Packing/packingProduct";
import OrderDetailWithoutPriceComponent from "../DetailsComponents/orderDetailWithoutPriceComponent";
import {ORDER_STATUS_PACKED, ORDER_STATUS_RETURNED} from "../../../Enums/orderStatusEnum";
import ButtonClose from "../../Base/Buttons/buttonClose";
import {ReturnOrderQuery} from "../../../Query/Orders/returnOrderQuery";

export default function OrderReturningComponent (props) {
    const [selectedOrder, setSelectedOrder] = useState(props.selectedOrder);
    const [handsSelectedProductName,setHandsSelectedProductName]=useState(undefined);
    const [inProcess, setInProcess] = useState(false);

    const reloadData=props.reloadData;

    const returnOrder = (data) => {

        setInProcess(true);
        const dat ={orderId: selectedOrder.id,productOnOrderId: data.productOnOrderId, warehouseOnOrderId: data.warehouseOnOrderId , count: data.count }

        ReturnOrderQuery(dat,()=>{
            setHandsSelectedProductName(undefined);
            reloadData((newData)=>{
                setSelectedOrder(newData);
                setInProcess(false)
                if(newData.orderStatus===ORDER_STATUS_RETURNED)
                {
                    props.cancel();
                }

            });

        });
    };


    return (
        <React.Fragment>

            <div>
                <OrderDetailWithoutPriceComponent row={selectedOrder}
                                                  action={()=>props.cancel()}
                                                  actionName={"Zamknij"}
                                                  removeProduct={(index)=>{
                                                  }}/>
            </div>
            <div>


                {(!inProcess && !handsSelectedProductName) ? <OrderProductWarehause data={selectedOrder.productToOrderViewModel}
                                                                                    completeOrder={returnOrder}
                                                                                    getDescription="Zwrócone"
                                                                                   
                                                                                    
                    />
                    : !inProcess ? <PackingProduct productName={handsSelectedProductName} completeOrder={returnOrder} onCancel={()=>
                    {
                        setHandsSelectedProductName(undefined);
                    }}/> : <p></p> }
                <ButtonClose onClick={()=>props.cancel()}/>
            </div>

        </React.Fragment>
    )
}