import React, {useEffect, useState} from "react";
import {useGetUsers} from "../../../Query/Users/useGetUsers";
import {useGetRoles} from "../../../Query/Users/useGetRoles";
import {useGetCompanyNames} from "../../../Query/Company/useGetCompanyNames";
import {DEFAULT_PASSWORD, UserNewModel} from "../../../Models/userNewModel";
import ConstantsMessages from "../../../Consts/messages";
import {DeleteUserQuery} from "../../../Query/Users/deleteUserQuery";
import {ChangeUserPasswordQuery} from "../../../Query/Users/changeUserPasswordQuery";
import {AddNewUserQuery} from "../../../Query/Users/addNewUserQuery";
import {EditUserQuery} from "../../../Query/Users/editUserQuery";
import {DataGrid, LoadPanel} from "devextreme-react";
import {Item, SearchPanel, Toolbar} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import {EditUserComponent} from "./editUserComponent";
import {ChangeUserPasswordComponent} from "./changeUserPasswordComponent";
import {UsersColumnsDef} from "../../../ColumnsDef/Users/usersColumnsDef";
import {AddButtonsOnEnd} from "../../Base/dxGridHellper";

export default function UsersComponent (props) {
    
    const [dataInEdit, setDataInEdit] = useState(undefined);
    const [showPasswordChange,setShowPasswordChange]=useState(undefined);


    const [usersLoading, usersError, setUsersReload, users] = useGetUsers();
    const [rolesLoading, rolesError, setRolesReload, roles] = useGetRoles();
    const [companyNamesLoading, companyNamesError, setCompanyNamesReload, companyNames] = useGetCompanyNames();
    const [editUsersColumns,setEditUsersColumns]=useState(
        AddButtonsOnEnd(UsersColumnsDef,
            [
                {
                    name:'customEdit',
                    icon:'edit',
                    onClick: (e)=> showEditModal(e.row.data)
                },
                {
                    name:'customDelete',
                    icon:'remove',
                    onClick: (e)=> deleteUser(e.row.data)
                },
                {
                    name:'customChangePassword',
                    icon:'key',
                    onClick: (e)=> showChangePasswordModal(e.row.data)
                },
            ]
            )
    )
    
    const insert = () => {
        setDataInEdit(UserNewModel);
    };

    const showEditModal=(row)=>{
        setDataInEdit(row);
    }

    const showChangePasswordModal=(row)=>{

        setShowPasswordChange({
            userId: row.id,
            adminChange: true,
            newPassword: DEFAULT_PASSWORD,
            login: row.userName,
            oldPassword: ""
        });
    }

    const deleteUser=(data)=>{

        if (window.confirm(ConstantsMessages.USER_DELETE_CONFIRM)) {
            DeleteUserQuery(data,()=>{
                setUsersReload(true);
            });
        } else {
            // Do nothing!
        }
    }

    const changePassword=(data)=> {

        ChangeUserPasswordQuery(data,()=>{
        });

        setShowPasswordChange(undefined);

    }

    const save=(data) => {
        if(data.id===0)
        {
            AddNewUserQuery(data,()=>{
                setUsersReload(true);
            });
        }
        else {
            EditUserQuery(data,()=>{
                setUsersReload(true);
            });
        }
        setDataInEdit(undefined);
    }

    return (
        <div>
            <DataGrid
                dataSource={users}
                rowAlternationEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
                columns={editUsersColumns}
            >
                <LoadPanel
                    enabled={true}
                    showIndicator={true}
                    showPane={false}
                    visible={usersLoading}
                />
                <Toolbar>
                    <Item  location="before">
                        <Button
                            icon='add'
                            onClick={insert} />
                    </Item>
                    <Item  location="before"
                        name="searchPanel"
                    />
                </Toolbar>
                <SearchPanel visible={true}/>


            </DataGrid>
            {dataInEdit !== undefined ? <EditUserComponent
                save={
                    (data) => {
                        save(data)
                    }
                }
                data={dataInEdit}
                cancel={()=>{
                    setDataInEdit(undefined);
                }}
                Roles={roles}
                Company={companyNames}
                isNew={dataInEdit.id===0}

            /> : null}
            {showPasswordChange !== undefined ? <ChangeUserPasswordComponent
                save={
                    (data) => {
                        changePassword(data)
                    }
                }
                data={showPasswordChange}
                cancel={()=>{
                    setShowPasswordChange(undefined);
                }}

            /> : null}
        </div>
    )
}