import React from "react";
import {OrderStatusEnum} from "../../Enums/orderStatusEnum";



export const OrdersToCompletingColumnDef = [
    {
        dataField: "orderNr",
        caption: "Nr zamówienia",
        dataType: "string"
    },
    {
        dataField: "companyName",
        caption: "Nazwa firmy",
        dataType: "string"
    },
    {
        dataField: "deliveryAddress",
        caption: "Adres dostawy",
        dataType: "string"
    },
    {
        dataField: "createdDate",
        caption: "Data utworzenia",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm",
        sortOrder: "desc"
    },
    {
        dataField: "orderStatus",
        caption: "Status",
        dataType: "object",
        lookup: {
            dataSource: OrderStatusEnum,
            valueExpr: "id",
            displayExpr: "desc"
        }
    },
]
    
