import {AxiosAuthGet} from "../../Helper";
import {ShowErrorNotification} from "../../notifications";
import ConstantsTitles from "../../Consts/titles";

import {LogError, LogInfo} from "../../logs";

const GET_DATA_TO_ORDER_RETURNING_URL = 'order/GetDataToOrderReturning/'

const onError=(error)=>{
    LogError('GetDataToOrderReturning',error);
    ShowErrorNotification(ConstantsTitles.ORDER_TITLE, error.message);
}

export const GetDataToOrderReturningQuery = (orderId, onResponse) => {
    LogInfo('GetDataToOrderReturning',orderId);

    const newUrl=GET_DATA_TO_ORDER_RETURNING_URL+orderId;

    AxiosAuthGet(newUrl,onResponse,onError);

}