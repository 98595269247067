export const OrderDetailProductWithoutPriceColumnsDef = [
    {
        dataField: "productIdCode",
        caption: "Kod ID",
        dataType: "string"
    },
    {
        dataField: "productName",
        caption: "Nazwa",
        dataType: "string"
    },
    {
        dataField: "productCount",
        caption: "Ilość",
        dataType: "string"
    },
]