import React, {useCallback, useEffect, useRef, useState} from "react";
import {useGetCompany} from "../../../Query/Company/useGetCompany";
import {useGetCompanyRepresentative} from "../../../Query/Company/useGetCompanyRepresentative";
import {CompanyNewModel} from "../../../Models/companyNewModel";
import {GetCompanyDetailsQuery} from "../../../Query/Company/getCompanyDetailsQuery";
import {AddNewCompanyQuery} from "../../../Query/Company/addNewCompanyQuery";
import {EditCompanyQuery} from "../../../Query/Company/editCompanyQuery";
import {SearchPanel, Toolbar, Item, Paging, Pager, Column, GroupPanel} from "devextreme-react/data-grid";
import {DataGrid, LoadPanel} from "devextreme-react";
import {DeleteCompanyQuery} from "../../../Query/Company/deleteCompanyQuery";
import {Button} from "devextreme-react/button";
import {EditCompanyComponent} from "./editCompanyComponent";
import ConstantsMessages from "../../../Consts/messages";
import {OrderDxStore} from "../../../Query/Orders/orderDxStore";
import {CompanyDxStore} from "../../../Query/Company/companyDxStore";
import {AddButtonsOnEnd, dataGridRefresh} from "../../Base/dxGridHellper";
import {CompaniesColumnsDef} from "../../../ColumnsDef/Company/companyColumnsDef";

export default function CompaniesComponent (props) {
    
    const [dataInEdit, setDataInEdit] = useState(undefined);
    const [editCompanyColumns]=useState(
        AddButtonsOnEnd(CompaniesColumnsDef
            ,[
                {
                    name:'customEdit',
                    icon:'edit',
                    onClick: (e)=> showEditModal(e.row.data)
                },
                {
                    name:'customDelete',
                    icon:'remove',
                    onClick: (e)=> deleteCompany(e.row.data)
                }
            ])
    )

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative] = useGetCompanyRepresentative();
    const companiesDataGridRef = useRef();
    
    
    const insert = useCallback( () => {
        setDataInEdit(CompanyNewModel);
    },[]);

    const showEditModal=useCallback((row)=>{
        GetCompanyDetailsQuery(row.id,(response)=>{
            setDataInEdit(response.data);
        });
    },[]);

    const deleteCompany=useCallback((data)=>{
        if (window.confirm(ConstantsMessages.COMPANY_DELETE_CONFIRM)) {
            DeleteCompanyQuery(data,()=>{
                dataGridRefresh(companiesDataGridRef);
            });
        } else {
            // Do nothing!
        }
    },[]);

    const save=useCallback((data) => {
        if(data.id===0)
        {
            AddNewCompanyQuery(data,()=>{
                dataGridRefresh(companiesDataGridRef);
            });
        }
        else {
            EditCompanyQuery(data,()=>{
                dataGridRefresh(companiesDataGridRef);
            });
        }
        setDataInEdit(undefined);
    },[]);
    
    return (
        <div>
            <DataGrid
                dataSource={CompanyDxStore}
                rowAlternationEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
                columns={editCompanyColumns}
                remoteOperations={true}
                ref={companiesDataGridRef}
            >
                <Toolbar>
                    <Item location="before">
                        <Button
                            icon='add'
                            onClick={insert} />
                    </Item>
                    <Item  location="before"
                        name="searchPanel"
                    />
                </Toolbar>
                <SearchPanel visible={true}/>
               
                
            </DataGrid>
            {dataInEdit !== undefined ? <EditCompanyComponent
                save={
                    (data) => {
                        save(data)
                    }
                }
                data={dataInEdit}
                companyRepresentative={companyRepresentative}
                cancel={()=>{
                    setDataInEdit(undefined);
                }}

            /> : null}
        </div>
    )
}