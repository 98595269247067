import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const EDIT_COMPANY_URL = 'company/Edit'

export const EditCompanyQuery = (data, onResponse) => {
    return DefaultPostQuery(
        EDIT_COMPANY_URL,
        ConstantsTitles.COMPANY_TITLE,
        ConstantsMessages.COMPANY_EDITED,
        EditCompanyQuery.name,
        data,
        onResponse
    )
}