export const UsersColumnsDef = [
    {
        dataField: "userName",
        caption: "Nazwa",
        dataType: "string"
    },
    {
        dataField: "phoneNumber",
        caption: "Telefon",
        dataType: "string"
    },
    {
        dataField: "email",
        caption: "E-mail",
        dataType: "string"
    },
    {
        dataField: "companyName",
        caption: "Firma",
        dataType: "string"
    },
    {
        dataField: "roleName",
        caption: "Rola",
        dataType: "string"
    },
    {
        dataField: "comment",
        caption: "Uwagi",
        dataType: "string"
    },
]