import {
    Card, CardBody, CardHeader, Table
} from "../../appp/components";
import React from "react";
import ProductOnWarehouseRow from "./productOnWarehouseRow";

const ProductOnWarehouseTable = (props)=>
{
    
    return (
        <React.Fragment>
       
            <Card >
                <CardHeader tag="h6" className="bg-info text-white">
                    {props.product.idCode} {props.product.name}
                </CardHeader>
                <CardBody>
                    {props.product.description}

                <Table className="mb-0" responsive>
                    <thead>
                    <tr>
                        <th className="bt-0">Kod id magazynu</th>
                        <th className="bt-0">Regał</th>
                        <th className="bt-0">Półka</th>
                        <th className="bt-0">Miejsce</th>
                        <th className="bt-0">Ilość</th>
                        <th className="bt-0">Data ważności</th>
                    </tr>
                    </thead>
                    <tbody>

            {props.data.map((dat, i) => {
               
                        return (
                            <tr id={'war_'+dat.id}>
                            <ProductOnWarehouseRow data={dat} />
                            </tr>
                            )
                    })}
                    </tbody>
            </Table>
                </CardBody>

            </Card>
        
        </React.Fragment>
        
    )
}

export default ProductOnWarehouseTable
