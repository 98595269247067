import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithFileDownloadQuery} from "../defaultPostWithFileDownloadQuery";

const GET_INVOICE_PDF_URL = 'Document/GetInvoicePdf'

export const GetInvoicePdfQuery = (data, onResponse) => {
    return DefaultPostWithFileDownloadQuery(
        GET_INVOICE_PDF_URL,
        ConstantsTitles.DOCUMENT_TITLE,
        ConstantsMessages.DOCUMENT_GET_INVOICE_PDF,
        GetInvoicePdfQuery.name,
        'Faktura'+data.id+'.pdf',
        {id:data.id},
        onResponse
    )
}
    
    
   