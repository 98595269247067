import {AxiosAuthPost, useGetApiDataAuth} from "../../Helper";
import React, {useEffect, useState} from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {CustomExportCSV} from "../../appp/routes/Tables/ExtendedTable/components";
import {
    Button,
    ButtonGroup,
    Col, CustomInput,
    FormGroup,
    Input,
    InputGroup,
    Label
} from "../../appp/components";
import BootstrapTable from "react-bootstrap-table-next";

import {UserContext} from "../../Users/UserContextProvider";
import {store} from "react-notifications-component";



function SellingReportsGroupedTable () {

    const [result, setResult] = useState([]);

    const [userName, setUserName] = useState(null);
    const [user, setUser] = React.useContext(UserContext);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState(-1);

    const receiveDataCompany = (response) => {
        console.log(response.data);
        setCompany(response.data);
    };

    const [loadingCompany, errorCompany, setReloadCompany] = useGetApiDataAuth(
        "company/GetCompanyNames",
        receiveDataCompany
    );

    useEffect(()=>{
        // let x = new Date();
        // let y = new Date();
        // x.setDate(0);
        // setDateTo(x)
        // y.setMonth(x.getMonth()-1);
        // setDateFrom(y);
    },[])


    const loadData = () => {

        AxiosAuthPost(
            "Report/GetSellingGroupedReport",
            {fromDate: dateFrom,toDate: dateTo,companyId: companyId
            },
            (response) => {
                setResult(response.data);
            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });

    }



    const sortCaret = (order) => {
        if (!order)
            return <i className="fa fa-fw fa-sort text-muted"></i>;
        if (order)
            return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
    };



    const columnDefs = [
        // {
        //     dataField: 'qrCodeImage',
        //     text: 'Photo',
        //     formatter: (cell) => (
        //         <Avatar.Image src={ `data:image/jpeg;base64,${cell}` } />
        //     ),
        // }, 

        {
            dataField: 'idCode',
            text: 'Kod id',
            sort: true,
            sortCaret
        }, {
            dataField: 'productName',
            text: 'Nazwa produktu',
            sort: true,
            sortCaret
        },
        {
            dataField: 'count',
            text: 'Ilość',
            sort: true,
            sortCaret
        },
        {
            dataField: 'price',
            text: 'Cena',
            sort: true,
            sortCaret
        },
    ];


    return (
        <div>
            <div>
                <FormGroup row>
                    <Label for="dateFrom" sm={3}>
                        Data - od
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="date"
                            name="dateFrom"
                            id="dateFrom"
                            placeholder=""
                            value={dateFrom}
                            onChange={(value)=>
                            {
                                setDateFrom(value.target.value)
                            }}

                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dateTo" sm={3}>
                        Data - do
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="date"
                            name="dateTo"
                            id="dateTo"
                            placeholder=""
                            value={dateTo}
                            onChange={(value)=>
                            {
                                setDateTo(value.target.value)
                            }}

                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="companyId" sm={3}>
                        Firma
                    </Label>
                    <Col sm={9}>
                        <InputGroup>
                            <CustomInput type="select" id="companyId" name="companyId"  value={companyId} onChange={
                                (event) => {
                                    setCompanyId(event.target.value)}
                            } >
                                {company.map(option => {
                                    return (<option key={option.id} value={option.id}>{option.name}</option>);
                                })}

                            </CustomInput>
                        </InputGroup>
                    </Col>
                </FormGroup>
            </div>
            <ToolkitProvider
                keyField="id"
                data={ result }
                columns={ columnDefs }
                search
                exportCSV
            >
                {
                    props => (
                        <React.Fragment>
                            <div className="d-flex justify-content-end align-items-center mb-2">
                                <h6 className="my-0">
                                    Produkty
                                </h6>

                                <div className="d-flex ml-auto">
                                    <ButtonGroup>

                                        <Button onClick={loadData}>
                                            Wczytaj raport
                                        </Button>
                                        <CustomExportCSV
                                            { ...props.csvProps }
                                        >
                                            Export
                                        </CustomExportCSV>
                                    </ButtonGroup>
                                </div>
                            </div>
                            <BootstrapTable
                                classes="table-responsive-lg"
                                bordered={ false }
                                responsive
                                hover
                                { ...props.baseProps }
                            />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>


        </div>
    )
}

export default SellingReportsGroupedTable;
