import {AxiosAuthGet} from "../../Helper";
import {ShowErrorNotification} from "../../notifications";
import ConstantsTitles from "../../Consts/titles";

import {LogError, LogInfo} from "../../logs";

const GET_ORDERS_INVOICES_URL = 'order/GetOrdersInvoices'

const onError=(error)=>{
    LogError('GetODOrdersInvoicesQuery',error);
    ShowErrorNotification(ConstantsTitles.ORDER_TITLE, error.message);
}

export const GetODOrdersInvoicesQuery = (onResponse) => {
    LogInfo('GetODOrdersInvoicesQuery');
    
    AxiosAuthGet(GET_ORDERS_INVOICES_URL,onResponse,onError);

}