import {LogError, LogInfo} from "../logs";
import {AxiosAuthPost} from "../Helper";
import {ShowErrorNotification, ShowInfoNotification} from "../notifications";


export const DefaultPostWithResponseErrorQuery = (url,infoTitle,responseOkMessage,logTitle, data, onResponse) => {
    LogInfo(logTitle,data);
    AxiosAuthPost(
        url,
        data,
        (response) => {

            if(response.data.succeeded) {
                ShowInfoNotification(infoTitle, responseOkMessage);
                onResponse(response);
            }
            else {
                LogError(logTitle, response.data.errors);
                ShowErrorNotification(infoTitle,  response.data.errors[0].description);
            }
            
        }, (error) => {

            // handle error
            LogError(logTitle,error);
            ShowErrorNotification(infoTitle, error.message);

        });
}