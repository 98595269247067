
export const DiscountOfTypeOnProductColumnsDef = [
    {
        dataField: "type",
        caption: "Typ",
        dataType: "string"
    },
    {
        dataField: "discount",
        caption: "Procent zniżki",
        dataType: "number"
    },
]
    