export const OrderReturnProductsColumnsDef = [
    {
        dataField: "productIdCode",
        caption: "Kod ID",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "productName",
        caption: "Nazwa",
        dataType: "string",
        allowEditing: false
    },
    {
        dataField: "productCount",
        caption: "Ilość",
        dataType: "string",
        allowEditing: true
    },
    {
        dataField: "fullPrice",
        caption: "Kwota",
        alignment: "right",
        allowEditing: false
    },
]
    
