import {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";

const GET_COMPANY_REPRESENTATIVE_URL = 'company/GetCompanyRepresentativeNames'

export const useGetCompanyRepresentative = () => {
    const [companyRepresentative, setCompanyRepresentative] = useState([]);
    
    const receiveData = (response) => {
        setCompanyRepresentative(response.data);
    };

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload] = useGetApiDataAuth(
        GET_COMPANY_REPRESENTATIVE_URL,
        receiveData
    );

    return [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative];
};