import {DefaultPostQuery} from "./defaultPostQuery";
import {LogError, LogInfo} from "../logs";
import {AxiosAuthPostBlob} from "../Helper";
import {ShowErrorNotification, ShowInfoNotification} from "../notifications";

export const DefaultPostWithFileDownloadQuery = (url,infoTitle,responseOkMessage,logTitle, filename, data, onResponse) => {
    LogInfo(logTitle, data);
    AxiosAuthPostBlob(
        url,
        data,
        (response) => {

            const blob = response.data;

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();

            ShowInfoNotification(infoTitle, responseOkMessage);
            onResponse(response);

        }, (error) => {

            // handle error
            LogError(logTitle, error);
            ShowErrorNotification(infoTitle, error.message);

        });
    
}