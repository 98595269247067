import React from "react";

export const AddressColumnsDef = [
    {
        dataField: "name",
        caption: "Nazwa",
        dataType: "string"
    },
    {
        dataField: "street",
        caption: "Ulica",
        dataType: "string"
    },
    {
        dataField: "city",
        caption: "Miasto",
        dataType: "string"
    },
    {
        dataField: "zip",
        caption: "Kod pocztowy",
        dataType: "string"
    },
    {
        dataField: "number",
        caption: "Numer",
        dataType: "string"
    },
    {
        dataField: "localNumber",
        caption: "Numer lokalu",
        dataType: "string"
    },
]
    
