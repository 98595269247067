import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithResponseErrorQuery} from "../defaultPostWithResponseErrorQuery";

const ADD_USER_URL = 'user/CreateUsers'

export const AddNewUserQuery = (data, onResponse) => {
    return DefaultPostWithResponseErrorQuery(
        ADD_USER_URL,
        ConstantsTitles.USERS_TITLE,
        ConstantsMessages.USER_ADDED,
        AddNewUserQuery.name,
        data,
        onResponse
    )
}