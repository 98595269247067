import React, {useRef, useState} from "react";
import {
    SearchPanel,
    Paging,
    Pager,
    GroupPanel,
    FilterRow,
    MasterDetail, Selection, Toolbar, Item
} from "devextreme-react/data-grid";
import {DataGrid} from "devextreme-react";
import {CancelOrderQuery} from "../../../Query/Orders/cancelOrderQuery";
import {AddButtonsOnEnd, dataGridRefresh, useDxDataGridExpandedLoadQuery} from "../../Base/dxGridHellper";
import ConstantsMessages from "../../../Consts/messages";
import OrderActionExpandedComponent from "../DetailsComponents/orderActionExpandedComponent";
import {OrdersColumnsDef} from "../../../ColumnsDef/Orders/ordersColumnsDef";
import {OrderToSendDxStore} from "../../../Query/Orders/orderToSendDxStore";
import {GetOrderDetailsQuery} from "../../../Query/Orders/getOrderDetailQuery";
import {ChangeOrderToSendQuery} from "../../../Query/Orders/changeOrderToSendQuery";
import {Button} from "devextreme-react/button";
import {GetPackageProtocolPdfQuery} from "../../../Query/Documents/getPackageProtocolPdfQuery";



export default function OrdersToSendComponent (props) {


    const ordersDataGridRef = useRef();
    const [ordersColumns]=useState(
        OrdersColumnsDef
    )


    
    const cancelSelectedOrder=(data)=>{

        if (window.confirm(ConstantsMessages.ORDER_CANCEL_ORDER_CONFIRM)) {
            CancelOrderQuery(data,()=>{
                dataGridRefresh(ordersDataGridRef);
            });
        }
    }

    const changeToSendSelectedOrder=(data)=>{
        ChangeOrderToSendQuery(data,()=>{
                dataGridRefresh(ordersDataGridRef);
            });
        
    }

    const getPackageProtocolPdf=(data)=>{
        GetPackageProtocolPdfQuery({id:0},()=>{
            dataGridRefresh(ordersDataGridRef);
        });
    }

    const actions={
        actionName: "Wysłane",
        action: changeToSendSelectedOrder,
        actionName2: "Anuluj zamówienie",
        action2: cancelSelectedOrder
    }

    const [contentReady, selectionChanged, renderDetailSection,reloadData]=useDxDataGridExpandedLoadQuery(GetOrderDetailsQuery,OrderActionExpandedComponent,actions);

    return (
        <div className={'content-block dx-card responsive-paddings'}>

                <DataGrid
                    keyExpr="id"
                    dataSource={OrderToSendDxStore}
                    showBorders={true}
                    remoteOperations={true}
                    columnAutoWidth={true}
                    ref={ordersDataGridRef}
                    columns={ordersColumns}
                    onSelectionChanged={selectionChanged}
                    onContentReady={contentReady}
                >
                    <Selection mode="single" />
                    <SearchPanel visible={true}/>
                    <Paging defaultPageSize={12} />
                    <Pager
                        showPageSizeSelector={true}
                    />
                    <MasterDetail enabled={false} render={renderDetailSection}/>
                    <Toolbar>
                        <Item location="before"  name="searchPanel"/>
                        <Item location="before">
                            <Button type={"success"} onClick={getPackageProtocolPdf}>
                                Pobierz protokół DPD
                            </Button>
                        </Item>
                    </Toolbar>

                </DataGrid> 
          
        </div>
    )
}