import React from "react";

const SelectProductRow = (props)=>
{

    return (
        <React.Fragment>
            <td className="align-middle">
                <div>
                    <i className="fa fa-arrow-circle-right fa-2x" onClick={() =>  props.onSelect(props.data)}></i>
                </div>
            </td>
            <td className="align-middle">
                <div>
                    {props.data.productIdCode}
                </div>
            </td>
            <td className="align-middle">
                <div>
                    {props.data.name}
                </div>
            </td>
            <td className="align-middle">

                {props.data.count > 25 ?
                    <span className="text-info">
                            {props.data.count}
                        </span> :
                    <span className="text-danger">
                            {props.data.count}
                        </span>
                }
            </td>
            <td className="align-middle">
                <div>
                    {props.data.expirationDate}
                </div>
            </td>
         
        </React.Fragment>

    )

}

export default SelectProductRow;