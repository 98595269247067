import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithResponseErrorQuery} from "../defaultPostWithResponseErrorQuery";

const EDIT_USER_URL = 'user/EditUser'

export const EditUserQuery = (data, onResponse) => {
    return DefaultPostWithResponseErrorQuery(
        EDIT_USER_URL,
        ConstantsTitles.USERS_TITLE,
        ConstantsMessages.USER_EDITED,
        EditUserQuery.name,
        data,
        onResponse
    )
}