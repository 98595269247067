import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const CANCEL_ORDER_URL = 'Order/CancelOrder'

export const CancelOrderQuery = (data, onResponse) => {
    return DefaultPostQuery(
        CANCEL_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CANCEL_ORDER,
        CancelOrderQuery.name,
        {id:data.id},
        onResponse
    )
}
