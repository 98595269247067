import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const COMPLETE_ORDER_URL = 'Order/CompeteOrder'

export const CompleteOrderQuery = (data, onResponse,showNotification) => {
    return DefaultPostQuery(
        COMPLETE_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_COMPLETE_ORDER,
        CompleteOrderQuery.name,
        data,
        onResponse,
        showNotification
    )
}