import {AxiosAuthPost, useGetApiDataAuth} from "../../Helper";
import React, {useEffect, useState} from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {CustomExportCSV, CustomSearch} from "../../appp/routes/Tables/ExtendedTable/components";
import {
    Avatar,
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col, DropdownItem, DropdownMenu, DropdownToggle, ModalBody, ModalFooter, ModalHeader,
    Row, UncontrolledButtonDropdown,
    UncontrolledModal
} from "../../appp/components";
import BootstrapTable from "react-bootstrap-table-next";

import EditProduct from "./editProduct";
import {FaEdit, FaTrash} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import AddToWarehouse from "./addToWarehouse";
import {ApplicationPaths} from "../api-authorization/ApiAuthorizationConstants";
import authService from "../api-authorization/AuthorizeService";
import ProductOnWarehouseTable from "../productonwarehouse/productOnWarehouseTable";
import { CheckRolesComponentName } from "../../Users/roleHelpers";
import {UserContext} from "../../Users/UserContextProvider";
import {store} from "react-notifications-component";



function ProductTable () {

    const [result, setResult] = useState([]);
    const [dataInEdit, setDataInEdit] = useState(undefined);
    const [showAddToWarehouse,setShowAddToWarehouse]=useState(undefined);

    const [userName, setUserName] = useState(null);
    const [user, setUser] = React.useContext(UserContext);


    // useEffect(async ()=>{
    //     const load= async ()=> {
    //         const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    //
    //         setUserName(user && user.name);
    //     }
    //     load();
    //
    // },[])

    const receiveData = (response) => {
        console.log(response.data);
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "product/Get",
        receiveData
    );

    const insert = () => {
        setDataInEdit({
            id: 0,
            idCode: "",
            name: "",
            nameEng: "",
            description: "",
            descriptionEng: "",
            brand: "",
            type: "",
            subtype: "",
            priceForProfessionals: 0,
            price: 0,
            isService: false
        });
    };

    const showEditModal=(row)=>{
        console.log("edit",row)
        setDataInEdit(row);
    }

    const showDeleteModal=(data)=>{

        AxiosAuthPost(
            "product/Delete",
            data,
            (response) => {
                setReload(true);
                store.addNotification({
                    title: "Produkt",
                    message: "Produkt został usunięty",
                    type: "success",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            },(error) => {
                // handle error

                console.log(error);
                store.addNotification({
                    title: "Błąd!",
                    message: error.message,
                    type: "danger",
                    insert: "top",
                    container: "top-full",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                });
            });
    }

    const save=(data) => {
        if(data.id===0)
        {
            AxiosAuthPost(
                "product/Add",
                data,
                (response) => {
                    setReload(true);
                    store.addNotification({
                        title: "Produkt",
                        message: "Produkt został dodany",
                        type: "success",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                },(error) => {
                    // handle error

                    console.log(error);
                    store.addNotification({
                        title: "Błąd!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                    });
                });
        }
        else {
            AxiosAuthPost(
                "product/Edit",
                data,
                (response) => {
                    setReload(true);
                    store.addNotification({
                        title: "Produkt",
                        message: "Produkt został zmodyfikowany",
                        type: "success",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });


                },(error) => {
                    // handle error

                    console.log(error);
                    store.addNotification({
                        title: "Błąd!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                    });
                });
        }
        setDataInEdit(undefined);
    }

    const addToWarehouse=(data) => {
      
        console.log(data);
            AxiosAuthPost(
                "product/AddToWarehouse",
                data,
                (response) => {
                    setReload(true);
                    store.addNotification({
                        title: "Produkt",
                        message: "Produkt został dodany do magazynu",
                        type: "success",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                },(error) => {
                    // handle error

                    console.log(error);
                    store.addNotification({
                        title: "Błąd!",
                        message: error.message,
                        type: "danger",
                        insert: "top",
                        container: "top-full",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                    });
                });
       
        setShowAddToWarehouse(undefined);
    }

    const actionsFormatter = (cell,row) =>
   
        <td className="align-middle text-right">
           
                <UncontrolledButtonDropdown>
                   <DropdownToggle color="link" className={` text-decoration-none  `}>
                    <i className="fa fa-gear"></i><i className="fa fa-angle-down ml-2"></i>
                </DropdownToggle> 
                <DropdownMenu right>
                   <DropdownItem onClick={() => showEditModal(row)}>
                        <i className="fa fa-fw fa-edit mr-2"></i>
                        Edycja {row.idCode}
                    </DropdownItem>
                    {CheckRolesComponentName(user.role,'product-delete') ?
                        <DropdownItem onClick={() => {
                            if (window.confirm('Jesteś pewien, że chcesz usunąć produkt?')) {
                                showDeleteModal(row)
                            } else {
                                // Do nothing!
                            }
                        }
                        }>
                        <i className="fa fa-fw fa-trash mr-2"></i>
                        Usuwanie {row.idCode}
                    </DropdownItem> : <div></div>}
                    {CheckRolesComponentName(user.role,'product-add-to-storage') ?  <DropdownItem onClick={() => setShowAddToWarehouse({
                        productId:row.id,
                        warehouseId: 0,
                        count: 0,
                        expirationDate: new Date(),
                        userName: userName
                    })}>
                        <i className="fa fa-fw fa-trash mr-2"></i>
                        Dodaj do magazynu
                    </DropdownItem>: <div></div>}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </td> 
        
        {/*<div className="ButtonInRow" style={{textAlign: 'center'}}>*/}
        {/*    <FaEdit size={20} color={"black"} data-tip data-for={`cessionE_${row.id}`} style={{cursor: "pointer"}} onClick={() => showEditModal(row)} />*/}
        {/*    <ReactTooltip id={`cessionE_${row.id}`} type="dark" place="right">*/}
        {/*        <span>Edytuj</span>*/}
        {/*    </ReactTooltip>*/}
        {/*    <FaTrash size={20} color={"black"} data-tip data-for={`cessionD_${row.id}`} style={{cursor: "pointer"}} onClick={() => showDeleteModal(row)} />*/}
        {/*    <ReactTooltip id={`cessionD_${row.id}`} type="dark" place="right">*/}
        {/*        <span>Usuń</span>*/}
        {/*    </ReactTooltip>*/}
        {/*</div>;*/}

    const sortCaret = (order) => {
        if (!order)
            return <i className="fa fa-fw fa-sort text-muted"></i>;
        if (order)
            return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
    };



    const columnDefs = [
        // {
        //     dataField: 'qrCodeImage',
        //     text: 'Photo',
        //     formatter: (cell) => (
        //         <Avatar.Image src={ `data:image/jpeg;base64,${cell}` } />
        //     ),
        // }, 

        {
            dataField: 'idCode',
            text: 'Kod id',
            sort: true,
            sortCaret
        }, {
            dataField: 'name',
            text: 'Nazwa',
            sort: true,
            sortCaret
        }, {
            dataField: 'nameEng',
            text: 'Nazwa Eng.',
            sort: true,
            sortCaret
        },
        {
            dataField: 'description',
            text: 'Opis',
            sort: true,
            sortCaret
        },
        {
            dataField: 'descriptionEng',
            text: 'Opis Eng.',
            sort: true,
            sortCaret
        },
        {
            dataField: 'brand',
            text: 'Marka',
            sort: true,
            sortCaret
        },
        {
            dataField: 'type',
            text: 'Typ',
            sort: true,
            sortCaret
        },
        {
            dataField: 'subtype',
            text: 'Podtyp',
            sort: true,
            sortCaret
        },
        {
            dataField: 'count',
            text: 'Ilość',
            sort: true,
            sortCaret
        },
        {
            dataField: 'oldestExpirationDate',
            text: 'Data ważności',
            sort: true,
            sortCaret
        },
        {
            dataField: 'price',
            text: 'Cena',
            sort: true,
            sortCaret
        },
        {
            dataField: 'priceForProfessionals',
            text: 'Cena dla pro',
            sort: true,
            sortCaret
        },
        {
            dataField: 'action',
            text: '',
            isDummyField: true,
            headerStyle: () => {
                return { width: '50px' }
            },
            formatter: actionsFormatter,
            csvExport: false
        }
       
    ];

    const expandRow = {
        renderer: row => (
            <div>
                    <ProductOnWarehouseTable data={row.productOnWarehousePlacesView} product={row}/>
            </div>

        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => isAnyExpands ? (
            <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
        ) : (
            <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
        ),
        expandColumnRenderer: ({ expanded }) =>
            expanded ? (
                <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
            ) : (
                <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
            )
    }

    return (
        <div>
            <ToolkitProvider
                keyField="id"
                data={ result }
                columns={ columnDefs }
                search
                exportCSV
            >
                {
                    props => (
                        <React.Fragment>
                            <div className="d-flex justify-content-end align-items-center mb-2">
                                <h6 className="my-0">
                                    Produkty
                                </h6>
                                <div className="d-flex ml-auto">
                                    <CustomSearch
                                        className="mr-2"
                                        { ...props.searchProps }
                                    />
                                    <ButtonGroup>
                                        <CustomExportCSV
                                            { ...props.csvProps }
                                        >
                                            Export
                                        </CustomExportCSV>
                                        <Button
                                            // id="modalDefault101"
                                            size="sm"
                                            outline
                                            onClick={ ()=> insert() }
                                        >
                                            Dodaj <i className="fa fa-fw fa-plus"></i>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                            <BootstrapTable
                                classes="table-responsive-lg"
                                bordered={ false }
                                responsive
                                hover
                                expandRow={ expandRow }
                                { ...props.baseProps }
                            />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>

            {/*<Button id="modalDefault101" color="secondary" outline>*/}
            {/*    See Modal  <i className="fa fa-angle-right ml-1"></i>*/}
            {/*</Button>*/}
            { /* START Example Modal */}
            {dataInEdit !== undefined ? <EditProduct
                save={
                    (data) => {
                        save(data)
                    }
                }
                data={dataInEdit}
                cancel={()=>{
                    setDataInEdit(undefined);
                }}

            /> : null}

            {showAddToWarehouse !== undefined ? <AddToWarehouse
                save={
                    (data) => {
                        addToWarehouse(data);
                    }
                }
                data={
                    showAddToWarehouse
                }
                cancel={()=>{
                    setShowAddToWarehouse(undefined);
                }}

            /> : null}

        </div>
    )
}

export default ProductTable;
