import React from "react";

import {useEditFormDialogHooks} from "../../Helper";
//import {Modal,ModalHeader,ModalBody,Container,Row,Col,FormGroup,Label,Input,ModalFooter} from "reactstrap";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter
} from "../../appp/components";
import {Button} from "reactstrap";




const EditWarehouse = (props) => {
    
    
    const [
        dataInEdit,
        handleSubmit,
        onDialogInputChange,
    ] = useEditFormDialogHooks(props.data);


    const save = (event) => {
        event.preventDefault();
        
        props.save(dataInEdit);
    };
    
  
  

   //  useEffect(()=>{
   //     console.log(props);
   //      if(props.data) {
   //          console.log(props.data,"dddd",data);
   //
   //         const dat = Object.assign({}, props.data);
   //         setData(dat);
   //     }
   // },[props.data])
    
    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Miejsce w magazynie
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>
                       
                        
                        <FormGroup row>
                            <Label for="idCode" sm={3}>
                                Kod id
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="idCode"
                                    id="idCode"
                                    placeholder="Kod Id"
                                    value={dataInEdit.idCode}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="rack" sm={3}>
                                Regał
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="rack"
                                    id="rack"
                                    placeholder=""
                                    value={dataInEdit.rack}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="shelf" sm={3}>
                                Półka
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="shelf"
                                    id="shelf"
                                    placeholder=""
                                    value={dataInEdit.shelf}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="place" sm={3}>
                                Miejsce
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="place"
                                    id="place"
                                    placeholder=""
                                    value={dataInEdit.place}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="comment" sm={3}>
                                Uwagi
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type=""
                                    name="comment"
                                    id="comment"
                                    placeholder=""
                                    value={dataInEdit.comment}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="width" sm={3}>
                                Szerokość
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="width"
                                    id="width"
                                    placeholder=""
                                    value={dataInEdit.width}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="height" sm={3}>
                                Wysokość
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="height"
                                    id="height"
                                    placeholder=""
                                    value={dataInEdit.height}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="depth" sm={3}>
                                Głębokość
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="depth"
                                    id="depth"
                                    placeholder=""
                                    value={dataInEdit.depth}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={save}>
                    Zapisz
                </Button>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Anuluj
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditWarehouse;