import React, {useState} from "react";
import {Container, Modal, ModalBody} from "reactstrap";
import {Box, Form} from "devextreme-react";
import {ButtonItem, GroupItem, Label, SimpleItem} from "devextreme-react/form";
import ButtonAdd from "../../Base/Buttons/buttonAdd";
import {Item} from "devextreme-react/box";



export const AddProductToOrderComponent = (props) => {

    const data= {count:0};

    const AddProduct=(data)=>{
        if(data.count>0) {
            const newData = props.data;
            newData.productCount = data.count;
            props.addProduct(newData);
        }
        
    }

    return (
                <React.Fragment>
                    <Box
                        direction="row"
                        width="150"
                        height="100%"
                    >
                        <Item ratio={1}>
                        <Form formData={data} colCount={1} labelMode='floating'>
                            <GroupItem caption= {"Dodaj produkt "+props.data.idCode}>
                                <SimpleItem 
                                    dataField="count"
                                    editorType="dxNumberBox"
                                    editorOptions={{min:1,max:props.data.availableCount}}
                                    isRequired={true} >
                                    <Label text='Ilość'/>
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    <ButtonAdd onClick={() => AddProduct(data)}/>
                        </Item>
                    </Box>
                </React.Fragment>
    )
}