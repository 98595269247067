export const ORDER_STATUS_CREATED ="Utworzone";
export const ORDER_STATUS_ACCEPTED ="Zaakceptowane";
export const ORDER_STATUS_COMPLETING ="Kompletowane";
export const ORDER_STATUS_PACKED="Spakowane";
export const ORDER_STATUS_PREPARED_TO_DELIVERY ="Do wysyłki";
export const ORDER_STATUS_SENT ="Wysłane";
export const ORDER_STATUS_RETURNING ="Zwracane";
export const ORDER_STATUS_CANCELED ="Anulowane";
export const ORDER_STATUS_RETURNED ="Zwrot";
export const ORDER_STATUS_OTHER_DELIVERED ="Zewnętrzne";
export const ORDER_STATUS_OTHER_PRE_CREATED ="Rozpoczęte";
export const ORDER_STATUS_OTHER_TO_ACCEPT ="Do akceptacji";


export const OrderStatusEnum = [
    { id: 0, desc: ORDER_STATUS_CREATED },
    { id: 1, desc: ORDER_STATUS_ACCEPTED },
    { id: 2, desc: ORDER_STATUS_COMPLETING },
    { id: 3, desc: ORDER_STATUS_PACKED },
    { id: 4, desc: ORDER_STATUS_PREPARED_TO_DELIVERY },
    { id: 5, desc: ORDER_STATUS_SENT },
    { id: 6, desc: ORDER_STATUS_RETURNING },
    { id: 7, desc: ORDER_STATUS_CANCELED },
    { id: 8, desc: ORDER_STATUS_RETURNED },
    { id: 9, desc: ORDER_STATUS_OTHER_DELIVERED },
    { id: 10, desc: ORDER_STATUS_OTHER_PRE_CREATED },
    { id: 11, desc: ORDER_STATUS_OTHER_TO_ACCEPT },

];






