import {DataGrid, Form} from "devextreme-react";
import {GroupItem, Label, RequiredRule, SimpleItem} from "devextreme-react/form";
import dxTextArea from "devextreme/ui/text_area";
import {Editing, Summary, TotalItem} from "devextreme-react/data-grid";
import React, {useEffect, useRef, useState} from "react";
import {OrderDetailProductsColumnsDef} from "../../../ColumnsDef/Orders/orderDetailProductsColumnsDef";
import {ProductsOnOrderColumnsDef} from "../../../ColumnsDef/Products/productsOnOrderColumnsDef";
import {dataGridRefresh} from "../../Base/dxGridHellper";
import {Button} from "devextreme-react/button";
import ButtonSave from "../../Base/Buttons/buttonSave";
import ButtonCancel from "../../Base/Buttons/buttonCancel";
import {Messages} from "../../../appp/routes/components/Dropdowns/Messages";
import {ShowErrorNotification, ShowInfoNotification} from "../../../notifications";

const deliveryAddressDisplayExpr= function(item) {
    return item && item.name + ' '+ item.street+ ' '+ item.number + ' '+ item.localNumber + ' ' +item.city;
}

let startDate=new Date()
startDate.setDate(startDate.getDate() + 7);

const defaultData={
    discount:0,
    vat: 23,
    bonus: 0,
    invoicePaymentDate: startDate,
    isDpd: true,
    isInvoice: true,
}


export default function NewOrderAgentComponent (props) {

    const [dataCompany,setDataCompany]=useState({...props.data.company})
    const [dataProducts,setDataProducts]=useState([])
    const [data,setData]=useState({...defaultData})
    const [isInvoiceNumberVisible,setIsInvoiceNumberVisible]=useState(false)
    const [packageWeight,setPackageWeight]=useState(0);

    const productsDataGridRef = useRef();
    


    const bonusOptions = {
        min: 0,
        max: 100,
        onValueChanged: (e) => {
            CalculationsProductValues(e);
        },
    }

    const CalculationsProductValues = (e) => {
        props.data.listOfProduct.map((op, i) => {
            console.log(op.type,dataCompany.discountOfTypeOnProduct,op,dataCompany);
            let productDiscount=
                (dataCompany.discountOfTypeOnProduct!==null &&
                    dataCompany.discountOfTypeOnProduct.filter(e => e.type === op.type).length > 0
                ) ? dataCompany.discountOfTypeOnProduct.find(e => e.type === op.type).discount : dataCompany.discountPercent;

            if(dataCompany.representative!==null && dataCompany.representative.isGroupInvoice)
            {
                productDiscount=
                    (dataCompany.representative.discountOfTypeOnProduct!==null &&
                        dataCompany.representative.discountOfTypeOnProduct.filter(x => x.type === e.oldData.type).length > 0
                    ) ? dataCompany.representative.discountOfTypeOnProduct.find(x => x.type === e.oldData.type).discount : dataCompany.representative.discountPercent;

            }
            const bonus = (e === undefined) ? data.bonus : e.value;
            if (op.vat === undefined) op.vat = data.vat;
            if (e !== undefined || op.newPrice === undefined) {
                op.discount=productDiscount + bonus;
                op.newPrice = (dataCompany.isProfessional ? op.priceForProfessionals : op.price);
                if (op.vat === 0) {
                    op.newPrice = Number(((op.newPrice / 1.23) * 100 / 100).toFixed(2));
                }
                op.newPrice = Number((op.newPrice - Number((op.newPrice * (productDiscount + bonus) / 100).toFixed(2))).toFixed(2));
            }
            // op.fullPrice = Number(((op.newPrice * op.productCount) * 100 / 100).toFixed(2));

        })
        if (productsDataGridRef.current !== undefined)
            dataGridRefresh(productsDataGridRef);
    }

    const onProductOnOrderRowUpdating = (e) => {
        console.log(e)
        if ((e.oldData.vat === 0 && e.newData.vat === 23)
            || (e.oldData.vat===23 && e.newData.vat===0))
        {
            const productDiscount=
                (dataCompany.discountOfTypeOnProduct!==null &&
                    dataCompany.discountOfTypeOnProduct.filter(x => x.type === e.oldData.type).length > 0
                ) ? dataCompany.discountOfTypeOnProduct.find(x => x.type === e.oldData.type).discount : dataCompany.discountPercent;


            e.oldData.newPrice = (dataCompany.isProfessional ? e.oldData.priceForProfessionals : e.oldData.price);

            if (e.newData.vat === 0) {
                e.oldData.newPrice = Number(((e.oldData.newPrice / 1.23) * 100 / 100).toFixed(2));
            }
            e.oldData.newPrice = Number((e.oldData.newPrice - Number((e.oldData.newPrice * (productDiscount + data.bonus) / 100).toFixed(2))).toFixed(2));
        }
        if(e.newData.newPrice===0)
        {
            //   e.oldData.fullPrice=0;
            e.oldData.discount=0;
        }
        else if(e.newData.newPrice)
        {
            //   e.oldData.fullPrice=Number((e.newData.newPrice*e.oldData.productCount).toFixed(2));
            e.oldData.discount=0;
        }
        else {
            //   e.oldData.fullPrice=Number((e.oldData.newPrice*e.oldData.productCount).toFixed(2));
        }
    }

    useEffect(()=>{

        CalculationsProductValues();

    },[props.data.listOfProduct])



    const onSave=()=>{

        if(data.deliveryAddressId===undefined)
        {
            ShowErrorNotification("Zamowienie", "Wybier adres dostawy");
        }
        else{
            let packageWight=0;

            props.data.listOfProduct.map((op, i) => {
                packageWight+=op.weight*op.productCount;

            });

        
            data.isInvoice=false;
            
            data.companyId=dataCompany.id;
            data.packageWeight=(packageWight / 1000).toFixed(0);
            props.onSave(data);
        }

    }

    return (
        <React.Fragment>
            <h3 className={'content-block'}>Zamówienie</h3>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form formData={props.data.companyView} colCount={2} labelMode='floating' readOnly={true} >
                    <SimpleItem dataField="representativeName">
                        <Label text='Przedstawiciel'/>
                    </SimpleItem>
                    <SimpleItem dataField="nip">
                        <Label text='NIP'/>
                    </SimpleItem>
                    <SimpleItem dataField="name">
                        <Label text='Nazwa'/>
                    </SimpleItem>
                    <SimpleItem dataField="address" editorType={dxTextArea}>
                        <Label text='Adres'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactFirstName">
                        <Label text='Kontakt - Imię'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactLastName">
                        <Label text='Kontakt - Nazwisko'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactPhoneNumbers">
                        <Label text='Telefon'/>
                    </SimpleItem>
                    <SimpleItem dataField="contactEmail">
                        <Label text='E-mail'/>
                    </SimpleItem>
                </Form>
                <Form formData={dataCompany} labelMode='floating' readOnly={true} colCount={2}>
                    <SimpleItem dataField="discountPercent">
                        <Label text='Zniżka'/>
                    </SimpleItem>
                    <SimpleItem dataField="isProfessional" >
                        <Label text='Czy profesionalista'/>
                    </SimpleItem>
                </Form>
                <Form formData={data} labelMode='floating' colCount={1}
                      showColonAfterLabel={true}
                      showValidationSummary={true}
                      validationGroup="orderData"
                >
                    <GroupItem caption="Ustawienia" colCount={2}>
                        <SimpleItem
                            dataField="deliveryAddressId"
                            editorType="dxSelectBox"
                            editorOptions={{
                                // value:  dataCompany.deliveryAddresses[0].id,
                                items: dataCompany.deliveryAddresses,
                                searchEnabled: false,
                                displayExpr:deliveryAddressDisplayExpr,
                                valueExpr:"id"}}
                        >
                            <Label text='Adres dostawy'/>
                            <RequiredRule
                                message="Wybierz adres dostawy"
                            />
                        </SimpleItem>
                        <SimpleItem dataField="bonus"
                                    editorOptions={bonusOptions}
                        >
                            <Label text='Dodatkowa zniżka'/>
                        </SimpleItem>
                        <SimpleItem dataField="comment" editorType={dxTextArea}>
                            <Label text='Uwagi'/>
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="Produkty">
                        <DataGrid
                            dataSource={props.data.listOfProduct}
                            allowColumnReordering={false}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            columnAutoWidth={true}
                            columns={ProductsOnOrderColumnsDef}
                            onRowUpdating={onProductOnOrderRowUpdating}
                            ref={productsDataGridRef}
                        >
                            <Summary>
                                <TotalItem
                                    column="fullPrice"
                                    summaryType="sum"
                                />
                                <TotalItem
                                    column="weight"
                                    summaryType="sum"
                                />
                            </Summary>
                            <Editing
                                useIcons={true}
                                mode="row"
                                allowUpdating={true}
                                allowDeleting={true}
                            />
                        </DataGrid>
                    </GroupItem>
                </Form>
                <ButtonSave onClick={onSave}
                >
                </ButtonSave>
                <ButtonCancel onClick={()=>props.onClose()} />

            </div>
        </React.Fragment>
    )
}