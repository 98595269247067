import {AxiosAuthPostBlob, useGetApiDataAuth} from "../../Helper";
import React, {useState} from "react";
import {
    Col, CustomInput,
    FormGroup,
    Input,
    InputGroup,
    Label
} from "../../appp/components";

import {UserContext} from "../../Users/UserContextProvider";
import '@progress/kendo-theme-default/dist/all.css';
import esMessages from "./../../pl-PL.json";
import {loadMessages} from "@progress/kendo-react-intl";
import {useGetCompanyRepresentative} from "../../Query/Company/useGetCompanyRepresentative";

loadMessages(esMessages, "pl-PL");


function GroupInvoice () {

    const [userName, setUserName] = useState(null);
    const [user, setUser] = React.useContext(UserContext);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState(-1);

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative] = useGetCompanyRepresentative();

    const receiveDataCompany = (response) => {
        console.log(response.data);
        setCompany(response.data);
    };

    const [loadingCompany, errorCompany, setReloadCompany] = useGetApiDataAuth(
        "company/GetCompanyRepresentativeNames",
        receiveDataCompany
    );

    const getGroupInvoice=(data)=>{
        AxiosAuthPostBlob(
            "Order/CreateGroupInvoice",
            {fromDate: dateFrom,toDate: dateTo,companyId: companyId
            },
            (response) => {
                const filename = 'faktura.pdf'
                const blob= response.data;

                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();


            },(error) => {
                // handle error

                console.log(error);

            });
    }
    

    return (
        <div>
            <div>
                <FormGroup row>
                    <Label for="dateFrom" sm={3}>
                        Data - od
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="date"
                            name="dateFrom"
                            id="dateFrom"
                            placeholder=""
                            value={dateFrom}
                            onChange={(value)=>
                            {
                                setDateFrom(value.target.value)
                            }}

                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="dateTo" sm={3}>
                        Data - do
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="date"
                            name="dateTo"
                            id="dateTo"
                            placeholder=""
                            value={dateTo}
                            onChange={(value)=>
                            {
                                setDateTo(value.target.value)
                            }}

                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="companyId" sm={3}>
                        Firma
                    </Label>
                    <Col sm={9}>
                        <InputGroup>
                            <CustomInput type="select" id="companyId" name="companyId"  value={companyId} onChange={
                                (event) => {
                                    setCompanyId(event.target.value)}
                            } >
                                {companyRepresentative.map(option => {
                                    return (<option key={option.id} value={option.id}>{option.name}</option>);
                                })}

                            </CustomInput>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <button
                    title="Faktura Grupowa"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={getGroupInvoice}
                >
                    Faktura grupowa
                </button>
            </div>
            <div>
            </div>
        </div>

    )
}

export default GroupInvoice;
