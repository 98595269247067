import CustomStore from "devextreme/data/custom_store";
import authService from "../components/api-authorization/AuthorizeService";
import Axios from "axios";

export const DxStoreCreate = (keyId,apiUrl) =>
    {
        return new CustomStore({
            key: keyId,
            load(loadOptions) {
                return authService.getAccessToken().then((token) => {
                    return Axios.post(apiUrl , loadOptions, {
                        headers: !token ? {} : { Authorization: `Bearer ${token}` },
                    })
                        .then((data) =>  {
                            return {
                            data: data.data.data,
                            totalCount: data.data.totalCount,
                            summary: data.data.summary,
                            groupCount: data.data.groupCount,
                        }})
                })
            }});
}

export const DxStoreWithIdCreate = (keyId,apiUrl,id) =>
{
    return new CustomStore({
        key: keyId,
        load(loadOptions) {
            return authService.getAccessToken().then((token) => {
                return Axios.post(apiUrl , {loadOptions,id}, {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` },
                })
                    .then((data) =>  {
                        return {
                            data: data.data.data,
                            totalCount: data.data.totalCount,
                            summary: data.data.summary,
                            groupCount: data.data.groupCount,
                        }})
            })
        }});
}