import React, {useState} from "react";

import {useEditFormDialogHooks, useGetApiDataAuth} from "../../Helper";
//import {Modal,ModalHeader,ModalBody,Container,Row,Col,FormGroup,Label,Input,ModalFooter} from "reactstrap";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    ModalFooter
} from "../../appp/components";
import {Button} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {CustomSearch} from "../../appp/routes/Tables/ExtendedTable/components";
import BootstrapTable from "react-bootstrap-table-next";


const SelectWarehouse = (props) => {


    const [
        dataInEdit,
        handleSubmit,
        onDialogInputChange,
    ] = useEditFormDialogHooks(props.data);

    const [result, setResult] = useState([]);


    const receiveData = (response) => {
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "warehouseplace/Get",
        receiveData
    );


    const save = (event) => {
        event.preventDefault();

        props.save(dataInEdit);
    };


    const sortCaret = (order) => {
        if (!order)
            return <i className="fa fa-fw fa-sort text-muted"></i>;
        if (order)
            return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
    };

    const actionsFormatter = (cell,row) =>
        <td className="align-middle text-right">
            <i className="fa fa-arrow-circle-right fa-2x" onClick={() =>  props.onSelect(row)}></i>
        </td>
    
    const columnDefs = [
        {
            dataField: 'action',
            text: '',
            isDummyField: true,
            headerStyle: () => {
                return { width: '50px' }
            },
            formatter: actionsFormatter,
            csvExport: false
        },
        {
            dataField: 'idCode',
            text: 'Kod id',
            sort: true,
            sortCaret
        }, {
            dataField: 'rack',
            text: 'Regał',
            sort: true,
            sortCaret
        }, {
            dataField: 'shelf',
            text: 'Półka',
            sort: true,
            sortCaret
        },
        {
            dataField: 'place',
            text: 'Miejsce',
            sort: true,
            sortCaret
        },
        {
            dataField: 'comment',
            text: 'Uwagi',
            sort: true,
            sortCaret
        },
       
    ];
    

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Wybierz magazyn
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <ToolkitProvider
                            keyField="id"
                            data={ props.result }
                            columns={ columnDefs }
                            search
                        >
                            {
                                props => (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-end align-items-center mb-2">
                                            <h6 className="my-0">
                                                Miejsca w magazynie
                                            </h6>
                                            <div className="d-flex ml-auto">
                                                <CustomSearch
                                                    className="mr-2"
                                                    { ...props.searchProps }
                                                />
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            classes="table-responsive-lg"
                                            bordered={ false }
                                            responsive
                                            hover
                                            { ...props.baseProps }
                                        />
                                    </React.Fragment>
                                )
                            }
                        </ToolkitProvider>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Zamknij
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SelectWarehouse;