import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithFileDownloadQuery} from "../defaultPostWithFileDownloadQuery";

const GET_PACKAGE_PROTOCOL_PDF_URL = 'Document/GetPackageProtocolPdf'

export const GetPackageProtocolPdfQuery = (data, onResponse) => {
    return DefaultPostWithFileDownloadQuery(
        GET_PACKAGE_PROTOCOL_PDF_URL,
        ConstantsTitles.DOCUMENT_TITLE,
        ConstantsMessages.DOCUMENT_GET_PACKAGE_PROTOCOL_PDF,
        GetPackageProtocolPdfQuery.name,
        'Protokol.pdf',
        data,
        onResponse
    )
}
    
    
   