import React, {useCallback, useEffect, useState} from "react";

import {CheckIfRoleForComponentName} from "../../Users/roleHelpers";
import SelectCompanyComponent from "../Managment/Company/selectCompanyComponent";
import {GetCompanyDetailsQuery} from "../../Query/Company/getCompanyDetailsQuery";
import AvailableProductsComponent from "./availableProductsComponent";
import NewOrderComponent from "./DetailsComponents/newOrderComponent";
import {CompanyNewModel} from "../../Models/companyNewModel";
import {AddNewCompanyQuery} from "../../Query/Company/addNewCompanyQuery";
import {EditCompanyQuery} from "../../Query/Company/editCompanyQuery";
import {EditCompanyComponent} from "../Managment/Company/editCompanyComponent";
import {useGetCompanyRepresentative} from "../../Query/Company/useGetCompanyRepresentative";
import {Button} from "devextreme-react/button";
import {CreateOrderQuery} from "../../Query/Orders/createOrderQuery";
import {CreateOrderWithInvoiceQuery} from "../../Query/Orders/createOrderWithInvoiceQuery";
import {GetPackagePdfQuery} from "../../Query/Documents/getPackagePdfQuery";
import {AddProductToOrderComponent} from "./DetailsComponents/addProductToOrderComponent";
import NewOrderComponent2 from "./DetailsComponents/newOrderComponent2";
import OrderDetailInfoComponent from "../Orders/DetailsComponents/orderDetailInfoComponent";
import OrderDetailEditableComponent from "../Orders/DetailsComponents/orderDetailEditableComponent";
import {GetOrderDetailsQuery} from "../../Query/Orders/getOrderDetailQuery";
import ConstantsMessages from "../../Consts/messages";
import {CancelOrderQuery} from "../../Query/Orders/cancelOrderQuery";
import {dataGridRefresh} from "../Base/dxGridHellper";
import {AcceptOrderQuery} from "../../Query/Orders/acceptOrderQuery";
import NewOrderAgentComponent from "./DetailsComponents/newOrderAgentComponent";
import {CreateOrderAgentQuery} from "../../Query/Orders/createOrderAgentQuery";

const startOrder={
    listOfProduct: [],
    company : undefined,
    number:  undefined // '20210112321'
}

export default function CreateOrderAgentComponent (props) {
    const [selectedCompany, setSelectedCompany] = useState(props.company);
    const [listOfProduct, setListOfProduct] = useState([]);
    const [isFromCompany,setIsFromCompany]=useState(props.isFromCompany);
    const [companyToEdit, setCompanyToEdit] = useState(undefined);

    const [companyRepresentativeLoading, companyRepresentativeError, setCompanyRepresentativeReload, companyRepresentative] = useGetCompanyRepresentative();


    const [selectedOrder,setSelectedOrder]= useState(undefined);
    const [orderNextStep,setOrderNextStep]= useState(undefined);


    useEffect(()=>{
        setListOfProduct([]);
        setSelectedOrder(undefined);
        setSelectedCompany(undefined);
        setCompanyToEdit(undefined);
        setOrderNextStep(undefined);
    },[])

    const onCompanySelect=(data)=>
    {
        GetCompanyDetailsQuery(data.id,(response)=>{

            setSelectedCompany(response.data);
            const newOrder={...startOrder};
            newOrder.listOfProduct=[];
            newOrder.company=response.data;
            newOrder.companyView=data;
            setSelectedOrder(newOrder);
            console.log('onCompanySelect',newOrder);
        });

    }

    const addProduct=(data) =>{
        const newList = JSON.parse(JSON.stringify(listOfProduct))
        newList.push(data);
        const nl=JSON.parse(JSON.stringify(newList));

        selectedOrder.listOfProduct=nl;
        //setSelectedOrder(selectedOrder);
        console.log(nl);
        console.log('addProduct',selectedOrder);
        setListOfProduct(nl);


    }

    const cancelSelectedOrder=(data)=> {
        if (window.confirm(ConstantsMessages.ORDER_CANCEL_ORDER_CONFIRM)) {
            CancelOrderQuery(data, () => {
                cancel();
            });
        }
    }

    

    const cancel=()=>{
        // if(!isFromCompany) {
        //     setListOfProduct([]);
        //     setSelectedOrder(undefined);
        //     setSelectedCompany(undefined);
        // }
        // else {
        //     setListOfProduct([]);
        //     startOrder.listOfProduct=[];
        //     startOrder.company=props.company;
        //     setSelectedOrder(startOrder);
        // }

        setListOfProduct([]);
        setSelectedOrder(undefined);
        setSelectedCompany(undefined);
        setOrderNextStep(undefined);
    }

    const onCompanyInsert = useCallback( () => {
        setCompanyToEdit({...CompanyNewModel});
    },[]);

    const onCompanySave=useCallback((data) => {
        if(data.id===0)
        {
            AddNewCompanyQuery(data,()=>{
                cancel();
            });
        }
        else {
            EditCompanyQuery(data,()=>{
                // dataGridRefresh(companiesDataGridRef);
                cancel();
            });
        }
        setCompanyToEdit(undefined);
    },[]);

    const onOrderSave=(data)=>{
        const prod = listOfProduct.map((op, i) => {
            return {
                productId: op.id,
                count: op.productCount,
                discount: op.discount,
                vat: op.vat,
                pricePln: op.newPrice,
            }
        });
        const dat = {
            companyId: data.companyId,
            deliveryAddressId: data.deliveryAddressId,
            products: prod,
            isInvoice: data.isInvoice,
            paymentType: data.invoicePaymentType,
            // invoiceBonus,
            invoicePaymentDate: data.invoicePaymentDate,
            isDpdPackage:data.isDpd,
            invoiceNr: data.invoiceNr,
            comment: data.comment,
            packageWeight:data.packageWeight

        }

        console.log(dat);
       
            CreateOrderAgentQuery(dat,(response)=>{
                const orderId= response.data;
                cancel();
            });
        

    }

    return (
        <React.Fragment>
            {selectedOrder && !orderNextStep ?
                <div>
                    <Button   type={"default"}  width={"100%"}  stylingMode={"contained"} onClick={()=>{setCompanyToEdit(selectedCompany)}} >
                        Edytuj firmę
                    </Button>
                    <NewOrderAgentComponent
                        data={selectedOrder}
                        onSave={onOrderSave}
                        onClose={cancel}
                        editDisable={!CheckIfRoleForComponentName('createOrder-edit-company')}
                    /> </div>: <div/>
            }

            {!selectedCompany && !orderNextStep && companyToEdit === undefined ? <SelectCompanyComponent
                    onInsert={onCompanyInsert}
                    onSelect={onCompanySelect}
                /> :
                companyToEdit  === undefined && !orderNextStep ?
                    <AvailableProductsComponent
                        addProductDetailComponent={AddProductToOrderComponent}
                        addProduct={addProduct}
                        isHidden={isFromCompany}/>
                    : <div/>
            }
            {companyToEdit !== undefined ? <EditCompanyComponent
                save={
                    (data) => {
                        onCompanySave(data)
                    }
                }
                data={companyToEdit}
                companyRepresentative={companyRepresentative}
                cancel={()=>{
                    setCompanyToEdit(undefined);
                }}

            /> : null}
            
        </React.Fragment>

    )
}
