import React, {useState} from "react";

import {useEditFormDialogHooks, useGetApiDataAuth} from "../../Helper";
//import {Modal,ModalHeader,ModalBody,Container,Row,Col,FormGroup,Label,Input,ModalFooter} from "reactstrap";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter
} from "../../appp/components";
import {Button} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {CustomSearch} from "../../appp/routes/Tables/ExtendedTable/components";
import BootstrapTable from "react-bootstrap-table-next";
import {ButtonInput} from "../../appp/routes/Forms/DatePicker/components";
import DatePicker from "react-datepicker";




const PickupWarehouse = (props) => {


    const [
        dataInEdit,
        handleSubmit,
        onDialogInputChange,
    ] = useEditFormDialogHooks(props.data);

    const [result, setResult] = useState([]);


    const receiveData = (response) => {
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "warehouseplace/Get",
        receiveData
    );


    const save = (event) => {
        event.preventDefault();

        props.save(dataInEdit);
    };


    const sortCaret = (order) => {
        if (!order)
            return <i className="fa fa-fw fa-sort text-muted"></i>;
        if (order)
            return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
    };


    const columnDefs = [
        {
            dataField: 'idCode',
            text: 'Kod id',
            sort: true,
            sortCaret
        }, {
            dataField: 'rack',
            text: 'Regał',
            sort: true,
            sortCaret
        }, {
            dataField: 'shelf',
            text: 'Półka',
            sort: true,
            sortCaret
        },
        {
            dataField: 'place',
            text: 'Miejsce',
            sort: true,
            sortCaret
        },
        {
            dataField: 'comment',
            text: 'Uwagi',
            sort: true,
            sortCaret
        }

    ];

    const expandRow = {
        renderer: row => (
            <div>
                <FormGroup row>
                    <Label for="count" sm={3}>
                        Ilość
                    </Label>
                    <Col sm={9}>
                        <Input
                            type="number"
                            name="count"
                            id="count"
                            placeholder=""
                            value={dataInEdit.count}
                            onChange={onDialogInputChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="expirationDate" sm={3}>
                        Data ważności
                    </Label>
                    <Col sm={9}>
                        <DatePicker
                            customInput={ <ButtonInput /> }
                            dateFormat="dd.MM.yyyy"

                            selected={dataInEdit.expirationDate}
                            onChange={(val)=>{
                                onDialogInputChange(
                                    {
                                        target: {
                                            type: "datepicker",
                                            value: val,
                                            name:"expirationDate",
                                        }
                                    })
                            }
                            }

                        />
                        {/*<Input*/}
                        {/*    type="datetime"*/}
                        {/*    name="expirationDate"*/}
                        {/*    id="expirationDate"*/}
                        {/*    placeholder=""*/}
                        {/*    value={dataInEdit.expirationDate}*/}
                        {/*    onChange={onDialogInputChange}*/}
                        {/*/>*/}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Button onClick={
                        ()=> {
                            dataInEdit.warehouseId=row.id;
                            dataInEdit.count=parseInt(dataInEdit.count,10)
                            props.save(dataInEdit)
                        }
                    }>Dodaj</Button>
                </FormGroup>
            </div>

        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => isAnyExpands ? (
            <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
        ) : (
            <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
        ),
        expandColumnRenderer: ({ expanded }) =>
            expanded ? (
                <i className="fa fa-angle-down fa-fw fa-lg text-muted"></i>
            ) : (
                <i className="fa fa-angle-right fa-fw fa-lg text-muted"></i>
            )
    }

    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Dodaj produkt do magazynu
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <ToolkitProvider
                            keyField="id"
                            data={ props.result }
                            columns={ columnDefs }
                            search
                        >
                            {
                                props => (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-end align-items-center mb-2">
                                            <h6 className="my-0">
                                                Miejsca w magazynie
                                            </h6>
                                            <div className="d-flex ml-auto">
                                                <CustomSearch
                                                    className="mr-2"
                                                    { ...props.searchProps }
                                                />
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            classes="table-responsive-lg"
                                            bordered={ false }
                                            responsive
                                            hover
                                            expandRow={ expandRow }
                                            { ...props.baseProps }
                                        />
                                    </React.Fragment>
                                )
                            }
                        </ToolkitProvider>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Zamknij
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default PickupWarehouse;