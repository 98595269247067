export const CompaniesColumnsDef = [
    {
        dataField: "nip",
        caption: "NIP",
        dataType: "string"
    },
    {
        dataField: "name",
        caption: "Nazwa",
        dataType: "string"
    },
    {
        dataField: "address",
        caption: "Adres",
        dataType: "string"
    },
    {
        dataField: "deliveryAddress",
        caption: "Adresy dostawy",
        dataType: "string"
    },
    {
        dataField: "contactFirstName",
        caption: "Imię",
        dataType: "string"
    },
    {
        dataField: "contactLastName",
        caption: "Nazwisko",
        dataType: "string"
    },
    {
        dataField: "contactPhoneNumbers",
        caption: "Telefon",
        dataType: "string"
    },
    {
        dataField: "contactEmail",
        caption: "Email",
        dataType: "string"
    },
    {
        dataField: "comment",
        caption: "Uwagi",
        dataType: "string"
    },
]