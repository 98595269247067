import {Button} from "devextreme-react/button";
import React from "react";

export default function ButtonAdd (props) {

    return (
        <Button type={"success"} onClick={props.onClick}>
            Dodaj
        </Button>
    )
}
