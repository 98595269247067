import React, {useEffect, useRef, useState} from "react";
import {useGetCompany} from "../../../Query/Company/useGetCompany";
import {Item, SearchPanel, Toolbar} from "devextreme-react/data-grid";
import {DataGrid, LoadPanel} from "devextreme-react";
import {CompanyDxStore} from "../../../Query/Company/companyDxStore";
import {CompaniesColumnsDef} from "../../../ColumnsDef/Company/companyColumnsDef";
import {AddButtonsOnBegin, AddButtonsOnEnd} from "../../Base/dxGridHellper";
import {Button} from "devextreme-react/button";

export default function SelectCompanyComponent (props) {

    const [selectCompanyColumns]=useState(
        AddButtonsOnBegin(CompaniesColumnsDef,
            [
                {
                    name:'customSelect',
                    icon:'arrowright',
                    onClick: (e)=> onSelect(e.row.data)
                },
            ])
        )
    
    const onSelect=(data)=>{
        props.onSelect(data);
    }
    
    
    return (
        <div>
            <DataGrid
                dataSource={CompanyDxStore}
                rowAlternationEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
                columns={selectCompanyColumns}
                remoteOperations={true}
            >
                <SearchPanel visible={true}/>
                <Toolbar>
                    {props.onInsert ? <Item location="before">
                        <Button
                            icon='add'
                            onClick={props.onInsert} />
                    </Item> : <div/>}
                    <Item  location="before"
                           name="searchPanel"
                    />
                </Toolbar>
                
            </DataGrid>
        </div>
    )
}