import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../../../../Users/UserContextProvider";

import { 
    Sidebar,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from './../../../components';
import { randomAvatar } from './../../../utilities';
import authService from "../../../../components/api-authorization/AuthorizeService";
import {ApplicationPaths} from "../../../../components/api-authorization/ApiAuthorizationConstants";
import {AxiosAuthPost} from "../../../../Helper";
import {ChangeUserPasswordComponent} from "../../../../components/Managment/Users/changeUserPasswordComponent";

const avatarImg = randomAvatar();


const SidebarTopA = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState(null);
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
    const loginPath = `${ApplicationPaths.Login}`;
    const [user2, setUser] = React.useContext(UserContext);
    const [roleName, setRoleName] = useState(null);
    const [showPasswordChange,setShowPasswordChange]=useState(undefined);
    const [userId, setUserId] = useState(null);
    const [userCorporation, setUserCorporation] = useState('');




    useEffect(async ()=>{
        const load= async ()=> {
            const [isAuthenticated, user,aus] = await Promise.all([authService.isAuthenticated(), authService.getUser(),authService.getAUs()])
           
           setIsAuthenticated(isAuthenticated);
           setUserName(user && user.name);
           console.log(user);
            console.log(aus);

            setUser(user);
            setRoleName(user && user.role);
            setUserId(user && user.sub);

            AxiosAuthPost(
                "user/GetUserCorporation",
                null,
                (response) => {
                    console.log(response);
                    setUserCorporation(response.data);
                    
                },(error) => {
                    // handle error

                    console.log(error);
                });
        }
        load();

    },[])

    const showChangePasswordModal=(row)=>{

        console.log(row);
        setShowPasswordChange({
            userId: row,
            adminChange: false,
            newPassword: "Test123$",
            login: "",
            oldPassword: ""
        });
    }

    const changePassword=(data)=>{

        AxiosAuthPost(
            "user/ChangePassword",
            data,
            (response) => {
                setShowPasswordChange(undefined);
                console.log(response);


                if(response.data.succeeded) {
                    alert("Zmieniono hasło");
                }
                else
                {
                    alert(response.data.errors[0].description);

                    console.log(response.data.errors[0].description);
                }

            },(error) => {
                // handle error

                console.log(error);
            });
    }

    const changeUserCorporation=()=>{

        AxiosAuthPost(
            "user/ChangeUserCorporation",
            null,
            (response) => {
                console.log(response);

                setUserCorporation(response.data);
                window.location.reload();

            },(error) => {
                // handle error

                console.log(error);
            });
    }
                return(
        <React.Fragment>
            { /* START: Sidebar Default */}

            <Sidebar.HideSlim>

                <Sidebar.Section className="pt-0">
                    <Link to="/" className="d-block">
                        {/*<Sidebar.HideSlim>*/}
                            {/*<Avatar.Image*/}
                            {/*    size="lg"*/}
                            {/*    src={avatarImg}*/}
                            {/*    addOns={[*/}
                            {/*        <AvatarAddOn.Icon*/}
                            {/*            className="fa fa-circle"*/}
                            {/*            color="white"*/}
                            {/*            key="avatar-icon-bg"*/}
                            {/*        />,*/}
                            {/*        <AvatarAddOn.Icon*/}
                            {/*            className="fa fa-circle"*/}
                            {/*            color="success"*/}
                            {/*            key="avatar-icon-fg"*/}
                            {/*        />*/}
                            {/*    ]}*/}
                            {/*/>*/}
                        {/*</Sidebar.HideSlim>*/}
                    </Link>

                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="link" className="pl-0 pb-0 btn-profile sidebar__link">
                            {isAuthenticated===true ? userName : "Anonimowy "}
                            {isAuthenticated===true ? " ("+ roleName +")": " "}
                            {" - "+userCorporation}

                            {/*{faker.name.firstName()} {faker.name.lastName()}*/}
                            <i className="fa fa-angle-down ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu persist>
                            <DropdownItem header>
                                {isAuthenticated===true ? userName : "Anonimowy "}

                                {/*{faker.name.firstName()} {faker.name.lastName()}*/}
                            </DropdownItem>
                            <DropdownItem divider/>
                            {/*<DropdownItem tag={ Link } to="/apps/profile-details">*/}
                            {/*    My Profile*/}
                            {/*</DropdownItem>*/}
                            {/*<DropdownItem tag={ Link } to="/apps/settings-edit">*/}
                            {/*    Settings*/}
                            {/*</DropdownItem>*/}
                            {/*<DropdownItem tag={ Link } to="/apps/billing-edit">*/}
                            {/*    Billings*/}
                            {/*</DropdownItem>*/}
                            <DropdownItem divider/>
                            {isAuthenticated===true ?
                            <DropdownItem tag={Link} to={logoutPath}>
                                <i className="fa fa-fw fa-sign-out mr-2"></i>
                                Wyloguj
                            </DropdownItem>
                                :  
                                <DropdownItem tag={Link} to={loginPath}>
                                    <i className="fa fa-fw fa-sign-out mr-2"></i>
                                    Zaloguj
                                </DropdownItem> }
                            {userId !==null ? <DropdownItem onClick={() => showChangePasswordModal(userId)}>
                                <i className="fa fa-fw fa-trash mr-2"></i>
                                Zmiana hasła
                            </DropdownItem> : ''}
                            {userId !==null ? <DropdownItem onClick={() => changeUserCorporation()}>
                                <i className="fa fa-fw fa-building-o mr-2"></i>
                                Zmień firmę
                            </DropdownItem> : ''}
                           
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    {/*<div className="small sidebar__link--muted">*/}
                    {/*    { faker.name.jobTitle() }*/}
                    {/*</div>*/}
                </Sidebar.Section>
            </Sidebar.HideSlim>
            { /* END: Sidebar Default */}

            { /* START: Sidebar Slim */}
            <Sidebar.ShowSlim>
                <Sidebar.Section>
                    {/*<Avatar.Image*/}
                    {/*    size="sm"*/}
                    {/*    src={avatarImg}*/}
                    {/*    addOns={[*/}
                    {/*        <AvatarAddOn.Icon*/}
                    {/*            className="fa fa-circle"*/}
                    {/*            color="white"*/}
                    {/*            key="avatar-icon-bg"*/}
                    {/*        />,*/}
                    {/*        <AvatarAddOn.Icon*/}
                    {/*            className="fa fa-circle"*/}
                    {/*            color="success"*/}
                    {/*            key="avatar-icon-fg"*/}
                    {/*        />*/}
                    {/*    ]}*/}
                    {/*/>*/}
                </Sidebar.Section>
            </Sidebar.ShowSlim>
            { /* END: Sidebar Slim */}
            {showPasswordChange !== undefined ? <ChangeUserPasswordComponent
                save={
                    (data) => {
                        changePassword(data);
                    }
                }
                data={showPasswordChange}
                cancel={()=>{
                    setShowPasswordChange(undefined);
                }}

            /> : null}
        </React.Fragment>
    )
}
     

export { SidebarTopA };
