import React, {useEffect, useState} from "react";
import {Button} from "devextreme-react/button";
import {Box, Form} from "devextreme-react";
import {Item} from "devextreme-react/box";
import OrderDetailInfoComponent from "./orderDetailInfoComponent";
import {ORDER_STATUS_OTHER_PRE_CREATED, ORDER_STATUS_OTHER_TO_ACCEPT} from "../../../Enums/orderStatusEnum";
import OrderDetailEditableComponent from "./orderDetailEditableComponent";
import OrderDetailAgentComponent from "./orderDetailAgentComponent";

export default function OrderExpandedComponent (props)
{
    
    return (
        <React.Fragment>
            <Box
                direction="row"
                width="600"
                height="100%"
            >
                {props.data.orderStatus===ORDER_STATUS_OTHER_PRE_CREATED ?
                    <Item ratio={1}>
                        <OrderDetailEditableComponent data={props.data} onCancel={props.onCancel} getPackagePdf={props.getPackagePdf} acceptSelectedOrder={props.acceptSelectedOrder} />
                    </Item>
                    :
                    props.data.orderStatus===ORDER_STATUS_OTHER_TO_ACCEPT ?
                        <Item ratio={1}>
                            <OrderDetailAgentComponent data={props.data} onCancel={props.onCancel} getPackagePdf={props.getPackagePdf} acceptSelectedOrder={props.acceptSelectedOrder} createInvoice={props.createInvoice} getInvoicePdf={props.getInvoicePdf} />
                        </Item>
                        :
                    <Item ratio={1}>
            <OrderDetailInfoComponent row={props.data} />
                    <div className={'content-block dx-card responsive-paddings'}>
                   <div>
                        {props.data.invoiceNr===null ?
                            <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.createInvoice(props.data)}}>
                                    Utwórz fakturę
                                </Button>
                           :

                            <Button type={"default"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.getInvoicePdf(props.data)}}>
                                    Pobierz fakturę
                                </Button>
                           }
                   </div>
                  <div>

                      <Button type={"success"} width={"100%"} stylingMode={"contained"} onClick={()=>{props.getExistedPackagePdf(props.data)}}>
                                Pobierz list przewozowy
                      </Button>
                  </div>
                    <div>
                      <Button width={"100%"} stylingMode={"contained"} onClick={()=>{props.onCancel(props.data)}}>
                          Zwrot / Anulowanie
                      </Button>
                       
                  </div>
                </div>
                </Item>}
            </Box>
        </React.Fragment>
    )
}