import React from "react";


export const PickupReportColumnsDef = [
    {
        dataField: "idCode",
        caption: "Produkt",
        dataType: "string"
    },
    {
        dataField: "productName",
        caption: "Nazwa produktu",
        dataType: "string"
    },
    {
        dataField: "operationDate",
        caption: "Data operacji",
        dataType: "datetime",
        format: "dd.MM.yyyy, HH:mm",
        sortOrder: "desc"
    },
    {
        dataField: "count",
        caption: "Ilość",
        dataType: "number"
    },
    {
        dataField: "userName",
        caption: "Użytkownik operacji",
        dataType: "string"
    },
    {
        dataField: "warehousePlacesId",
        caption: "Magazyn",
        dataType: "string"
    },
    {
        dataField: "invoiceNr",
        caption: "Faktura",
        dataType: "string"
    }
]
