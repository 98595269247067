import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithFileDownloadQuery} from "../defaultPostWithFileDownloadQuery";

const GET_EXISTED_PACKAGE_PDF_URL = 'Document/GetExistedPackagePdf'

export const GetExistedPackagePdfQuery = (data, onResponse) => {
    return DefaultPostWithFileDownloadQuery(
        GET_EXISTED_PACKAGE_PDF_URL,
        ConstantsTitles.DOCUMENT_TITLE,
        ConstantsMessages.DOCUMENT_GET_EXISTING_PACKAGE_PDF,
        GetExistedPackagePdfQuery.name,
        'Paczka'+data.id+'.pdf',
        {id:data.id},
        onResponse
    )
}
    
    
   