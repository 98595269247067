import {
    Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader, Table
} from "../../appp/components";
import React, {useState} from "react";
import SelectProductRow from "./selectProductRow";
import {Button} from "reactstrap";
import {useGetApiDataAuth} from "../../Helper";




const SelectProductTable = (props)=>
{

    const [result, setResult] = useState([]);


    const receiveData = (response) => {
        setResult(response.data);
    };

    const [loading, error, setReload] = useGetApiDataAuth(
        "warehouseplace/Get",
        receiveData
    );


    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Wybierz Produkt
            </ModalHeader>
            <ModalBody>
        <React.Fragment>

            <Card >
                <CardHeader tag="h6" className="bg-info text-white">
                    {props.warehouse.idCode}
                </CardHeader>
                <CardBody>
                    Regał: {props.warehouse.rack} Półka: {props.warehouse.shelf}  Miejsce: {props.warehouse.place}

                    <Table className="mb-0" responsive>
                        <thead>
                        <tr>
                            <th className="bt-0"></th>
                            <th className="bt-0">Kod id produktu</th>
                            <th className="bt-0">Nazwa produktu</th>
                            <th className="bt-0">Ilość</th>
                            <th className="bt-0">Data ważności</th>
                        </tr>
                        </thead>
                        <tbody>

                        {props.data.map((dat, i) => {

                            return (
                                <tr key={'prw_'+dat.id}>
                                    <SelectProductRow data={dat} onSelect={props.onSelect}  />
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </CardBody>

            </Card>

        </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Zamknij
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SelectProductTable
