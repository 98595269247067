import {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";

const GET_USERS_URL = 'user/GetUsers'

export const useGetUsers = () => {
    const [users, setUsers] = useState([]);

    const receiveData = (response) => {
        setUsers(response.data);
    };

    const [usersLoading, usersError, setUsersReload] = useGetApiDataAuth(
        GET_USERS_URL,
        receiveData
    );

    return [usersLoading, usersError, setUsersReload, users];
};
