import {useState} from "react";
import {useGetApiDataAuth} from "../../Helper";

const GET_COMPANY_NAMES_URL = 'company/GetCompanyNames'

export const useGetCompanyNames = () => {
    const [companyNames, setCompanyNames] = useState([]);

    const receiveData = (response) => {
        setCompanyNames(response.data);
    };

    const [companyNamesLoading, companyNamesError, setCompanyNamesReload] = useGetApiDataAuth(
        GET_COMPANY_NAMES_URL,
        receiveData
    );

    return [companyNamesLoading, companyNamesError, setCompanyNamesReload, companyNames];
};