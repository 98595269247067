import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const CHANGE_ORDER_TO_SEND_URL = 'Order/ChangeToSend'

export const ChangeOrderToSendQuery = (data, onResponse) => {
    return DefaultPostQuery(
        CHANGE_ORDER_TO_SEND_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CHANGE_ORDER_TO_SEND,
        ChangeOrderToSendQuery.name,
        {id:data.id},
        onResponse
    )
}