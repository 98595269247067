import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostWithFileDownloadQuery} from "../defaultPostWithFileDownloadQuery";

const CREATE_ORDER_WITH_INVOICE_URL = 'Order/CreateOrderWithInvoice'

export const CreateOrderWithInvoiceQuery = (data, onResponse) => {
    return DefaultPostWithFileDownloadQuery(
        CREATE_ORDER_WITH_INVOICE_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CREATE_ORDER,
        CreateOrderWithInvoiceQuery.name,
        'Faktura'+data.id+'.pdf',
        data,
        onResponse
    )
}
    
    
   