module.exports = Object.freeze({
    COMPANY_ADDED: "Firma została dodana",
    COMPANY_EDITED: "Firma została zmodyfikowana",
    COMPANY_DELETED: "Firma została usunięta",
    COMPANY_DELETE_CONFIRM: "Jesteś pewien, że chcesz usunąć firmę?",
    
    USER_ADDED: "Użytkownik został dodany",
    USER_EDITED: "Użytkownik został  zmodyfikowany",
    USER_DELETED: "Użytkownik został usunięty",
    USER_CHANGE_PASSWORD: "Hasło zostało zmienione",
    USER_DELETE_CONFIRM: "Jesteś pewien, że chcesz usunąć użytkownika?",

    ORDER_CREATE_ORDER: "Zamówinie zostało złożone",
    ORDER_ACCEPT_ORDER: "Zamówinie zostało zaakceptowane",
    ORDER_CANCEL_ORDER: "Zamówienie zostało anulowane",
    ORDER_CANCEL_ORDER_CONFIRM: "Jesteś pewien, że chcesz anulować zamówienie?",
    ORDER_ACCEPT_ORDER_CONFIRM: "Brak numeru przesyłki, czy chcesz kontynuować?",
    ORDER_COMPLETE_ORDER: "Dodano produkt z zamówienia",
    ORDER_RETURN_ORDER: "Zwrócono produkt z zamówienia",
    ORDER_TO_RETURN_ORDER: "Zwrot zamówienia",
    ORDER_CHANGE_ORDER_TO_SEND: "Zmieniono na wysłane",



    DOCUMENT_CREATE_INVOICE: "Faktura została utworzona",
    DOCUMENT_GET_INVOICE_PDF: "Rozpoczęto pobieranie faktury",
    DOCUMENT_GET_EXISTING_PACKAGE_PDF: "Rozpoczęto pobieranie listu przewozowego",
    DOCUMENT_GET_PACKAGE_PROTOCOL_PDF: "Rozpoczęto pobieranie protokołu przewozowego",




});