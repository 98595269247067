import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const SET_PICKUP_TO_ORDER_URL = 'Order/SetPickupToOtherDeliveredOrder'

export const SetPickupToOrderQuery = (data, onResponse) => {
    return DefaultPostQuery(
        SET_PICKUP_TO_ORDER_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CREATE_ORDER,
        SetPickupToOrderQuery.name,
        data,
        onResponse
    )
}
