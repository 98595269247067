import {Button} from "devextreme-react/button";
import React from "react";

export default function ButtonCancel (props) {

    return (
        <Button  type={"back"} onClick={props.onClick}>
            Anuluj
        </Button>
    )
}