import React, {useState} from "react";
import OrderProductWarehause from "../../OrderRealization/orderProductWarehause";
import PackingProduct from "../../Packing/packingProduct";
import OrderDetailWithoutPriceComponent from "../DetailsComponents/orderDetailWithoutPriceComponent";
import {CompleteOrderQuery} from "../../../Query/Orders/completeOrderQuery";
import {ORDER_STATUS_PACKED} from "../../../Enums/orderStatusEnum";
import ButtonClose from "../../Base/Buttons/buttonClose";

export default function OrderCompletingComponent (props) {
    const [selectedOrder, setSelectedOrder] = useState(props.selectedOrder);
    const [handsSelectedProductName,setHandsSelectedProductName]=useState(undefined);
    const [inProcess, setInProcess] = useState(false);
    
    const reloadData=props.reloadData;

    const completeOrder = (data) => {

        setInProcess(true);
        const dat ={orderId: selectedOrder.id,productOnWarehousePlaceId: data.productOnWarehousePlaceId , count: data.count }
        
        CompleteOrderQuery(dat,()=>{
            setHandsSelectedProductName(undefined);
            reloadData((newData)=>{
                setSelectedOrder(newData);
                setInProcess(false)
                if(newData.orderStatus===ORDER_STATUS_PACKED)
                {
                    props.cancel();
                }
               
            });
          
        });
    };
    
   const closeOrder = () => {

       setInProcess(true);
       const dat ={orderId: selectedOrder.id,productOnWarehousePlaceId: 0 , count: 0 }

       CompleteOrderQuery(dat,()=>{
           setHandsSelectedProductName(undefined);
           props.cancel();

       },false);
   };
  
    
    return (
        <React.Fragment>

            <div className={'content-block dx-card responsive-paddings'}>
            <OrderDetailWithoutPriceComponent row={selectedOrder}
                                                  action={()=>props.cancel()}
                                                  actionName={"Zamknij"}
                                                  removeProduct={(index)=>{
                                                  }}/>
            </div>
            <div className={'content-block dx-card responsive-paddings'}>



            {(!inProcess && !handsSelectedProductName) ? <OrderProductWarehause data={selectedOrder.productToOrderViewModel}
                                                                                    completeOrder={completeOrder}
                                                                                    getDescription="Pobrane"
                                                                                    handsGetDescription="Pobranie ręczne"
                                                                                    onHandleSelected={
                                                                                        (selectProduct) => {
                                                                                            setHandsSelectedProductName(selectProduct.name);
                                                                                        }
                                                                                    }
                    />
                    : !inProcess ? <PackingProduct productName={handsSelectedProductName} completeOrder={completeOrder} onCancel={()=>
                    {
                        setHandsSelectedProductName(undefined);
                    }}/> : <p></p> }
                <ButtonClose onClick={()=>
                    closeOrder()
                    }/>
            </div>

        </React.Fragment>
    )
}