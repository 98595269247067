
import {Button} from "devextreme-react/button";
import React from "react";

export default function ButtonClose (props) {

    return (
        <Button type={"success"} onClick={props.onClick}>
            Zamknij
        </Button>
    )
}
