import {
    Card, CardBody, CardHeader, Table
} from "../../appp/components";
import React, {useEffect, useState} from "react";
import WarehouseProductRow from "./warehouseProductRow";
import {UserContext} from "../../Users/UserContextProvider";
import {CheckRolesComponentName} from "../../Users/roleHelpers";

const WarehouseProductTable = (props)=>
{
    const [user, setUser] = React.useContext(UserContext);
    const [isEditRowMode,setIsEditRowMode]= useState(false);

    {/*{ ?*/}
   useEffect(()=>{
           if(CheckRolesComponentName(user.role,'storage-edit-product'))
           {
               setIsEditRowMode(true);
           }
           else {
               setIsEditRowMode(false);
           }
       }
       ,[]
   )

    return (
        <React.Fragment>

            <Card >
                <CardHeader tag="h6" className="bg-info text-white">
                    {props.warehouse.idCode} 
                </CardHeader>
                <CardBody>
                    Regał: {props.warehouse.rack} Półka: {props.warehouse.shelf}  Miejsce: {props.warehouse.place}

                <Table className="mb-0" responsive>
                    <thead>
                    <tr>
                        <th className="bt-0">Kod id produktu</th>
                        <th className="bt-0">Nazwa produktu</th>
                        <th className="bt-0">Ilość</th>
                        <th className="bt-0">Data ważności</th>
                    </tr>
                    </thead>
                    <tbody>

                    {props.data.map((dat, i) => {

                        return (
                            <tr key={'prw_'+dat.id}>
                                <WarehouseProductRow data={dat} EditProductOnWarehouse={props.EditProductOnWarehouse} showEdit={isEditRowMode} />
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                </CardBody>

            </Card>

        </React.Fragment>
        
    )
}

export default WarehouseProductTable
