import React from "react";

import {useEditFormDialogHooks} from "../../Helper";
//import {Modal,ModalHeader,ModalBody,Container,Row,Col,FormGroup,Label,Input,ModalFooter} from "reactstrap";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Col,
    FormGroup,
    Label,
    Input,
    ModalFooter
} from "../../appp/components";
import {Button} from "reactstrap";
import DatePicker from "react-datepicker";
import {ButtonInput} from "../../appp/routes/Forms/DatePicker/components";


const EditProductOnWarehouse = (props) => {


    const [
        dataInEdit,
        handleSubmit,
        onDialogInputChange,
    ] = useEditFormDialogHooks(props.data);


    const save = (event) => {
        event.preventDefault();

        props.save(dataInEdit);
    };


    
    return (
        <Modal
            centered
            size="xl"
            isOpen={true}
            onHide={() => props.cancel()}
        >
            <ModalHeader tag="h6">
                Produkt w magazynie
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <Container>
                        <FormGroup>
                            <Label for="count" sm={3}>
                                Ilość
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="number"
                                    name="count"
                                    id="count"
                                    placeholder=""
                                    value={dataInEdit.count}
                                    onChange={onDialogInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label for="expirationDate" sm={3}>
                                Data ważności
                            </Label>
                            <Col sm={9}>
                                <DatePicker
                                    customInput={ <ButtonInput /> }
                                    dateFormat="dd.MM.yyyy"
                                     selected={dataInEdit.expirationDate}
                                    onChange={(val)=>{
                                        onDialogInputChange(
                                            {
                                                target: {
                                                    type: "datepicker",
                                                    value: val,
                                                    name:"expirationDate",
                                                }
                                            })
                                    }
                                    }

                                />
                            </Col>
                        </FormGroup>
                    </Container>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button color="link" className="text-primary" onClick={save}>
                    Zapisz
                </Button>
                <Button color="link" className="text-primary" onClick={()=>props.cancel()}>
                    Anuluj
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditProductOnWarehouse;