import ConstantsTitles from "../../Consts/titles";
import ConstantsMessages from "../../Consts/messages";

import {DefaultPostQuery} from "../defaultPostQuery";

const CREATE_ORDER_OD_URL = 'Order/CreateOrderOtherDelivered'

export const CreateOrderODQuery = (data, onResponse) => {
    return DefaultPostQuery(
        CREATE_ORDER_OD_URL,
        ConstantsTitles.ORDER_TITLE,
        ConstantsMessages.ORDER_CREATE_ORDER,
        CreateOrderODQuery.name,
        data,
        onResponse
    )
}
