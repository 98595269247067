import React, {useEffect, useState} from "react";
import {Button} from "devextreme-react/button";
import OrderDetailWithoutPriceComponent from "./orderDetailWithoutPriceComponent"
import {Box} from "devextreme-react";
import {Item} from "devextreme-react/box";

export default function OrderActionExpandedComponent (props)
{

    return (
        <React.Fragment>
            <Box
                direction="row"
                width="600"
                height="100%"
                >
                <Item ratio={1}>
                    <OrderDetailWithoutPriceComponent row={props.data} />
                    <div className={'content-block dx-card responsive-paddings'}>

                    <div>
                        {props.actionName ?
                            <Button type={"default"}  width={"100%"}  stylingMode={"contained"} onClick={()=>{props.action(props.data)}} >
                                {props.actionName}
                            </Button> : <div/>}
                    </div>
                    <div>
                        {props.actionName2 ?
                            <Button   type={"success"}  width={"100%"}  stylingMode={"contained"} onClick={()=>{props.action2(props.data)}} >
                                {props.actionName2}
                            </Button> : <div/>}
                    </div>
                    </div>
                </Item>
            </Box>
            
        </React.Fragment>
    )
}